<template>
  <div>
    <transition name="fade">
      <div class="modal-overlay" v-if="dialog"
           @click="toggleDialog()"></div>
    </transition>
    
    <div v-if="!dialog" style="cursor: pointer" @click="toggleDialog()">
      Click to extend.
    </div>
    
    <transition name="pop">
      <div v-if="dialog" class="bg-white border-radius-16 modal"
           style="position: absolute; z-index: 1001; opacity: 1; width: 50%; height: 80%;">
        <div
          style="display: flex; width:100%; height:100%; flex-direction:column; justify-content:flex-start; align-items:center;">
          
          <div class="col-dark-blue"
               style="height:100px; display:flex; justify-content:center; align-items:center; font-size:24px; font-weight:700;">
            {{settings.displayname}}
          </div>
          
          <div style="overflow:auto; margin: auto; height: 100%; width: 100%;">
            <div v-for="(val, index) in settingsValues" class="border-radius-8 bor-dark-blue-30"
                 style="margin: auto auto 10px; padding: 10px; width: 32em; min-height: 42px; display: flex; align-items: center; justify-content: space-between"
                 :key="index">
              
              <div style="width: 100vw; margin-right: 20px; vertical-align: middle">
                <CustomInputText v-if="isFirstLineEditable" v-model="settingsKeys[index]"
                                 :modelValue="settingsKeys[index]"
                                 class="bor-light-blue"
                                 @change="keyChange(settingsOldKeys[index], settingsKeys[index], settingsValues[index])"
                                 style="margin-top: 5px; margin-bottom: 5px; height: 35px; border: none; border-bottom: 1px solid"/>
                
                <CustomInputText v-model="settingsValues[index]" :modelValue="settingsValues[index]"
                                 @change="saveSettings()"
                                 class="bor-light-blue"
                                 style="margin-top: 5px; margin-bottom: 5px; height: 35px; border: none; border-bottom: 1px solid"/>
              </div>
              
              <i v-if="isDeletable" class="pi pi-trash"
                 style="font-size:24px; cursor: pointer" @click="onClickDelete(index)"></i>
            </div>
            
            <div v-if="allowAddNew">
              <div v-if="!addNewValue" @click="toggleAddNewValue()"
                   style="cursor: pointer; margin: auto auto 10px; padding: 10px; width: 32em; min-height: 42px; display: inherit; align-items: center; justify-content: space-between">
                <i class="pi pi-plus" style="float:left; margin-right: 5px;"/>
                <div>
                  Add new Value
                </div>
              </div>
              
              <div v-if="addNewValue" class="border-radius-8 bor-dark-blue-30"
                   style="margin: auto auto 10px; padding: 10px; width: 32em; min-height: 42px; display: flex; align-items: center; justify-content: space-between">
                
                <div style="width: 100vw; margin-right: 20px; vertical-align: middle">
                  <CustomInputText v-if="isFirstLineEditable" label="Key" v-model="fieldInputKey"
                                   :modelValue="fieldInputKey"
                                   class="bor-light-blue"
                                   style="margin-top: 5px; margin-bottom: 5px; height: 35px; border: none; border-bottom: 1px solid"></CustomInputText>
                  
                  <CustomInputText v-model="fieldInputValue" label="Value" :modelValue="fieldInputValue"
                                   class="bor-light-blue"
                                   style="margin-top: 5px; margin-bottom: 5px; height: 35px; border: none; border-bottom: 1px solid"></CustomInputText>
                </div>
                
                <i class="pi pi-check" style="font-size:24px; cursor: pointer"
                   @click="toggleAddNewValue()"></i>
              </div>
            </div>
          </div>
          
          <div
            style="width: 90%; height: 100px; display:flex; align-items:center; justify-content:right; overflow: hidden"
          >
            <Button
              @click="toggleDialog()"
              label="Close"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="min-width:120px; height:40px; padding:7px 6px 6px 8px; margin-right: 15px; overflow: hidden; z-index: 1002"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script src="./SettingsListItem.ts"></script>
import {
  CustomerMetadata,
  CustomerMetadataTemplate,
  User,
  UserRole,
} from '../../../target/api/de/moovit/titletoolserver/model';
import {SystemApi, UserApi} from '../../../target/api';
import {defineComponent, getCurrentInstance, onMounted, ref} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  components: {},
  setup: function () {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;
    const store = useStore();
    const systemApi: SystemApi = new SystemApi();
    const userApi = new UserApi();

    let userIsAdmin: any = ref(false);

    let currentLanguage = ref(undefined);
    let currentGroup = ref(undefined);
    let currentTranslator = ref(undefined);
    let currentStatus = ref(undefined);
    let currentCustom = ref<CustomerMetadata>();
    let customOptions = ref<Array<CustomerMetadataTemplate>>();
    let customMap = ref<Map<string, string>>();

    let currentRole = ref(undefined);
    let currentCostCenter = ref(undefined);
    let currentDefaultLanguage = ref(undefined);

    let addFilters = () => {
      let currentLanguageAny: any = currentLanguage.value;
      let currentGroupAny: any = currentGroup.value;
      let currentTranslatorAny: any = currentTranslator.value;
      let currentStatusAny: any = currentStatus.value;
      let currentCustomAny: any = currentCustom.value;

      let currentRoleAny: any = currentRole.value;
      currentRole.value = undefined;
      let currentCostCenterAny: any = currentCostCenter.value;
      currentCostCenter.value = undefined;
      let currentDefaultLanguageAny: any = currentDefaultLanguage.value;
      currentDefaultLanguage.value = undefined;

      if (currentStatusAny) {
        store.commit('ADD_FILTER_BREADCRUMB', {
          name: currentStatusAny,
          type: 'STATUS'
        });
      }

      if (currentLanguageAny) {
        store.commit('ADD_FILTER_BREADCRUMB', {
          name: currentLanguageAny.name,
          type: 'LANGUAGE'
        });
      }

      if (currentGroupAny) {
        store.commit('ADD_FILTER_BREADCRUMB', {
          name: currentGroupAny,
          type: 'GROUP'
        });
      }

      if (currentTranslatorAny) {
        store.commit('ADD_FILTER_BREADCRUMB', {
          name: currentTranslatorAny.id,
          type: 'TRANSLATOR',
        });
      }

      if (currentRoleAny) {
        store.commit('ADD_FILTER_BREADCRUMB', {
          name: currentRoleAny,
          type: 'ROLE',
        });
      }

      if (currentCostCenterAny) {
        store.commit('ADD_FILTER_BREADCRUMB', {
          name: currentCostCenterAny,
          type: 'COSTCENTER',
        });
      }

      if (currentDefaultLanguageAny) {
        store.commit('ADD_FILTER_BREADCRUMB', {
          name: currentDefaultLanguageAny.name,
          type: 'DEFAULT_LANGUAGE',
        });
      }

      if (currentCustomAny) {
        store.commit('ADD_FILTER_BREADCRUMB', {name: currentCustom.value, type: 'CUSTOM'});
        currentCustom.value = undefined;
      }
    };

    onMounted(async () => {
      emitter.on('show-add-filter-dialog', async () => {
        let user: User = (await userApi.getUserById(JSON.parse(localStorage.loginData).user.id))
          .data;
        console.debug('user', user);

        userIsAdmin.value = user.role == UserRole.ADMIN;

        let firstUserGroup = undefined;
        if (user.groups && user.groups.length > 0) {
          firstUserGroup = user.groups[0].id;
        }

        customOptions.value = (await systemApi.searchCustomerMetadataTemplate("", (firstUserGroup ? firstUserGroup : ""))).data;
      });

    });

    return {
      currentLanguage,
      currentGroup,
      currentTranslator,
      currentStatus,
      currentCustom,

      currentRole,
      currentCostCenter,
      currentDefaultLanguage,

      customOptions,
      addFilters,
      userIsAdmin,
    };
  },
});

// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface HealthInfo
 */
export interface HealthInfo {
    /**
     * 
     * @type {string}
     * @memberof HealthInfo
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthInfo
     */
    cpuLoad?: number;
    /**
     * 
     * @type {number}
     * @memberof HealthInfo
     */
    ramLoad?: number;
    /**
     * 
     * @type {string}
     * @memberof HealthInfo
     */
    status?: HealthInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof HealthInfo
     */
    tasks?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthInfo
     */
    systemTime?: number;
    /**
     * 
     * @type {number}
     * @memberof HealthInfo
     */
    activeJobRunningInMilliseconds?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HealthInfo
     */
    serverAvailable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HealthInfo
     */
    storageAvailable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HealthInfo
     */
    os?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthInfo
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthInfo
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthInfo
     */
    spaceLeftOnSystemDrive?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum HealthInfoStatusEnum {
    RENDERING = 'RENDERING',
    ANALYZING = 'ANALYZING',
    UNZIPPING = 'UNZIPPING',
    IDLE = 'IDLE'
}




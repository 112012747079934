import {
  AssetItem,
  AssetItemStatus,
  BasicAssetItem,
  CustomerMetadata,
  Field,
  FieldType,
  PreviewFrame,
  Progress,
  ProgressStatus,
  RenderAssetItemObject,
  SystemPreference,
  WsTopic,
} from "../../../target/api/de/moovit/titletoolserver/model";
import {
  AssetApi,
  AssetItemApi,
  FieldApi,
  SystemApi,
} from "../../../target/api";

import {
  defineComponent,
  getCurrentInstance, nextTick,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import axios, {AxiosResponse} from "axios";
import videojs, {VideoJsPlayer} from "video.js";
import main from "@/main";

export default defineComponent({
  components: {},
  props: [
    "asset",
    "assetItem",
    "assetItemIndex",
    "currentAssetItemIndex",
    "needsVerification",
  ],
  setup: function (props) {
    const router = useRouter();
    const store = useStore();
    const i18n: any = useI18n();

    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();
    const systemApi: SystemApi = new SystemApi();
    const fieldApi: FieldApi = new FieldApi();

    let intervalId: number | undefined = undefined;

    let windowWidth = ref<number>();
    let isFullscreen = ref<boolean>(false);
    let isJobLoaded = ref<boolean>(false);
    let isJobMasterAssetVerify = ref<boolean>(false);
    let previewLinkActive = ref<boolean>(false);

    let item = ref<AssetItem>({});

    //Player
    let player = ref<VideoJsPlayer>();
    let playerVideoControls = ref<any>({});
    let playerPaused = ref<boolean>(true);
    let playerSliderPosition = ref<number>(0);
    let playerVolume = ref<number>(50);
    let playerSpeed = ref<number>(1);
    let showVolumeSlider = ref<boolean>(false);
    let playerVideoOptions = ref<any>();

    //Translations & Texts
    let currentField = ref<Field>({});
    let isFieldInPointModified = ref<boolean>(false);
    let currentMasterValue = ref<string>();
    let currentTranslationValue = ref<string>();
    let isTextRTL = ref<boolean>(false);
    let currentFieldCounter = ref<number>(-1);
    let allFields = ref<Array<Field>>([]);
    let fields = ref<Array<Field>>([]);
    let lastPlayerHeadPosFromField = ref<number>(0);
    let showSubtitleFields = ref<boolean | null>(null);
    let onlySubtitleOrTextlayer = ref<boolean | null>();

    let isAdvancedField = ref<boolean>(false);
    let firstMainText = ref<string>("");
    let firstTranslationText = ref<string>("");
    let lastMainText = ref<string>("");
    let lastTranslationText = ref<string>("");

    //Complete Dialog
    let completeDialogVisible = ref<boolean>(false);
    let customerMetadata = ref<Array<CustomerMetadata>>([]);

    //Rendering Process
    let isRendering = ref<boolean>();
    let renderProgress = ref<number>();
    let renderError = ref<boolean>(false);
    let submitJob = ref<boolean>(false);
    let isFinalRender = ref<boolean>(false);

    //Advanced Settings
    let isAdvancedSettingsVisible = ref<boolean>(false);
    let advancedSettingsFontAdjust = ref<string>("DEFAULT");
    let advancedSettingsFontSizePercent = ref(100);
    let advancedSettingsOffsetX = ref<number>(0);
    let advancedSettingsOffsetY = ref<number>(0);
    let advancedSettingsOverlayPanel = ref();

    //UI Buttons
    let renderButtonText = ref<string>("");
    let playButtonText = ref<string>("");
    let playButtonIcon = ref<string>("pi pi-play");
    let pauseOnNextField = ref<boolean>(false);

    //Variant
    let isAssetItemVariant = ref<boolean>(false);

    //Preview Frame
    let currentPreviewFrameLink = ref<string>("");

    //VoiceOver
    let showVoiceOverDropdown = ref<boolean>(false);
    let audioUploadFileInput = ref();
    let selectedVoiceOverTrack = ref<Field>();
    let voiceTracks = ref<Array<Field>>();
    let voiceTracksAvailable = ref<boolean>(false);

    //MultiFormat
    let showMultiFormatDropdown = ref<boolean>(false);
    let showMultiFormatRenderDropdown = ref<boolean>(false);
    let variants = ref<BasicAssetItem[]>([]);
    let selectedVariant = ref<BasicAssetItem>();

    //Replace Media
    let showReplaceMediaDropdown = ref<boolean>(false);
    const fileInput: any = ref(null);

    //### Functions ###

    //Utils
    let getVideoURL = (assetItem: AssetItem, timecode: boolean = true) => {
      if (assetItem) {
        return (
          process.env.VUE_APP_TT_ENDPOINT +
          "/videostream?access_token=" +
          JSON.parse(localStorage.loginData).access_token +
          "&id=" +
          assetItem.id +
          (timecode ? "&ts=" + assetItem.lastModified : "")
        );
      } else {
        return "";
      }
    };

    let getCurrentTimecode = (seconds: number) => {
      if (!seconds) {
        return "00 : 00";
      }
      let minutes = Math.floor(seconds / 60);
      seconds -= minutes * 60;

      let minuteString: string = minutes + "";
      if (minutes < 10) minuteString = "0" + minutes;

      let secondString: string = Math.floor(seconds) + "";
      if (seconds < 10) secondString = "0" + Math.floor(seconds);

      return minuteString + " : " + secondString;
    };

    let _setFields = async (id: string) => {
      fields.value = [];

      fieldApi.getAssetItemFieldsByAssetItemId(id).then((assetItemFields) => {
        if (assetItemFields.data) {
          fields.value = assetItemFields.data;

          for (const x in fields.value) {
            for (const j in fields.value) {
              if (j == x) continue;
              if (
                getPlayheadPosForField(fields.value[x]) ==
                getPlayheadPosForField(fields.value[j])
              ) {
                fields.value[j].inPoint = fields.value[j].inPoint! + 0.1;
                isFieldInPointModified.value = true;
              }
            }
          }

          allFields.value = fields.value;

          console.log(fields.value);

          let isOnlySubtitleOrTextlayer = fields.value.filter(
            (field) => field.fieldType == FieldType.CONTINUOUS
          );

          if (isOnlySubtitleOrTextlayer.length != 0) {
            isOnlySubtitleOrTextlayer = fields.value.filter(
              (field) => field.fieldType == FieldType.LETTERING
            );
            if (isOnlySubtitleOrTextlayer.length == 0) {
              onlySubtitleOrTextlayer.value = false;
            } else {
              onlySubtitleOrTextlayer.value = null;
            }
          } else {
            onlySubtitleOrTextlayer.value = true;
          }
        }
      });
    };

    let updateFieldApiCall = (currentField: Field, savedElement: HTMLElement | null, fieldDataChanged: boolean) => {
      let oldInPoint: number = 0;

      if (isFieldInPointModified.value) {
        fieldApi.getField(currentField.id!).then(response => {
          oldInPoint = currentField.inPoint!;
          currentField.inPoint = response.data.inPoint;

          fieldApi
            .updateField(currentField.id!, currentField)
            .then(() => {
              if (fieldDataChanged) {
                setTimeout(() => {
                  if (savedElement) {
                    savedElement.classList.add("saved-element-animation");
                  }
                }, 40);
              }

              emitter.emit("field-visualizer-update-fields", null);

              if (isFieldInPointModified.value) {
                currentField.inPoint = oldInPoint;
              }
            });
        });
      } else {
        fieldApi
          .updateField(currentField.id!, currentField)
          .then(() => {
            if (fieldDataChanged) {
              setTimeout(() => {
                if (savedElement) {
                  savedElement.classList.add("saved-element-animation");
                }
              }, 40);
            }

            emitter.emit("field-visualizer-update-fields", null);
          });
      }
    }

    let saveCurrentFieldValues = async () => {
      console.debug("saveCurrentFieldValues");

      let savedElement = document.getElementById("savedElement");

      if (savedElement) {
        savedElement.classList.remove("saved-element-animation");
      }

      let fieldDataChanged = false;
      console.debug("Current Field: ", currentField.value);

      if (currentField && currentField.value && currentField.value.id) {
        if (currentField.value.value !== currentTranslationValue.value) {
          console.debug(
            "Updating Field Value. " +
            "Old: '" +
            currentField.value.value +
            "', New: '" +
            currentTranslationValue.value +
            "'"
          );

          // fields.value[currentFieldCounter.value].value = currentTranslationValue.value;

          currentField.value.value = currentTranslationValue.value;
          fieldDataChanged = true;
        }

        if (
          currentField.value.xOffset !== undefined &&
          currentField.value.xOffset !== advancedSettingsOffsetX.value
        ) {
          console.debug(
            "Updating Field xOffset. " +
            "Old: '" +
            currentField.value.xOffset +
            "', New: '" +
            advancedSettingsOffsetX.value +
            "'"
          );

          currentField.value.xOffset = advancedSettingsOffsetX.value;
          fieldDataChanged = true;
        }

        if (
          currentField.value.yOffset !== undefined &&
          currentField.value.yOffset !== advancedSettingsOffsetY.value
        ) {
          console.debug(
            "Updating Field yOffset. " +
            "Old: '" +
            currentField.value.yOffset +
            "', New: '" +
            advancedSettingsOffsetY.value +
            "'"
          );

          currentField.value.yOffset = advancedSettingsOffsetY.value;
          fieldDataChanged = true;
        }

        if (currentField.value.autoAdjustFont === undefined) {
          currentField.value.autoAdjustFont = false;
        }

        if (
          currentField.value.autoAdjustFont !==
          (advancedSettingsFontAdjust.value == "AUTOMATIC")
        ) {
          console.debug(
            "Updating Field autoAdjustFont. " +
            "Old: '" +
            currentField.value.autoAdjustFont +
            "', " +
            "New: '" +
            (advancedSettingsFontAdjust.value == "AUTOMATIC") +
            "'"
          );

          currentField.value.autoAdjustFont =
            advancedSettingsFontAdjust.value == "AUTOMATIC";
          fieldDataChanged = true;
        }

        if (
          currentField.value.fontFactor !== undefined &&
          currentField.value.fontFactor !==
          advancedSettingsFontSizePercent.value
        ) {
          console.debug(
            "Updating Field fontFactor. " +
            "Old: '" +
            currentField.value.fontFactor +
            "', " +
            "New: '" +
            advancedSettingsFontSizePercent.value +
            "'"
          );

          currentField.value.fontFactor = advancedSettingsFontSizePercent.value;
          fieldDataChanged = true;
        }

        console.debug("Latest Field: ", currentField.value);

        updateFieldApiCall(currentField.value, savedElement, fieldDataChanged);
      }
    };

    let _setNewFields = async () => {
      //  await saveCurrentFieldValues();

      if (currentFieldCounter.value == -1) {
        currentMasterValue.value = "";
        currentTranslationValue.value = "";

        advancedSettingsOffsetX.value = 0;
        advancedSettingsOffsetY.value = 0;
        advancedSettingsFontAdjust.value = "MANUAL";
        advancedSettingsFontSizePercent.value = 100;
      }

      if (
        fields.value &&
        currentFieldCounter.value != null &&
        currentFieldCounter.value != -1
      ) {
        currentField.value = fields.value[currentFieldCounter.value];
      }

      if (!currentField.value) {
        return;
      }

      if (pauseOnNextField.value) {
        _updateCurrentTimecode();
      }

      currentMasterValue.value = currentField.value.srcValue;
      currentTranslationValue.value = currentField.value.value;

      advancedSettingsOffsetX.value = currentField.value.xOffset
        ? currentField.value.xOffset
        : 0;
      advancedSettingsOffsetY.value = currentField.value.yOffset
        ? currentField.value.yOffset
        : 0;
      advancedSettingsFontAdjust.value = currentField.value.autoAdjustFont
        ? "AUTOMATIC"
        : "MANUAL";
      advancedSettingsFontSizePercent.value =
        currentField.value.fontFactor === -1
          ? 100
          : currentField.value.fontFactor!;
      let currentTranslation: any = document.getElementById(
        "currentTranslation"
      );
      currentTranslation.focus();

      _calculateFirstAndLastText();
    };

    let _setNextField = async () => {
      if (currentFieldCounter.value != null && fields.value) {
        await saveCurrentFieldValues();

        currentFieldCounter.value = currentFieldCounter.value + 1;

        if (currentFieldCounter.value >= fields.value.length) {
          currentFieldCounter.value = 0;
        }
      }
      _setNewFields();
    };

    let getPlayheadPosForField = (field: Field) => {
      if (field) {
        let calculatedTime = (field.inPoint! + field.outPoint!) / 2;

        if (calculatedTime == lastPlayerHeadPosFromField.value) {
          lastPlayerHeadPosFromField.value = calculatedTime; // + 0.1;
          return lastPlayerHeadPosFromField.value;
        } else {
          lastPlayerHeadPosFromField.value = calculatedTime;
          return calculatedTime;
        }
      } else {
        return 0;
      }
    };

    let _updateCurrentTimecode = () => {
      if (currentFieldCounter.value == -1) {
        playerVideoControls.value.currentTime = 0;
        playerSliderPosition.value = 0;
      }
      if (currentField.value && currentField.value.value) {
        playerVideoControls.value.currentTime = getPlayheadPosForField(
          currentField.value
        );
        playerSliderPosition.value =
          (playerVideoControls.value.currentTime /
            playerVideoControls.value.duration) *
          100;
      }
    };

    let _setCurrentTimecode = (timecode: number) => {
      playerVideoControls.value.currentTime = timecode;
    };

    let _reloadJob = async () => {
      if (item.value && item.value.id) {
        let getAssetItemByIdResponse = await assetItemApi.getAssetItemById(
          item.value.id
        );
        item.value = getAssetItemByIdResponse.data;
        if (item.value && item.value.id) {
          await _setFields(item.value.id);
          emitter.emit("field-visualizer-update-fields", null);
        }
        isRendering.value =
          isAssetItemStatusRendering() || isAssetItemStatusScheduled();
      }
    };

    let _loadJob = async (assetItem: AssetItem) => {
      console.debug("loading ", assetItem);
      renderButtonText.value = "general.videoPreview";
      playButtonIcon.value = "pi pi-play";
      playButtonText.value = "general.play";

      if (assetItem && assetItem.id) {
        item.value = assetItem;

        await assetItemApi.getAssetItemById(assetItem.id).then((result) => {
          //to refresh data, for example the status
          item.value = result.data;
        });

        console.debug("Loading", item.value);

        _emitterOn();

        if (item.value && item.value.id) {
          isRendering.value =
            isAssetItemStatusRendering() || isAssetItemStatusScheduled();
          renderProgress.value = 0;

          renderError.value = item.value.status === AssetItemStatus.FAILED;
          if (renderError.value) {
            renderProgress.value = 100;
          }

          isJobMasterAssetVerify.value = props.needsVerification;

          selectedVoiceOverTrack.value = {};

          playerVideoControls.value = {};

          playerSliderPosition.value = 0;
          currentField.value = {};
          isFieldInPointModified.value = false;
          currentMasterValue.value = "";
          currentTranslationValue.value = "";
          isTextRTL.value = false;
          currentFieldCounter.value = -1;
          fields.value = [];
          lastPlayerHeadPosFromField.value = 0;
          isAdvancedField.value = false;
          firstMainText.value = "";
          firstTranslationText.value = "";
          lastMainText.value = "";
          lastTranslationText.value = "";

          let rtlLanguages: SystemPreference = (
            await systemApi.getSystemPreference("rtllanguages")
          ).data;
          let list: Array<string> = <Array<string>>rtlLanguages.value;

          for (const rtlLanguage of list) {
            if (rtlLanguage === item.value.language) {
              isTextRTL.value = true;
              break;
            }
          }

          systemApi
            .getCustomMetadataByIdList(item.value.customerMetadata)
            .then((result) => {
              if (result.data) {
                customerMetadata.value = [];
                for (let metadata of result.data) {
                  if (metadata.needTranslation) {
                    customerMetadata.value.push(metadata);
                  }
                }
              }
            });

          await _setFields(item.value.id);

          _loadVideo();

          setVoiceOverTracks();

          emitter.on("ws" + WsTopic.ASSETITEMPROGRESS, (progress: Progress) => {
            if (
              progress.percent &&
              item.value &&
              item.value.id === progress.assetItemId
            ) {
              isRendering.value = true;
              console.debug(progress);
              renderButtonText.value = i18n.t("general.progressXPercent", [
                progress.percent,
              ]);
              renderProgress.value = progress.percent;
              if (
                progress.status == ProgressStatus.COMPLETE &&
                progress.percent == 100
              ) {
                _reloadJob().then(async () => {
                  renderButtonText.value = "general.videoPreview";
                  if (player.value) {
                    player.value.src([
                      {src: getVideoURL(item.value), type: "video/mp4"},
                    ]);
                  }
                });
              } else if (
                progress.status == ProgressStatus.ERROR &&
                progress.percent == 100
              ) {
                store.commit("SHOW_SNACKBAR", {
                  snackbarType: "error",
                  snackbarContent: {t: "snackbar.renderingFailed"},
                });
                renderError.value = true;

                _reloadJob().then(() => {
                  renderButtonText.value = "general.videoPreview";
                  if (player.value) {
                    player.value.src([
                      {src: getVideoURL(item.value), type: "video/mp4"},
                    ]);
                  }
                });
              } else {
                isRendering.value = true;
              }
            }
          });

          intervalId = window.setInterval(() => {
            if (
              playerVideoControls.value &&
              player &&
              player.value &&
              !player.value.paused()
            ) {
              playerSliderPosition.value =
                (playerVideoControls.value.currentTime /
                  playerVideoControls.value.duration) *
                100;
            }
          }, 40);
        }

      }

      setTimeout(() => {
        let playButton = document.getElementById("job-video-play-button");

        if (playButton) {
          setTimeout(() => {
            isJobLoaded.value = true;
          }, 200);
        }

      }, 300);

      console.debug("Loaded", item.value);
    };

    let _loadVideo = () => {
      playerVideoOptions.value = {};
      playerVideoOptions.value.preload = "metadata";
      playerVideoOptions.value.errorDisplay = false;
      playerVideoOptions.value.playbackRates = [1, 2];

      if (player.value) {
        player.value.src({
          type: "video/mp4",
          src: getVideoURL(item.value, !isRendering.value),
        });
        player.value.load();
      } else {
        player.value = videojs(
          playerVideoControls.value,
          playerVideoOptions.value
        );
        player.value.src({
          type: "video/mp4",
          src: getVideoURL(item.value, !isRendering.value),
        });
        player.value.load();
      }

      let volume = localStorage.getItem("volume");

      if (volume) {
        playerVolume.value = Number.parseInt(volume);
      }

      onChangeVideoSliderVolume(playerVolume.value);
    };

    let _unloadJob = () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }

      main.config.globalProperties.$emitter.off(
        "ws" + WsTopic.ASSETITEMPROGRESS
      );
      main.config.globalProperties.$emitter.off(
        "ws" + WsTopic.PREVIEWFRAMECREATED
      );

      _emitterOff();
    };

    let _emitterOn = () => {
      emitter.on("updateFieldValues" + item.value.id, (newText: string) => {
        currentTranslationValue.value = newText;
        saveCurrentFieldValues();
      });

      emitter.on("nextField" + item.value.id, () => {
        onClickNextFieldButton();
      });

      emitter.on("previousField" + item.value.id, () => {
        onClickPreviousFieldButton();
      });

      emitter.on("goToField" + item.value.id, (index: number) => {
        _goToField(index);
      });

      emitter.on("setCurrentPreviewFrameLink", (link: string) => {
        currentPreviewFrameLink.value = link;
        previewLinkActive.value = true;
      });

      emitter.on(
        "onChangeVideoSliderPosition" + item.value.id,
        (position: number) => {
          playerSliderPosition.value = position;
          onChangeVideoSliderPosition(position);
        }
      );

      emitter.on("addAudioTrack", (file: File) => {
        onUploadNewAudio(file);
      });
    };

    let _emitterOff = () => {
      emitter.off("updateFieldValues" + item.value.id);
      emitter.off("nextField" + item.value.id);
      emitter.off("previousField" + item.value.id);
      emitter.off("goToField" + item.value.id);
      emitter.off("setCurrentPreviewFrameLink");
      emitter.off("onChangeVideoSliderPosition" + item.value.id);
      emitter.off("addAudioTrack");
    };

    //AssetItem Functions
    let isAssetItemStatusClosed = () => {
      if (item.value) {
        return (
          !item.value.needsVerification &&
          (item.value.status == AssetItemStatus.CLOSED ||
            item.value.status == AssetItemStatus.MAMUPLOADED ||
            item.value.status == AssetItemStatus.MAMUPLOADING ||
            item.value.status == AssetItemStatus.READYTOAPPROVE ||
            item.value.status == AssetItemStatus.COMPLETE)
        );
      }
      return false;
    };

    let isAssetItemStatusReadyToApprove = () => {
      if (item.value) {
        return item.value.status == AssetItemStatus.READYTOAPPROVE;
      }
      return false;
    };

    let isAssetItemStatusRendering = () => {
      if (item.value) {
        return item.value.status == AssetItemStatus.RENDERING;
      }
      return false;
    };

    let isAssetItemStatusScheduled = () => {
      if (item.value) {
        return item.value.status == AssetItemStatus.SCHEDULED;
      }
      return false;
    };

    let isAssetItemStatusFailed = () => {
      if (item.value) {
        return item.value.status == AssetItemStatus.FAILED;
      }
      return false;
    };

    let assetItemRender = (isHigh: boolean) => {
      if (player.value && item.value) {
        player.value.pause();
        playerVideoControls.value.currentTime = 0;
        playerSliderPosition.value = 0;
        currentPreviewFrameLink.value = "";

        isFinalRender.value = isHigh;

        if (isHigh) {
          submitJob.value = true;
        }

        let renderAssetItemObject = <RenderAssetItemObject>{
          high: isHigh,
          assetItemId: item.value.id,
          skipEmailsAndUploads: true,
        };

        assetItemApi
          .renderAssetItem(false, renderAssetItemObject)
          .then((assetItem) => {
            item.value = assetItem.data;
          });

        renderButtonText.value = i18n.t("general.rendering") + "...";
        isRendering.value = true;
        renderError.value = false;
        renderProgress.value = 0;
      }
    };

    let assetItemDownload = () => {
      if (item.value) {
        const url = getVideoURL(item.value);
        if (url) {
          let downloadElement = document.createElement("a");
          downloadElement.href = url + "&dl=true";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
        }
      }
    };

    //ClickEvents
    let onClickPlayButton = () => {
      currentPreviewFrameLink.value = "";
      if (player.value) {
        if (player.value.paused()) {
          onVideoPlay();
        } else {
          onVideoPause();
        }
      }
    };

    let onClickPreviousFieldButton = () => {
      currentPreviewFrameLink.value = "";
      console.debug("onClickPreviousFieldButton");
      // _setPreviousField();
      if (fields.value.length > 0) {
        let prevIndex = currentFieldCounter.value - 1;
        if (prevIndex < 0) {
          prevIndex = 0;
        }
        _goToField(prevIndex);

        _calculateFirstAndLastText();
      }
    };

    let onClickNextFieldButton = () => {
      currentPreviewFrameLink.value = "";
      console.debug("onClickNextFieldButton");

      if (fields.value.length > 0) {
        let nextIndex = 0;

        if (currentFieldCounter.value + 1 < fields.value.length) {
          nextIndex = currentFieldCounter.value + 1;
        }
        _goToField(nextIndex);

        _calculateFirstAndLastText();
      }
    };

    let _goToField = (index: number) => {
      let field = fields.value[index];
      currentFieldCounter.value = index;

      let timestamp = getPlayheadPosForField(field);
      if (!playerVideoControls.value) playerVideoControls.value = {};
      playerVideoControls.value.currentTime = timestamp;

      onVideoPause();

      _setNewFields().then(() => {
        _updateCurrentTimecode();
      });
    };

    let onClickPauseOnNextFieldButton = () => {
      if (pauseOnNextField.value) {
        store.commit("SHOW_SNACKBAR", {
          snackbarContent: {t: "snackbar.videoNotPauseOnNextField"},
        });
      } else {
        store.commit("SHOW_SNACKBAR", {
          snackbarContent: {t: "snackbar.videoPauseOnNextField"},
        });
      }
      pauseOnNextField.value = !pauseOnNextField.value;
    };

    let onClickUpdateAndWatchButton = (isHigh: boolean) => {
      assetItemRender(isHigh);
    };

    let onClickChangeInputField = () => {
      isAdvancedField.value = !isAdvancedField.value;
    };

    let onChangeVideoSliderPosition = (value: any) => {
      console.log("Current Prev Frame Link:", currentPreviewFrameLink.value);
      if (!previewLinkActive.value) {
        currentPreviewFrameLink.value = "";
      } else {
        previewLinkActive.value = !previewLinkActive.value;
      }
      console.log(
        "Current Prev Frame Link AFTER:",
        currentPreviewFrameLink.value
      );
      const newPlayerTime = (value / 100) * playerVideoControls.value.duration;

      let lastPastField: number = -1;

      if (player.value && fields.value) {
        for (let index = 0; index < fields.value.length; index++) {
          const field = fields.value[index];
          if (getPlayheadPosForField(field) <= newPlayerTime) {
            lastPastField = index;
          } else {
            break;
          }
        }
        currentFieldCounter.value = lastPastField;
      }

      _setCurrentTimecode(newPlayerTime);
    };

    let onChangeVideoSliderVolume = (value: any) => {
      localStorage.setItem("volume", value);
      playerVideoControls.value.volume = value / 100;
    };

    let onChangeVideoSpeed = () => {
      playerSpeed.value = playerSpeed.value + 0.5;

      if (playerSpeed.value > 2) {
        playerSpeed.value = 1;
      }

      if (player.value) {
        player.value.playbackRate(playerSpeed.value);
      }
    };

    let onClickAdvancedSettings = (event: any) => {
      currentPreviewFrameLink.value = "";
      advancedSettingsOverlayPanel.value.toggle(event);
    };

    let onClickChangeFieldType = (isTextLayer: boolean) => {
      showSubtitleFields.value = isTextLayer == null ? null : !isTextLayer;

      fields.value = allFields.value;

      if (isTextLayer == null) {
        fields.value = allFields.value;
      } else {
        if (isTextLayer) {
          //Only Textlayer
          fields.value = fields.value.filter(
            (field) => field.fieldType == FieldType.CONTINUOUS
          );
        } else {
          //Only Subtitle
          fields.value = fields.value.filter(
            (field) => field.fieldType == FieldType.LETTERING
          );
        }
      }

      if (fields.value.length == 0) {
        fields.value = allFields.value;
        showSubtitleFields.value = null;

        store.commit("SHOW_SNACKBAR", {
          snackbarType: "info",
          snackbarContent: {t: "snackbar.noSubtitleFields"},
        });
      } else {
        _goToField(0);
      }
    };

    let onClickDownloadOrComplete = () => {
      if (isAssetItemStatusClosed()) {
        if (isAssetItemStatusReadyToApprove()) {
          if (item.value.approverID) {
            store.commit("SHOW_INFO_DIALOG", {
              dialogTitle: "dialog.information",
              dialogContent: {t: "dialog.currentlyApproving", e: []},
              confirmCallback: () => {
              },
            });
          }
        } else {
          assetItemDownload();
          store.commit("SHOW_SNACKBAR", {
            snackbarContent: {t: "snackbar.jobDownloaded"},
          });
        }
      } else {
        completeDialogVisible.value = true;

        /*
        store.commit('SHOW_CONFIRM_DIALOG', {
          dialogTitle: 'dialog.areYouSure',
          dialogContent: item.value.approverID
            ? {t: 'dialog.submitJob', e: [props.asset.name]}
            : {t: 'dialog.completeJob', e: [props.asset.name]},
          confirmCallback: () => {
            assetItemRender(true);
            store.commit('SHOW_SNACKBAR', {
              snackbarContent: item.value.approverID
                ? {t: 'snackbar.jobSubmitted'}
                : {t: 'snackbar.jobCompleted'},
            });
          },
        })*/
      }
    };

    let onClickFinalCompleteJob = () => {
      assetItemRender(true);
      store.commit("SHOW_SNACKBAR", {
        snackbarContent: item.value.approverID
          ? {t: "snackbar.jobSubmitted"}
          : {t: "snackbar.jobCompleted"},
      });
      completeDialogVisible.value = false;
    };

    let onClickDownloadSrt = () => {
      if (item.value && item.value.id) {
        const url =
          process.env.VUE_APP_TT_ENDPOINT +
          "/asset/item/" +
          item.value.id +
          "/srt?access_token=" +
          JSON.parse(localStorage.loginData).access_token;

        if (url) {
          console.debug(url);
          let downloadElement = document.createElement("a");
          downloadElement.href = url;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
        }
      }
    };

    let onClickVerifyMasterAsset = async () => {
      store.commit("SHOW_CONFIRM_DIALOG", {
        dialogTitle: "dialog.areYouSure",
        dialogContent: {t: "dialog.approveVerify", e: [props.asset.name]},
        confirmCallback: async () => {
          await assetApi
            .verifyAsset(localStorage.sessionId, item.value)
            .catch(() => {
              store.commit("SHOW_SNACKBAR", {
                snackbarType: "error",
                snackbarContent: {t: "snackbar.somethingWentWrong"},
              });
            })
            .then(() => {
              router.push("assets");
              store.commit("SHOW_SNACKBAR", {
                snackbarContent: {t: "snackbar.jobVerified"},
              });
            });
        },
      });
    };

    let onClickRenderCurrentFrame = async () => {
      currentPreviewFrameLink.value = "";

      let previewFrame: PreviewFrame = {};
      previewFrame.assetItemId = item.value.id;
      previewFrame.time = playerVideoControls.value.currentTime;

      let previewFrameResponse = await assetItemApi.createPreviewFrame(
        previewFrame
      );

      if (previewFrameResponse) {
        previewFrame = {};
        previewFrame = previewFrameResponse.data;

        emitter.emit("addPreviewFrame", previewFrame);
      }
    };

    let onKeyboardClickedEvent = (event: KeyboardEvent) => {
      if (event.key === "s" && event.ctrlKey && 1) {
        saveCurrentFieldValues();
      }

      if (event.key === " " && event.ctrlKey && 1) {
        onClickPlayButton();
      }

      if (event.key === "k" && event.ctrlKey && 1) {
        onVideoPause();
      }

      if (event.key === "l" && event.ctrlKey && 1) {
        if (!player.value!.paused()) {
          onChangeVideoSpeed();
        }
      }

      if (event.key === "d" && event.ctrlKey && 1) {
        saveCurrentFieldValues();
        onClickNextFieldButton();
      }

      if (event.key === "a" && event.ctrlKey && 1) {
        saveCurrentFieldValues();
        onClickPreviousFieldButton();
      }

      if (event.key === "r" && event.ctrlKey && 1) {
        onClickUpdateAndWatchButton(false);
      }

      if (event.key === "f" && event.ctrlKey && 1) {
        onClickRenderCurrentFrame();
      }

      if (event.key === "p" && event.ctrlKey && 1) {
        onClickPauseOnNextFieldButton();
      }

      if (event.key === "m" && event.ctrlKey && 1) {
        if (playerVolume.value != 0) {
          playerVolume.value = 0;
        } else {
          playerVolume.value = 100;
        }

        onChangeVideoSliderVolume(playerVolume.value);
      }
    };

    //VideoEvents
    let onVideoPlay = () => {
      if (player.value) {
        player.value.play();
        playButtonIcon.value = "pi pi-pause";
        playButtonText.value = "general.pause";
      }
      currentPreviewFrameLink.value = "";
    };

    let onVideoPause = () => {
      if (player.value) {
        player.value.pause();
        playButtonIcon.value = "pi pi-play";
        playButtonText.value = "general.play";
      }
    };

    let onVideoUpdate = () => {
      if (currentFieldCounter.value != null && fields.value) {
        if (currentFieldCounter.value + 1 >= fields.value.length) {
          return;
        }
        if (
          playerVideoControls.value &&
          typeof playerVideoControls.value.currentTime !== "undefined"
        ) {
          const currentPlayerTime = playerVideoControls.value.currentTime;

          let nextField = fields.value[currentFieldCounter.value + 1];
          let nextValue = getPlayheadPosForField(nextField);

          if (currentPlayerTime >= nextValue) {
            if (pauseOnNextField.value) {
              onVideoPause();
            }
            _setNextField();
          }
        }
      }
    };

    let onVideoEnded = async () => {
      if (item.value && item.value.id) {
        //await _setFields(item.value.id);
      }

      if (player.value) {
        currentFieldCounter.value = -1;
        playerSliderPosition.value = 0;

        _setNewFields();
      }
    };

    //Advanced TextArea Handling

    let _calculateFirstAndLastText = () => {
      if (currentFieldCounter.value > 0) {
        firstTranslationText.value = fields.value[
        currentFieldCounter.value - 1
          ].value!;
        firstMainText.value = fields.value[
        currentFieldCounter.value - 1
          ].srcValue!;
      } else {
        firstTranslationText.value = "";
        firstMainText.value = "";
      }

      if (currentFieldCounter.value < fields.value.length - 1) {
        lastTranslationText.value = fields.value[
        currentFieldCounter.value + 1
          ].value!;
        lastMainText.value = fields.value[
        currentFieldCounter.value + 1
          ].srcValue!;
      } else {
        lastTranslationText.value = "";
        lastMainText.value = "";
      }
    };

    //Methods
    //Util
    let changeCurrentAssetItemIndex = (index: number) => {
      emitter.emit("updateCurrentAssetItemIndex", index);
    };

    let hasPermission = (permstring: string) => {
      if (localStorage.loginData)
        return JSON.parse(localStorage.loginData).user.permissions.permissions[
          permstring
          ];
    };

    let isItemRendering = () => {
      return item.value.status == AssetItemStatus.RENDERING;
    };

    let isItemClosed = () => {
      return item.value.status == AssetItemStatus.CLOSED;
    };

    let download = (url: string) => {
      let downloadElement = document.createElement("a");
      downloadElement.href = url + "&dl=true";
      // downloadElement.download = item.value.name + '.mp4';
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
    };

    let _onResize = () => {
      windowWidth.value = window.innerWidth;
    };

    let onMouseDownCloseDropdowns = (event: any) => {
      if (showVoiceOverDropdown.value) {
        setTimeout(() => {
          showVoiceOverDropdown.value = false;
        }, 200);
      }

      if (showMultiFormatDropdown.value) {
        setTimeout(() => {
          showMultiFormatDropdown.value = false;
        }, 200);
      }

      if (showReplaceMediaDropdown.value) {
        setTimeout(() => {
          showReplaceMediaDropdown.value = false;
        }, 200);
      }

      if (showMultiFormatRenderDropdown.value) {
        setTimeout(() => {
          showMultiFormatRenderDropdown.value = false;
        }, 200);
      }

      if (showVolumeSlider.value && event.target.localName !== "span") {
        setTimeout(() => {
          showVolumeSlider.value = false;
        }, 200);
      }
    };

    //VoiceOver
    let onClickAddVoiceOver = () => {
      audioUploadFileInput.value.click();
      showVoiceOverDropdown.value = false;
    };

    let onClickCustomVoiceOverTrack = () => {
      emitter.emit("toggleCustomVoiceOverTrack", null);
      showVoiceOverDropdown.value = false;
    };

    let onClickSubtitleToVoiceOver = () => {
      if (item.value && item.value.id) {
        store.commit("SHOW_SNACKBAR", {
          snackbarType: "info",
          snackbarContent: {t: "snackbar.generateVoiceOver"},
        });
        assetItemApi
          .generateVoiceOver(item.value.id)
          .then((response: AxiosResponse<boolean>) => {
            let voiceOverGenerationResult;
            let voiceOverGenerationType;
            if (response && response.data) {
              voiceOverGenerationType = "success";
              voiceOverGenerationResult = "snackbar.generateVoiceOverSuccess";

              setVoiceOverTracks().then(_ => {
                onClickUpdateAndWatchButton(false);
              });

            } else {
              voiceOverGenerationType = "error";
              voiceOverGenerationResult = "snackbar.generateVoiceOverFailed";
            }
            store.commit("SHOW_SNACKBAR", {
              snackbarType: voiceOverGenerationType,
              snackbarContent: {t: voiceOverGenerationResult},
            });
          });
      }
      showVoiceOverDropdown.value = false;
    };

    let updateSelectedVoiceOverTrack = async () => {
      if (item.value && item.value.id && selectedVoiceOverTrack.value) {
        await assetItemApi.updateAssetItem(<AssetItem>{
          id: item.value.id,
          selectedAudioTrack: selectedVoiceOverTrack.value.id,
        });

        if (variants.value) {
          for (let variant of variants.value) {
            await assetItemApi.updateAssetItem(<AssetItem>{
              id: variant.id,
              selectedAudioTrack: selectedVoiceOverTrack.value.id,
            });
          }
        }
      }
    };

    let onUploadNewAudio = (event: any) => {
      console.debug("JobView - onUploadNewAudio(event)");

      let newAudio;

      if (event instanceof File) {
        newAudio = event;
      } else {
        newAudio = event.target.files[0];
      }

      console.debug(newAudio);

      let formData: FormData = new FormData();
      formData.append("file", newAudio);
      let url =
        "upload?task=uploadAudio&id=" +
        item.value.id +
        "&sessionId=" +
        store.state.sessionId;

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          console.debug("SUCCESS!!");
          setVoiceOverTracks();
        })
        .catch(function () {
          console.debug("FAILURE!!");
        });
    };

    let getVoiceOverTracks = () => {
      if (voiceTracks.value) {
        return voiceTracks.value;
      }
    };

    let setVoiceOverTracks = async () => {
      if (item.value && item.value.id) {
        assetItemApi.getAssetItemById(item.value.id).then(response => {
          item.value = response.data;

          fieldApi.getAssetItemAudioTracksByAssetItemId(item.value.id!).then(response => {
            voiceTracks.value = response.data.filter(
              (element: Field) => {
                return element.fieldType == "VOICEOVER";
              }
            );
            if (voiceTracks.value) {
              voiceTracksAvailable.value = voiceTracks.value.length > 0;
              voiceTracks.value.forEach((field: Field) => {
                if (field.id === item.value.selectedAudioTrack) {
                  selectedVoiceOverTrack.value = field;
                }
              });
            }
          });
        });
      }
    };

    //MultiFormat
    let setVariant = async () => {
      if (item.value && item.value.id) {
        if (item.value.variants && item.value.variants.length > 0) {
          if (!item.value.variants.includes(item.value)) {
            item.value.variants.unshift(item.value);
          }
          variants.value = [];
          variants.value = item.value.variants;

          if (variants.value.length > 0) {
            selectedVariant.value = variants.value[0];
          }
        }
      }
    };

    let onChangeVariant = async (variant: BasicAssetItem) => {
      selectedVariant.value = variant;
      console.debug("Set Variant ", variant.assetName);
      if (selectedVariant.value) {
        _unloadJob();
        let variantAssetItem = (
          await assetItemApi.getAssetItemById(selectedVariant.value.id!)
        ).data;
        await _loadJob(variantAssetItem);
        await _reloadJob();
      }
    };

    //Replace Media
    let onClickGenerateXLS = () => {
      showReplaceMediaDropdown.value = false;

      if (item.value) {
        const url =
          process.env.VUE_APP_TT_ENDPOINT +
          "/asset/item/" +
          item.value.id +
          "/xls?access_token=" +
          JSON.parse(localStorage.loginData).access_token;
        download(url);
        // saveAs(url, currentAssetItem.value.id + '.xls')
      }
    };

    let onClickImportXLS = (event: any) => {
      showReplaceMediaDropdown.value = false;
      console.debug("JobView - onClickImportXLS(event)");
      console.debug(event.target.files[0]);

      let formData: FormData = new FormData();
      formData.append("file", event.target.files[0]);
      let url =
        "upload?task=importXLS&id=" +
        props.assetItem.id +
        "&sessionId=" +
        store.state.sessionId;

      // assetItemApi.importXLS(item.value.id!, JSON.stringify(formData));
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          console.debug("Upload successful!", result);
          _unloadJob();
          _loadJob(props.assetItem);
        })
        .catch(function () {
          console.debug("Upload failed!");
        });
    };

    let handleTimeUpdate = () => {
      console.log("TIMEUPDATE");
    };

    onMounted(() => {
      _loadJob(props.assetItem);

      setVariant();

      _onResize();
      window.addEventListener("resize", () => _onResize());
      window.addEventListener(
        "mousedown",
        (ev) => onMouseDownCloseDropdowns(ev),
        false
      );
      window.addEventListener("keydown", (event) =>
        onKeyboardClickedEvent(event)
      );
    });

    onUnmounted(() => {
      _unloadJob();

      window.removeEventListener("resize", () => _onResize());
      window.removeEventListener(
        "mousedown",
        (ev) => onMouseDownCloseDropdowns(ev),
        false
      );
      window.removeEventListener("keydown", (event) =>
        onKeyboardClickedEvent(event)
      );
    });

    return {
      windowWidth,
      isFullscreen,

      item,

      renderProgress,
      isRendering,
      renderError,
      isJobLoaded,
      isJobMasterAssetVerify,

      currentPreviewFrameLink,

      voiceTracks,
      audioUploadFileInput,

      variants,

      showVoiceOverDropdown,
      showMultiFormatDropdown,
      showMultiFormatRenderDropdown,
      showReplaceMediaDropdown,

      fileInput,

      currentMasterValue,
      currentTranslationValue,
      isTextRTL,
      currentFieldCounter,
      currentField,

      isAdvancedField,
      firstMainText,
      firstTranslationText,
      lastMainText,
      lastTranslationText,
      showSubtitleFields,
      onlySubtitleOrTextlayer,

      completeDialogVisible,
      customerMetadata,

      isAdvancedSettingsVisible,
      advancedSettingsFontAdjust,
      advancedSettingsFontSizePercent,
      advancedSettingsOffsetX,
      advancedSettingsOffsetY,
      advancedSettingsOverlayPanel,

      player,
      playerVideoControls,
      playerVideoOptions,
      playerSliderPosition,
      playerPaused,
      playerVolume,
      playerSpeed,
      showVolumeSlider,

      playButtonText,
      playButtonIcon,
      renderButtonText,

      submitJob,

      pauseOnNextField,
      fields,

      isAssetItemVariant,

      hasPermission,
      isItemRendering,
      isItemClosed,

      changeCurrentAssetItemIndex,

      onClickAddVoiceOver,
      onClickCustomVoiceOverTrack,
      onClickSubtitleToVoiceOver,

      onChangeVariant,

      onClickGenerateXLS,
      onClickImportXLS,

      getCurrentTimecode,
      getVideoURL,
      saveCurrentFieldValues,

      isAssetItemStatusReadyToApprove,
      isAssetItemStatusClosed,
      isAssetItemStatusRendering,
      isAssetItemStatusScheduled,
      isAssetItemStatusFailed,
      assetItemDownload,
      assetItemRender,

      onClickPlayButton,
      onClickPreviousFieldButton,
      onClickNextFieldButton,
      onClickPauseOnNextFieldButton,
      onClickUpdateAndWatchButton,
      onClickChangeInputField,
      onChangeVideoSliderPosition,
      onChangeVideoSliderVolume,
      onChangeVideoSpeed,
      onClickAdvancedSettings,
      onClickChangeFieldType,
      onClickDownloadOrComplete,
      onClickFinalCompleteJob,
      onClickDownloadSrt,
      onClickVerifyMasterAsset,
      onClickRenderCurrentFrame,

      selectedVoiceOverTrack,
      updateSelectedVoiceOverTrack,
      getVoiceOverTracks,
      onUploadNewAudio,

      onVideoPlay,
      onVideoPause,
      onVideoUpdate,
      onVideoEnded,
    };
  },
});

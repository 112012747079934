<template>
  <transition name="fade">
    <div
      class="modal-overlay"
      v-if="$store.state.dialog.visible"
      @click="$store.state.dialog.visible = false"
    ></div>
  </transition>
  
  <transition name="pop">
    <div
      class="modal"
      style="width: 50%; height: 20%; z-index: 1005"
      v-if="$store.state.dialog.visible"
    >
      <div
        style="
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div
          class="col-dark-blue"
          style="
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 700;
          "
        >
          {{$t($store.state.dialog.title)}}
        </div>
        
        <div v-if="$store.state.dialog.content.e"
             style="max-width: 100%; height: 50px; text-overflow: ellipsis; overflow-x: hidden"
        >
          {{$t($store.state.dialog.content.t, $store.state.dialog.content.e)}}
        </div>
        <div v-else
             style="max-width: 100%; height: 50px; text-overflow: ellipsis; overflow-x: hidden">
          {{$t($store.state.dialog.content.t)}}
        </div>
        
        <div
          style="
            width: 90%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <Button
              v-if="$store.state.dialog.btnRejectText"
              @click="
                $store.commit('HIDE_DIALOG');
                $store.state.dialog.rejectCallback
                  ? $store.state.dialog.rejectCallback()
                  : '';
              "
              :label="$t($store.state.dialog.btnRejectText)"
              class="border-radius-8 col-dark-blue bor-transparent bg-white"
              style="min-width: 120px; height: 40px; padding: 7px 6px 6px 8px"
            />
          </div>
          
          <Button
            @click="
              $store.commit('HIDE_DIALOG');
              $store.state.dialog.confirmCallback
                ? $store.state.dialog.confirmCallback()
                : '';
            "
            :label="$t($store.state.dialog.btnConfirmText)"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="min-width: 120px; height: 40px; padding: 7px 6px 6px 8px"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./Dialog.ts"></script>

<template>
  <div v-if="activeArray" class="border-radius-8 col-dark-blue bor-menu-color bg-white"
       style="position: absolute; overflow:hidden; z-index: 10007; top: 5%; left: 5%; width: 90%; height: 90%; box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.15);">
    
    <div
      style="display: flex; width:100%; height:100%; flex-direction:column; justify-content:flex-start; align-items:center;">
      <div class="col-dark-blue"
           style="height:100px; display:flex; justify-content:center; align-items:center; font-size:24px; font-weight:700;">
        Exceptions
      </div>
      
      <div style="height: 100%">
        <div v-for="(item, index) in modelValue"
             style="display: flex; align-items: center; justify-content: space-between; overflow:auto; height: 10%; width: 100%;">
          <CustomInputField v-model="modelValue[index]" :modelValue="item" type="string"/>
          <i class="pi pi-trash col-dark-blue" style="float: right" @click="modelValue.splice(index, 1)"/>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: space-between; overflow:auto; height: 10%; width: 100%;">
          <CustomInputField v-model="newValueForArrayRef" :modelValue="newValueForArrayRef" type="string"/>
          <i class="pi pi-plus col-dark-blue" style="float: right"
             @click="modelValue.push(newValueForArrayRef); newValueForArrayRef = ''"/>
        </div>
      </div>
      
      <div
        style="width: 90%; height: 100px; display:flex; align-items:center; justify-content:right; overflow: hidden">
        <Button @click="activeArray = !activeArray"
                :label="$t('general.back')"
                class="border-radius-8 col-white bor-transparent bg-dark-blue"
                style="width:72px; height:40px; padding:7px 6px 6px 8px; margin-left: 50px; margin-right: 15px"
        />
      </div>
    </div>
  
  </div>
  
  <div style="margin-left: 10px; margin-right: 10px" :class="rejected ? 'col-reject' : ''">
    <div>
      {{label}}
    </div>
    
    <div
      class="col-dark-blue bg-transparent"
      style="display:flex; flex-direction:column; justify-content:space-between; position: relative; padding:2px 5px 10px"
    >
      <InputText
        v-if="type === 'string'"
        v-model="innerModelValue"
        :id="customId ? customId : ''"
        :disabled="disabled"
        :placeholder="modelValue ? modelValue : label"
        :class="isActive ? 'bor-dark-blue-30' : 'bor-light-blue'"
        style="width:100%; height:100%; border: none; border-bottom: 1px solid; font-size:16px;"
        @change="$emit('update:modelValue', innerModelValue)"
        @input="$emit('update:modelValue', innerModelValue)"
        @focus="isActive = true"
        @blur="isActive = false"
      />
      
      <div v-else-if="type === 'boolean'"
           style="display: flex; align-items: center; cursor: pointer">
        <i
          :class="
        'pi ' +
        (innerModelValue
          ? ' pi-check-circle col-dark-blue'
          : ' pi-circle-off col-menu-color')
      "
          style="font-size: 24px"
          
          @click="$emit('update:modelValue', !modelValue)"
        ></i>
        <div
          :class="innerModelValue ? 'col-dark-blue' : 'col-menu-color'"
          style="margin-left: 8px; float: right"
        >
          {{innerModelValue ? 'Active' : 'Inactive'}}
        </div>
      </div>
      
      <InputText v-else-if="type === 'number'"
                 v-model="innerModelValue"
                 type="number"
                 :id="customId ? customId : ''"
                 :class="isActive ? 'bor-dark-blue-30' : 'bor-light-blue'"
                 style="width:100%; height:100%; border: none; border-bottom: 1px solid; font-size:16px;"
                 @change="$emit('update:modelValue', innerModelValue)"
                 @input="$emit('update:modelValue', innerModelValue)"
                 @focus="isActive = true"
                 @blur="isActive = false"
      />
      
      <div v-else-if="type === 'array'"
           @click="activeArray = !activeArray">
        
        <div
          style="width:100%; height:90%; border:0; font-size:16px; cursor:pointer;"
        >
          <div>
            <div style="float: left; padding-right: 15px">
              <i class="pi pi-angle-right"></i>
              Click to edit.
            </div>
            <div v-if="modelValue.length > 0" style="max-width: 600px; max-height: 20px; overflow: hidden">
              <div v-for="(item, index) in modelValue" class="col-menu-color" style="padding-top: 2px; float: left">
                {{index !== 0 ? ',' : ''}}
                {{item}}
              </div>
            </div>
          </div>
        
        </div>
      </div>
      
      <InputText v-else
                 v-model="innerModelValue"
                 :id="customId ? customId : ''"
                 :disabled="true"
                 :class="isActive ? 'bor-dark-blue' : 'bor-light-blue'"
                 style="width:100%; height:100%; border: 0; font-size:16px;"
                 @change="$emit('update:modelValue', innerModelValue)"
                 @input="$emit('update:modelValue', innerModelValue)"
                 @focus="isActive = true"
                 @blur="isActive = false"
      />
    </div>
  </div>
</template>

<script src="./CustomInputField.ts"></script>

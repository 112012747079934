<template>
  <div
    class="col-dark-blue-30 bg-white border-radius-16"
    style="
      width: 95%;
      height: 90%;
      box-shadow: 0 0 10px 0;
      border-color: transparent;
    "
  >
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        class="col-dark-blue"
        style="
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 600;
        "
      >
        {{$t("addAsset.previewTestvideo")}}
      </div>
      <div
        class="col-dark-blue"
        style="
          min-height: 30px;
          font-size: 16px;
          font-weight: 300;
          text-align: center;
        "
      >
        {{$t("addAsset.checkAllLayersRecognized")}}
      </div>
      <div
        id="addAssetPreviewVideoContainer"
        style="
          width: 100%;
          height: calc(100vh - 300px);
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        "
      >
        <div
          :style="
            'width:calc(' +
            currentVideoWidth +
            'px - 100px); padding-left:20px; margin-right:20px;'
          "
        >
          <video
            ref="videoPlayerRef"
            class="video-js vjs-default-skin vjs-16-9"
            controlsList="nodownload"
            oncontextmenu="return false;"
            @timeupdate="onVideoUpdate()"
          ></video>
          <div v-if="hasAssetItemFields"
               style="height: 20px; padding-top: 5px; width: 100%">
            <FieldVisualizer
              :assetId="asset.masterAssetItem.id"
              :videoLength="videoPlayerRef.duration"
              :videoPosition="currentVideoTime"
              :fieldIds="fieldIds"
              :showTimeline="true"
            />
          </div>
        </div>
      </div>
      
      <!-- FOOTER -->
      
      <div
        style="
          width: 90%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          <Button
            @click="
              $emit('update:transitionDir', 'slide-left');
              $emit(
                'update:currentPage',
                currentPage - (hasAssetItemFields ? 1 : 2)
              );
              $router.push({
                query: {
                  id: $route.query.id,
                  page:
                    parseInt($route.query.page) - (hasAssetItemFields ? 1 : 2),
                },
              });
            "
            :disabled="finished"
            label="Back"
            class="border-radius-8 col-dark-blue bor-transparent bg-white"
            style="width: 64px; height: 40px; padding: 7px 6px 6px 8px; margin-right: 5px"
          />
        </div>
        <div
          style="
            width: 300px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <i :class="'col-dark-blue pi ' + getCircleClass(1, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(2, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(3, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(4, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(5, currentPage)"></i>
        </div>
        <div
          style="
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          <Button
            @click="onClickFinish"
            :disabled="finished"
            label="Done"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="width: 64px; height: 40px; padding: 7px 6px 6px 8px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./UploaderVideoPreview.ts"></script>

<template>
  <CustomDropdown v-model:modelValue="innerModelValue"
                  :options="languages"
                  option-label="name"
                  :filter="true"
                  :includes-flag="true"
                  :selectedLanguages="selectedLanguages"
                  :dropdown-height="dropdownHeight"
                  :text-over-flow-width="textOverFlowWidth"
                  :placeholder="$t(placeholder ? placeholder : 'general.language')"
  />
</template>

<script src="./AutoCompleteLanguage.ts"></script>

<template>
  <div
    style="display: flex; align-items: center; flex-direction: column; width: 100%"
  >
    <div style="display: flex; position: relative; width: calc(100vw - 150px)">
      <ListJobApprovalItem
        v-if="show && item"
        :item="item"
        :hideControls="true"
        style="pointer-events: none"
      />
    </div>
    
    <!--///////////////////////////////////////////////-->
    
    <Transition name="slide-fade">
      <div
        v-if="show && item"
        class="col-menu-color bg-white border-radius-16"
        style="
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        height: 100%;
        width: calc(100vw - 150px);
        "
      >
        <RoundedLabel
          v-if="item"
          :countryFlag="item.language"
          :text="item.language"
          languageCode="true"
          noHover="true"
          class="col-dark-blue"
        />
        
        <div
          style="
            height: 100%;
            width: 100%;
            overflow: hidden;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
              height: 60vh;
            "
          >
            <div
              v-if="false"
              style="height: 100%"
              :style="
                'width: ' +
                  (currentVideoWidth - 180) +
                  'px; margin-bottom: 10px;'
              "
            >
              <video
                ref="videoPlayerRef"
                class="video-js vjs-default-skin vjs-16-9"
                :options="videoOptions"
              ></video>
            </div>
            
            <!--            START -->
            
            <div style="width: 50%; display: flex; flex-direction: column; gap: 20px">
              <div class="border-radius-16 bor-dark-blue"
                   style="
                      background-color: black;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      border-width: 2px;
                      height: 100%;
                      z-index: 2;
                      overflow: hidden"
              >
                <div
                  class="video-container"
                  style="display: flex; align-items: center; height: 100%; width: 100%"
                >
                  <video
                    class="video-js"
                    ref="videoPlayerRef"
                    style="
                      height: inherit;
                      background: transparent;
                      pointer-events: none;
                    "
                  />
                </div>
                <div
                  class="bg-menu-color-2"
                  style="
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px;
                    z-index: 1;
                    border-bottom-right-radius: 13px;
                    border-bottom-left-radius: 13px"
                >
                  <div style="display: flex; align-items: center; gap: 20px">
                    <RoundedLabel
                      :leftIcon="playButtonIcon"
                      class="border-radius-8 col-dark-blue bg-menu-color-2"
                      :noHover="true"
                      :noBorder="true"
                      style="
                        width: 25px;
                        padding-left: 8px;
                        height: 25px;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center"
                      @click="onClickPlayButton()"
                    />
                    
                    <div>
                      <span
                        v-if="showVolumeSlider"
                        class="bg-white border-radius-16 box-shadow-2"
                        style="position: absolute; display: flex; justify-content: center; align-items: center; height: 130px; width: 30px;  margin-top: -130px; margin-left: -7px"
                      >
                        <Slider
                          v-model="playerVolume"
                          orientation="vertical"
                          :tooltips="false"
                          :step="10"
                          style="width: 4px; height: 100px"
                          @change="onChangeVideoSliderVolume"
                        />
                      </span>
                      <i
                        class="pi pi-volume-up col-dark-blue"
                        style="font-size: 18px; margin-top: 2px; cursor: pointer"
                        @click="showVolumeSlider = !showVolumeSlider"
                      >
                      </i>
                    </div>
                    
                    <div class="col-dark-blue">
                      {{
                        videoPlayerRef
                          ? getCurrentTimecode(videoPlayerRef.currentTime)
                          : "00 : 00"
                      }}
                      /
                      {{
                        videoPlayerRef
                          ? getCurrentTimecode(videoPlayerRef.duration)
                          : "00 : 00"
                      }}
                    </div>
                  </div>
                  <div>
                    <!--                TODO: Timeline Zoom -->
                  </div>
                </div>
              </div>
              
              <div style="display: flex; flex-direction: column; gap: 10px; margin-left: 10px; margin-right: 10px">
                <div>
                  <Timeline
                    :timecode="playerPosition"
                    :assetItemId="item.id"
                    :videoLength="videoPlayerRef ? videoPlayerRef.duration : 0"
                    :hideFrames="true"
                  />
                </div>
              </div>
            </div>

            <!--            END -->

            <div
              v-if="
                !alreadyApproved &&
                  !isRejected &&
                  hasPermission('approval_view/visibility')
              "
              class="approvalControls"
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 20px;
                width: 30%;
                min-width: 170px;
                max-width: 400px;
                overflow: hidden;
                height: auto;
              "
            >
              <div
                v-if="showSendEmail"
                class="border-radius-8 bor-dark-blue"
                style="
                  display: flex;
                  align-items: center;
                  height: 60px;
                  padding: 7px 16px 6px 18px;
                  margin-right: 18px;
                  margin-bottom: 20px;
                "
                v-tooltip="$t('tooltip.sendToThirdApprover')"
              >
                <CustomInputText
                  v-model="currentEmail"
                  :label="$t('general.eMail')"
                  class="col-dark-blue bg-transparent border-radius-8"
                  style="
                    width: 400px;
                    height: 38px;
                    padding: 0 16px 0 16px;
                    margin-right: 10px;
                  "
                />
                <Button
                  @click="sendEmail()"
                  :label="$t('general.send')"
                  class="border-radius-8 col-white bor-transparent bg-dark-blue"
                  style="width: 85px; height: 40px; margin-right: 15px"
                />
              </div>
              <Button
                @click="isRejected = true"
                :label="$t('general.reject')"
                class="border-radius-8 col-dark-blue bor-dark-blue bg-white"
                style="
                  height: 60px;
                  padding: 7px 16px 6px 18px;
                  margin-right: 18px;
                  margin-bottom: 20px;"
              />
              <Button
                @click="approveItem(true)"
                :label="$t('general.approve')"
                class="border-radius-8 col-white bor-transparent bg-dark-blue"
                style="
                  height: 60px;
                  padding: 7px 16px 6px 18px;
                  margin-right: 18px;
                  margin-bottom: 20px;
                "
              />
            
            </div>
            
            <div
              v-if="isRejected"
              id="controls"
              class="approvalControls"
              style="
                position: relative;
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                max-width: 30%;
                min-width: 420px;
                overflow: hidden;
                height: auto;
              "
            >
              <div
                v-if="false"
                style="
                  display: flex;
                  margin-bottom: 10px;
                  padding: 10px 10px 10px 10px;
                  border-style: solid;
                  border-width: 1px;
                  border-radius: 20px;
                "
                v-tooltip="$t('tooltip.sendToThirdApprover')"
              >
                <CustomInputText
                  v-model="currentEmail"
                  :label="$t('general.eMail')"
                  class="col-dark-blue bg-transparent border-radius-8"
                  style="
                    width: 400px;
                    height: 38px;
                    padding: 0 16px 0 16px;
                    margin-right: 10px;
                  "
                />
                <Button
                  @click="sendEmail()"
                  :label="$t('general.send')"
                  class="border-radius-8 col-white bor-transparent bg-dark-blue"
                  style="width: 75px; height: 40px; margin-right: 15px"
                />
              </div>
              
              <div
                style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%"
              >
                <div style="overflow-x: auto; height: 80%; width: 100%">
                  <div v-for="(comment, index) in activeComments">
                    <div
                      style="display: flex;
                            align-items: center;
                            justify-content: space-between;
                             width: 100%;
                             min-height: 50px;
                             padding: 15px;
                             margin-bottom: 15px"
                      class="bor-dark-blue-70 bg-menu-color-2 border-radius-24 col-dark-blue"
                    >
                      <div>
                        <div class="col-dark-blue-70" style="font-size: 14px">
                          {{comment.timecode}}
                        </div>
                        
                        <div>
                          <Textarea
                            class="border-radius-8 bor-transparent"
                            readonly
                            autoResize
                            rows="1"
                            cols="30"
                            :value="comment.comment"
                            style="max-width: 430px; height: 10px"
                          />
                        </div>
                      </div>
                      
                      <i class="pi pi-trash"
                         style="cursor: pointer"
                         @click="activeComments.splice(index, 1)"></i>
                    </div>
                  </div>
                </div>
                
                <div style="display: flex; height: 20%; width: 100%">
                  <Textarea
                    v-model="currentComment"
                    :placeholder="$t('general.commentForRecjection')"
                    class="col-dark-blue bg-transparent border-radius-8"
                    style="
                      width: 400px;
                      min-height: 38px;
                      font-size: 16px;
                      resize: none;
                      padding: 10px 16px 0 16px;
                      margin-right: 10px;
                    "
                  />
                  <Button
                    @click="
                      addToComment(
                        getCurrentTimecode(videoPlayerRef.currentTime),
                        currentComment
                      )
                    "
                    :label="$t('general.add')"
                    class="border-radius-8 col-dark-blue bor-dark-blue bg-white"
                    style="width: 90px; margin-right: 15px"
                  />
                </div>
              </div>
              
              <div v-if="false" style="height: 80%; border-radius: 20px"> <!-- alte version hier -->
                <div
                  style="
                    height: 84%;
                    overflow-y: scroll;
                    padding: 10px 10px 10px 10px;
                    border-style: solid;
                    border-width: 1px;
                    border-radius: 20px;
                  "
                  class="approveJobBorder"
                >
                  <div
                    v-for="(element, index) in activeComments"
                    :key="element"
                    style="
                      display: flex;
                      flex-direction: row;
                      margin-bottom: 10px;
                      border-radius: 10px;
                      padding: 6px 6px 6px 6px;
                      -webkit-box-shadow: 1px 1px 2px 0px rgba(99, 99, 99, 1);
                      -moz-box-shadow: 1px 1px 2px 0px rgba(99, 99, 99, 1);
                      box-shadow: 1px 1px 2px 0px rgba(99, 99, 99, 1);
                    "
                    class="approveComment"
                  >
                    {{ element.id }}
                    <Button
                      @click="activeComments.splice(index, 1)"
                      class="col-dark-blue bor-dark-blue bg-white approveCommentDeleteBtn"
                      style="
                        left: 1%;
                        width: 23px;
                        height: 23px;
                        position: relative;
                        z-index: 3;
                        border-radius: 30px;
                        font-weight: bolder;
                      "
                    >
                      -
                    </Button>
                    <Button
                      style="
                        margin-left: 10px;
                        text-overflow: ellipsis;
                        min-width: fit-content;
                        height: 30px;
                        border: transparent;
                        border-radius: 20px;
                      "
                      class="col-dark-blue bor-dark-blue bg-transparent approveTimestamp"
                      @click="setCurrentTimecode(element[0])"
                    >
                      @ {{element[0]}}
                    </Button>
                    <div
                      style="margin-left: 30px; word-wrap: break-word"
                      class="approveCommentText"
                    >
                      {{element[1]}}
                    </div>
                  </div>
                </div>
                
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 10px 10px 10px;
                    margin-top: 10px;
                    border-style: solid;
                    border-width: 1px;
                    border-radius: 20px;
                  "
                >
                  <div
                    style="margin-right: 5px; font-size: 16px; min-width: 70px"
                  >
                    @{{currentVideoTime}}
                  </div>
                  <Textarea
                    v-model="currentComment"
                    :placeholder="$t('general.commentForRecjection')"
                    class="col-dark-blue bg-transparent border-radius-8"
                    style="
                      width: 400px;
                      min-height: 38px;
                      font-size: 16px;
                      resize: none;
                      padding: 10px 16px 0 16px;
                      margin-right: 10px;
                    "
                  />
                  <Button
                    @click="addToComment(currentVideoTime, currentComment)"
                    :label="'Add'"
                    class="border-radius-8 col-dark-blue bor-dark-blue bg-white"
                    style="width: 75px; height: 40px; margin-right: 15px"
                  />
                </div>
              </div>
            </div>
          </div>
          
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 15px;
            "
          >
            <Button
              @click="
                isRejected ? (isRejected = false) : $router.push('/jobApproval')
              "
              :label="$t('general.cancel')"
              class="border-radius-8 col-dark-blue bor-transparent bg-white"
              style="
                width: 64px;
                height: 40px;
                padding: 7px 6px 6px 8px;
                margin-left: 18px;
                margin-bottom: 20px;
              "
            />
            
            <Button
              v-if="isRejected"
              @click="approveItem(false)"
              :label="$t('general.confirm')"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="
                  height: 40px;
                  padding: 7px 16px 6px 18px;
                  margin-right: 15px;
                "
            />
          </div>
        </div>
      
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.p-inputtext {
  background-color: transparent;
}

.approveCommentDeleteBtn {
  border-color: var(--dark-blue) !important;
  color: var(--dark-blue);
  background-color: transparent;
}

.approveTimestamp {
  color: var(--dark-blue);
}

.approveComment {
  background-color: var(--menu-color);
}

.approveCommentText {
  color: var(--black);
}
</style>

<script src="./ApproveJobView.ts"></script>

import {defineComponent, getCurrentInstance, onMounted, ref} from "vue";
import {AssetApi, SystemApi, AssetItemApi, UserGroupApi} from "../../../target/api";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {Asset, AssetItem, CustomerMetadata, UpdateAssetObject} from "target/api/de/moovit/titletoolserver/model";
import {uuid} from "vue-uuid";

export default defineComponent({
  props: ["currentPage", "currentFile", "transitionDir", "propsValues"],
  components: {},
  setup: function (props: any, context) {
    const route = useRoute();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;
    const router = useRouter();
    const store = useStore();
    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();
    const userGroupApi = new UserGroupApi();
    const systemApi = new SystemApi();

    let submitted = ref(false);

    let currentId: any = ref(undefined);
    let currentProjectName: any = ref(undefined);
    let currentLanguage: any = ref(undefined);
    let currentGroup: any = ref(undefined);
    let lastGroup: any = ref(undefined);

    let currentAsset = ref<Asset>({});

    let customerMetadata = ref<Array<CustomerMetadata>>([]);

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return " pi-check-circle";
      if (page > currentPage) return " pi-circle-on col-menu-color";
      if (page == currentPage) return " pi-circle-on ";
    };

    let onClickCancel = async () => {
      if (currentAsset.value && currentAsset.value.id) {
        assetApi
          .cancelAssetUpload(currentAsset.value.id)
          .then((response) => {
            resetView();
          })
          .catch((err) => {
            console.debug(err);
          });
      } else {
        resetView();
      }
    };

    let resetView = () => {
      context.emit("update:currentFile", null);
      currentId.value = undefined;
      currentProjectName.value = "";
      context.emit("update:currentPage", 1);
      context.emit("update:currentFile", undefined);
      context.emit("update:currentLanguage", undefined);
      context.emit("update:assets", []);
      context.emit("update:propsValues", {});
      currentId.value = "";
      currentProjectName.value = "";
      currentLanguage.value = {name: "", value: ""};
      currentGroup.value = "";
      currentAsset.value = {};

      emitter.emit("set-data-loaded", false);
      router.push({
        query: {},
      });
    };

    let submitChanges = async () => {
      console.debug("AddAsset - ITEM", currentAsset.value);

      submitted.value = true;

      if (!currentProjectName.value || !currentLanguage.value || currentLanguage.value.name === "" || currentLanguage.value.value === "" || !currentGroup.value || checkCustomerMetadataRequired()) {
        return;
      }

      if (currentId.value) {
        currentAsset.value.thirdPartyID = currentId.value;
      }
      currentAsset.value.name = currentProjectName.value;
      currentAsset.value.groupID = currentGroup.value;
      currentAsset.value.masterAssetItem!.language = currentLanguage.value.name;

      console.debug("AddAsset - UPDATE ASSET", currentAsset.value);

      let updateAssetObject: UpdateAssetObject = {};
      updateAssetObject.asset = currentAsset.value;

      await assetApi.updateAsset(updateAssetObject);

      let assetItem: AssetItem = (await assetItemApi.getAssetItemById(currentAsset.value.id!)).data;
      assetItem.language = currentLanguage.value.name;
      assetItem.assetName = currentProjectName.value;

      console.debug("Update Asset Item", assetItem);

      assetItemApi.updateAssetItemValues(assetItem);

      context.emit("update:propsValues", {
        currentId: currentId.value,
        currentProjectName: currentProjectName.value,
        currentLanguage: currentLanguage.value,
        currentGroup: currentGroup.value,
        customerMetadata: customerMetadata.value,
      });

      setTimeout(() => {
        context.emit("update:transitionDir", "slide-right");
        context.emit("update:currentPage", props.currentPage + 1); // (assetItem.fields!.length == 0 ? 2 : 1));
        router.push({
          query: {id: route.query.id, page: parseInt(<string>route.query.page) + 1}, //(assetItem.fields!.length == 0 ? 2 : 1) },
        });
      }, 100);
    };

    let checkCustomerMetadataRequired = () => {
      if (!customerMetadata.value) {
        return true;
      }
      for (const metadata of customerMetadata.value) {
        if (metadata.required) {
          switch (metadata.type) {
            case "STRING":
              if (metadata.typeValueString === undefined) {
                return true;
              }
              break;
            case "INTEGER":
              if (metadata.typeValueInteger === undefined) {
                return true;
              }
              break;
            case "DOUBLE":
              if (metadata.typeValueDouble === undefined) {
                return true;
              }
              break;
            case "BOOLEAN":
              if (metadata.typeValueBoolean === undefined) {
                return true;
              }
              break;
          }
        }
      }
      return false;
    }

    let onChangeGroup = async () => {
      if (lastGroup.value !== currentGroup.value) {
        lastGroup.value = currentGroup.value;
        customerMetadata.value =
          (await systemApi.searchCustomerMetadataTemplate("", currentGroup.value ?
            currentGroup.value : JSON.parse(localStorage.loginData).user.groups[0].id)).data;

        for (const metadata of customerMetadata.value) {
          metadata.id = uuid.v1();
          if (metadata.type === 'BOOLEAN') {
            metadata.typeValueBoolean = false;
          }
        }
      }
    }

    onMounted(async () => {
      if (props.propsValues !== undefined) {
        console.debug("props.propsValues", props.propsValues);
        currentId.value = props.propsValues.currentId;
        currentProjectName.value = props.propsValues.currentProjectName;
        currentLanguage.value = props.propsValues.currentLanguage;
        currentGroup.value = props.propsValues.currentGroup;

        customerMetadata.value = props.propsValues.customerMetadata;
      }

      let getAssetResponse = await assetApi.getAssetById("" + route.query.id);
      // let getAssetResponse = await assetApi.getAssetById('f7409c30-8fd7-11ec-9a64-878bbd473365');
      if (getAssetResponse.data) {
        console.debug("AddAssetViewDetails - ASSET", getAssetResponse.data);

        currentAsset.value = getAssetResponse.data;

        currentId.value = getAssetResponse.data.thirdPartyID;
        currentProjectName.value = getAssetResponse.data.name;
        currentGroup.value = getAssetResponse.data.groupID;
        if (getAssetResponse.data.masterAssetItem && getAssetResponse.data.masterAssetItem.language) {
          currentLanguage.value = {
            name: getAssetResponse.data.masterAssetItem.language,
            value: getAssetResponse.data.masterAssetItem.language,
          };
        }
      }
    });

    return {
      getCircleClass,
      submitted,
      currentId,
      onClickCancel,
      currentProjectName,
      currentLanguage,
      currentGroup,
      submitChanges,
      customerMetadata,
      onChangeGroup,
    };
  },
});

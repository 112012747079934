import {defineComponent, getCurrentInstance, onMounted, onUnmounted, ref} from 'vue';
import {
  AssetItem,
  AssetItemStatus,
  SearchAssetItemsObject,
  User,
  UserRole,
} from '../../../target/api/de/moovit/titletoolserver/model';
import {useStore} from 'vuex';
import {UserApi, AssetItemApi} from '../../../target/api';
import {useRouter} from "vue-router";

export default defineComponent({
  components: {},
  setup: function () {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;
    const store = useStore();
    const router = useRouter();

    let sortColumn = ref('completed');
    let sortAsc = ref(false);
    let searchText = ref('');

    let page: number = 0;
    let pageSize: number = 20;

    let jobsRef: any | undefined;
    let addingAssets = ref(false);
    let added0Assets = ref(false);

    let jobs = ref([]);

    let lastClickedItem = ref<string>();

    let assetItemApi = new AssetItemApi();
    const userApi = new UserApi();

    let getVideoURL = (assetItem: AssetItem) => {
      if (assetItem && assetItem.id) {
        return (
          process.env.VUE_APP_TT_ENDPOINT +
          '/videostream?access_token=' +
          JSON.parse(localStorage.loginData).access_token +
          '&id=' +
          assetItem.id +
          '&tc=' + assetItem.lastModified
        );
      } else {
        return null;
      }
    };

    let playVideo = (id: string) => {
      let video: any = document.getElementById('approvalVideo.' + id);
      if (video) {
        video.play();
      }
    }

    let pauseVideo = (id: string) => {
      let video: any = document.getElementById('approvalVideo.' + id);
      if (video) {
        video.pause();
      }
    }

    let changeSort = (column: string) => {
      if (sortColumn.value == column) {
        sortAsc.value = !sortAsc.value;
      } else {
        sortColumn.value = column;
        sortAsc.value = true;
      }
      searchApprovals();
    };

    let onBlurSearchField = () => {
      console.debug('onBlurSearchField = ()');
      searchApprovals();
    };

    let getApprovals: any = async () => {
      let searchApprovalsObject: SearchAssetItemsObject = {};

      let languages = [];
      let groups = [];
      let translators = [];

      for (let i in store.state.filterBreadcrumbs) {
        let filterBreadcrumb = store.state.filterBreadcrumbs[i];

        if (filterBreadcrumb.type == 'LANGUAGE') languages.push(filterBreadcrumb.name);
        if (filterBreadcrumb.type == 'GROUP') groups.push(filterBreadcrumb.name);
        if (filterBreadcrumb.type == 'TRANSLATOR') translators.push(filterBreadcrumb.name);
      }

      let user: User = (await userApi.getUserById(JSON.parse(localStorage.loginData).user.id)).data;
      let userIsAdmin: boolean = user.role == UserRole.ADMIN

      if (!userIsAdmin && user && user.groups) {
        for (const group of user.groups) {
          if (group.id) {
            groups.push(group.id);
          }
        }
      }

      searchApprovalsObject.languages = languages;
      searchApprovalsObject.groups = groups;
      searchApprovalsObject.translators = translators;

      searchApprovalsObject.page = page;
      searchApprovalsObject.pageSize = pageSize;
      searchApprovalsObject.searchText = searchText.value;
      searchApprovalsObject.assetItemStatus = [AssetItemStatus.READYTOAPPROVE];

      searchApprovalsObject.ascSort = sortAsc.value;
      searchApprovalsObject.sortColumn = sortColumn.value;

      let searchAssetItemsResponse = await assetItemApi.searchAssetItems(searchApprovalsObject);
      if (searchAssetItemsResponse) {
        console.debug('JobApprovalView - ITEMS', searchAssetItemsResponse.data);
        jobs.value = [];
        return searchAssetItemsResponse.data;
      }
    };

    let searchApprovals = async () => {
      page = 0;
      jobs.value = await getApprovals();
    };

    let addApprovals = async () => {
      if (addingAssets.value || added0Assets.value) return;

      addingAssets.value = true;
      page++;

      let newApprovals: any = await getApprovals();
      jobs.value = jobs.value.concat(newApprovals);
      console.debug('ADDED ' + newApprovals.length + ' ASSETS');

      if (newApprovals.length == 0) {
        added0Assets.value = true;
      }

      addingAssets.value = false;
    };

    let onClick = (e: any) => {
      if (e.target.id == 'roundedLabel') return;
      if (e.target.id == 'flag') return;
      if (!e.target.id.includes('approval.')) return;

      let assetItem: AssetItem = jobs.value.filter((value: AssetItem) => {
          return value.id === e.target.id.split('.')[1]
        }
      )[0];

      hasPermission('approval_view/click_items/to_be_approved')
        ? router.push({
          name: 'approveJob',
          query: {id: assetItem.id},
        })
        : '';
    };

    let setLastClickedItem = (event: any) => {
      lastClickedItem.value = event.data.id;
    }

    let hasPermission = (permString: string) => {
      return JSON.parse(localStorage.loginData).user.permissions.permissions[permString];
    };

    onMounted(async () => {
      emitter.on('search-asset-items', () => {
        searchApprovals();
      });
      searchApprovals();

      let tJobApprovals = {
        t: 'activityBreadcrumbs.jobApprovals',
        e: undefined,
      };
      store.commit('SET_ACTIVITY_BREADCRUMBS', [tJobApprovals]);

      document.addEventListener('click', onClick);
    });

    onUnmounted(() => {
      emitter.off('search-asset-items');

      document.removeEventListener('click', onClick);
    });

    return {
      jobs,
      sortColumn,
      sortAsc,
      searchText,
      changeSort,
      onBlurSearchField,
      getVideoURL,
      playVideo,
      pauseVideo,
      setLastClickedItem,
      addingAssets,
      added0Assets,
    };
  },
});

import {
  AssetItem,
  AssetItemStatus,
  AssetStatus,
  NotificationDto,
  WorkflowEngineMessageType,
} from "../../../target/api/de/moovit/titletoolserver/model";
import {defineComponent, getCurrentInstance, nextTick, onMounted, onUnmounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import videojs from "video.js";
import {AssetApi, AssetItemApi, SystemApi, UserApi} from "../../../target/api";
import {useStore} from "vuex";

export default defineComponent({
  components: {},
  setup: function () {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let assetItemApi: AssetItemApi = new AssetItemApi();
    let assetApi: AssetApi = new AssetApi();
    let systemApi: SystemApi = new SystemApi();
    let userApi: UserApi = new UserApi();
    let item = ref<AssetItem>();

    let showControls = ref(false);
    let show = ref(true);

    let player: any = ref();
    let videoPlayerRef: any = ref(null);
    let videoOptions: any = ref({});
    let updateJobs = ref(false);

    let playButtonIcon = ref<string>("pi pi-play");
    let playerPosition = ref<number>(0);
    let showVolumeSlider = ref<boolean>();
    let playerVolume = ref<number>(100);

    let intervalId: number | undefined = undefined;

    let currentVideoTime: any = ref();
    let currentComment = ref<string>("");

    let currentLanguage = ref(undefined);
    let currentVideoWidth: any = ref(undefined);

    let currentClientWidth: any = ref<number>(0);

    let showSendEmail = ref<boolean>(false);
    let currentEmail = ref<string>();

    let isRejected = ref<boolean>(false);

    let alreadyApproved = ref<boolean>(false);

    let activeComments = ref<Array<Comment>>([]);

    class Comment {
      comment: string = ""
      timecode: string = ""

      constructor(comment: string, timestamp: string) {
        this.comment = comment;
        this.timecode = timestamp;
      }
    }

    let getVideoURL = (assetItem: AssetItem) => {
      if (assetItem && assetItem.id) {
        return (
          process.env.VUE_APP_TT_ENDPOINT +
          "/videostream?access_token=" +
          JSON.parse(localStorage.loginData).access_token +
          "&id=" +
          assetItem.id
        );
      } else {
        return null;
      }
    };

    let addToComment = (timestamp: string, comment: string) => {
      if (activeComments.value && currentComment.value) {
        activeComments.value.push(new Comment(comment, timestamp));

        currentComment.value = "";
      }
    };

    let setCurrentTimecode = (timecode: string) => {
      let timecodeArray = timecode.split(":");
      let min = parseInt(timecodeArray[0]) * 60;
      let sec = parseInt(timecodeArray[1]);
      let numberTimecode = min + sec;
      videoPlayerRef.value.currentTime = numberTimecode;
    };

    let getCurrentTimecode = (seconds: number) => {
      if (!seconds) {
        return "00 : 00"
      }

      let minutes = Math.floor(seconds / 60);
      seconds -= minutes * 60;

      let minuteString: string = minutes + "";
      if (minutes < 10) minuteString = "0" + minutes;

      let secondString: string = Math.floor(seconds) + "";
      if (seconds < 10) secondString = "0" + Math.floor(seconds);

      currentVideoTime.value = minuteString + " : " + secondString;
      return minuteString + " : " + secondString;
    };

    let resize = () => {
      currentClientWidth.value = document.documentElement.clientWidth;

      let approveJobVideoContainer: any = document.getElementById(
        "approveJobVideoContainer"
      );

      if (approveJobVideoContainer) {
        console.debug("resize", document.documentElement.clientHeight);
        //currentVideoWidth.value = document.documentElement.clientHeight -100;
        let newHeight = document.documentElement.clientHeight - 280;
        currentVideoWidth.value = (newHeight / 9) * 16;
        // let maxWidth = document.documentElement.clientWidth - 250;
        // let maxHeight = approveJobVideoContainer.clientHeight;
        // let quotient = maxWidth / 16 / (maxHeight / 9);

        // if (quotient > 1) {
        //   currentVideoWidth.value = maxHeight * (16 / 9);
        // } else {
        //   currentVideoWidth.value = document.documentElement.clientWidth - 250;
        // }
      }
    };

    let approveItem = (isApproved: boolean) => {
      if (item.value) {
        item.value.status = isApproved
          ? AssetItemStatus.CLOSED
          : AssetItemStatus.NEW;
        assetItemApi.updateAssetItemValues(item.value).then(async () => {
          if (item.value) {
            await assetApi.calculateAssetStatus(item.value.id!);

            let notifyDto: NotificationDto;

            if (isApproved) {
              if (item.value.assetID) {
                let asset = (await assetApi.getAssetById(item.value.assetID))
                  .data;

                if (asset) {
                  if (asset.assetStatus === AssetStatus.COMPLETE) {
                    let notifyDto: NotificationDto;
                    notifyDto = {topic: WorkflowEngineMessageType.JOBSUBMITTED};
                    notifyDto.userId = item.value!.translatorID;
                    notifyDto.assetItem = item.value;

                    console.debug("-->", notifyDto);
                    systemApi.sendMessageToWorkflowEngine(
                      notifyDto.topic,
                      notifyDto
                    );

                    store.commit("SHOW_SNACKBAR", {
                      snackbarType: 'info',
                      snackbarContent: {t: "snackbar.assetApproved"},
                    });
                  }
                }
              }
            } else {

              for (const key in activeComments.value) {
                let value = activeComments.value[key];

                currentComment.value = currentComment.value +
                  "(" + value.timecode + ") </br> "
                  + value.comment.replace(/$/gm, "</br>")
                  + "</br>";
              }

              if (!currentComment.value) {
                currentComment.value = "No comment provided.";
              }

              notifyDto = {topic: WorkflowEngineMessageType.JOBREJECTED};
              notifyDto.requestor = item.value!.approverID;
              notifyDto.userId = item.value!.translatorID;
              notifyDto.text = currentComment.value;
              notifyDto.assetItem = item.value;

              console.debug("-->", notifyDto);
              systemApi.sendMessageToWorkflowEngine(notifyDto.topic, notifyDto);

              currentComment.value = "";

              store.commit("SHOW_SNACKBAR", {
                snackbarType: 'info',
                snackbarContent: {t: "snackbar.assetRejected"},
              });
            }
            router.push("/jobApproval");
          }
        });
      }
    };

    let sendEmail = async () => {
      //add email receiver in notify for Send button
      if (!currentEmail.value) {
        return;
      }

      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(currentEmail.value)) {
        return;
      }

      let notifyDto: NotificationDto;

      notifyDto = {topic: WorkflowEngineMessageType.JOBSUBMITTEDFORAPPROVAL};
      notifyDto.email = currentEmail.value;
      notifyDto.assetItem = item.value!;

      console.debug("-->", notifyDto);
      systemApi.sendMessageToWorkflowEngine(notifyDto.topic, notifyDto);

      store.commit("SHOW_SNACKBAR", {
        snackbarContent: {t: "snackbar.emailSentTo", e: [currentEmail.value]},
      });

      currentEmail.value = "";
    };

    let onClickPlayButton = () => {
      if (player.value) {
        if (player.value.paused()) {
          player.value.play();
          playButtonIcon.value = "pi pi-pause";
        } else {
          player.value.pause();
          playButtonIcon.value = "pi pi-play";
        }
      }
    };

    let onChangeVideoSliderVolume = (value: any) => {
      videoPlayerRef.value.volume = value / 100;
    };

    let onChangeVideoSliderPosition = (timecode: number) => {
      if (!player.value.paused()) {
        onClickPlayButton();
      }

      const newPlayerTime = (timecode / 100) * videoPlayerRef.value.duration;

      videoPlayerRef.value.currentTime = newPlayerTime;
      currentVideoTime.value = newPlayerTime;

      onClickPlayButton();
      onClickPlayButton();
    };

    let hasPermission = (permString: string) => {
      if (localStorage.loginData)
        return JSON.parse(localStorage.loginData).user.permissions.permissions[permString];
    };

    onMounted(async () => {
      let getAssetByIdResponse = await assetItemApi.getAssetItemById(
        "" + route.query.id
      );
      item.value = getAssetByIdResponse.data;
      console.debug(item.value);

      if (item.value) {
        alreadyApproved.value = item.value.status == AssetItemStatus.CLOSED;
      }

      let tJobApprovals = {
        t: "activityBreadcrumbs.jobApprovals",
        e: undefined,
      };
      let tApproveJob = {
        t: "activityBreadcrumbs.approveJob",
        e: [item.value!.assetName],
      };
      store.commit("SET_ACTIVITY_BREADCRUMBS", [tJobApprovals, tApproveJob]);


      emitter.on("onChangeVideoSliderPosition" + item.value.id, (position: number) => {
        onChangeVideoSliderPosition(position);
      });

      nextTick(() => {
        show.value = true;
      }).then((ignored) => {
        videoOptions.value = {
          loop: true,
          controls: false,
          bigPlayButton: false,
          sources: [
            {
              src: getVideoURL(item.value!),
              type: "video/mp4",
            },
          ],
        };

        player.value = videojs(videoPlayerRef.value, videoOptions.value);

        systemApi.getSystemPreference("allowThirdPartyApprover").then(key => {
          showSendEmail.value = (key.data.value as unknown as boolean);
        });

        intervalId = window.setInterval(() => {
          if (videoPlayerRef.value && player && player.value && !player.value.paused()) {
            playerPosition.value = (videoPlayerRef.value.currentTime / videoPlayerRef.value.duration) * 100;
          }
        }, 40);
      });

      window.addEventListener("resize", function () {
        resize();
      });

      nextTick(() => {
        resize();
      });
    });

    onUnmounted(() => {
      window.removeEventListener("resize", function () {
        resize();
      });
      emitter.off("onChangeVideoSliderPosition" + item.value!.id);

      if (intervalId) {
        window.clearInterval(intervalId);
      }
    });

    return {
      show,
      showControls,
      item,
      currentLanguage,
      currentVideoWidth,
      updateJobs,
      player,
      videoPlayerRef,
      videoOptions,
      playerPosition,
      showVolumeSlider,
      playerVolume,
      playButtonIcon,
      showSendEmail,
      currentEmail,
      currentComment,
      currentClientWidth,
      currentVideoTime,
      activeComments,
      isRejected,
      alreadyApproved,

      onChangeVideoSliderVolume,
      onChangeVideoSliderPosition,
      onClickPlayButton,

      approveItem,
      sendEmail,
      getCurrentTimecode,
      addToComment,
      setCurrentTimecode,
      hasPermission,
    };
  },
});

<template>
  
  <Accordion :multiple="true">
    <AccordionTab v-for="(setting, key) in settings" :header="key">
      <div v-for="(value, index) in setting" style="display: flex; flex-direction: row; min-height: 56px"
           :style="index % 2 === 0 ? 'background-color: rgba(13,29,79,0.05)' : ''">
        <div style="width: 250px; display: flex; justify-content: left; align-items: center">
          {{value.displayname}}
          <div v-tooltip="value.tooltip">
            <i class="pi pi-info-circle col-dark-blue"
               style="font-size: 12px; padding: 0 10px 20px 10px; cursor: pointer"></i>
          </div>
        </div>
        <div style="width: 70%; margin: 5px 50px 5px 50px">
          
          <div v-if="value.type==='STRING'">
            <CustomInputText v-model:modelValue="value.value"
                             style="width: 90%; max-width: 800px"
                             @change="commitSettings(value)"/>
          </div>
          
          <div v-else-if="value.type==='INTEGER'">
            <InputNumber inputId="horizontal"
                         v-model="value.value"
                         showButtons
                         buttonLayout="horizontal"
                         :step="1"
                         decrementButtonClass="p-button-danger"
                         incrementButtonClass="p-button-success"
                         incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         :useGrouping="false"
                         mode="decimal"
                         style="width: 90%; max-width: 800px; min-width: 50px"
                         @blur="commitSettings(value)"/>
          </div>
          <div v-else-if="value.type==='DOUBLE'">
            <InputNumber inputId="horizontal"
                         v-model="value.value"
                         showButtons
                         buttonLayout="horizontal"
                         :step="0.1"
                         decrementButtonClass="p-button-danger"
                         incrementButtonClass="p-button-success"
                         incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         :useGrouping="false"
                         mode="decimal"
                         style="width: 90%; max-width: 800px; min-width: 50px"
                         @blur="commitSettings(value)"/>
          </div>
          
          <div v-else-if="value.type==='BOOLEAN'"
               style="height: 100%; display: flex; justify-content: left; align-items: center">
            <Switch v-model="value.value" style="width: 68px"
                    @click="commitSettings(value)"/>
          </div>
          
          <div v-else-if="value.type==='ARRAY'"
               style="height: 100%; display: flex; justify-content: left; align-items: center">
            <SettingsListItem :settings="value" :isFirstLineEditable="false" :isDeletable="true"
                              :allowAddNew="true"/>
          </div>
          
          <div v-else-if="value.type==='MAP'"
               style="height: 100%; display: flex; justify-content: left; align-items: center">
            <SettingsListItem :settings="value" :isFirstLineEditable="true" :isDeletable="true"
                              :allowAddNew="true"/>
          
          </div>
          
          <div v-else-if="value.type==='METADATA_ARRAY'"
               style="height: 100%; display: flex; justify-content: left; align-items: center">
            <CustomerMetadataDialog/>
          </div>
          
          <div v-else>
            <CustomInputText v-model:modelValue="value.value"
                             :disabled="true"
                             style="width: 90%; max-width: 800px"/>
          </div>
        
        </div>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script src="./AdminControlViewSettings.ts"></script>

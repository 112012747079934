import {defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';

export default defineComponent({
  props: ['firstText', 'mainText', 'lastText', 'index', 'disabled', 'customId', 'assetItemId', 'disableSwitches'],
  components: {},
  setup: function (props) {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let innerModelValue = ref<string>();

    let nextField = () => {
      emitter.emit('nextField' + props.assetItemId, null);
    }

    let previousField = () => {
      emitter.emit('previousField' + props.assetItemId, null);
    }

    let onScroll = () => {
      let elems = document.getElementsByClassName("container");
      let sT = elems[1].scrollTop;

      if (sT) {
        for (let i = 0; i < elems.length; i++) {
          elems[i].scrollTop = sT;
        }
      }
    }

    let saveCurrentFieldValuesEmit = () => {
      emitter.emit('updateFieldValues' + props.assetItemId, innerModelValue.value);
    }

    watch(props, () => {
      innerModelValue.value = props.mainText;
    });

    onMounted(() => {
      innerModelValue.value = props.mainText;
    });

    return {
      innerModelValue,

      saveCurrentFieldValuesEmit,

      nextField,
      previousField,
      onScroll
    };
  },
});

<template>
  <transition name="fade">
    <div class="modal-overlay" v-if="addNew" @click="addNew = false"></div>
  </transition>
  
  <transition name="pop">
    <div class="modal" style="width: 50%; height: 70%;" v-if="addNew">
      <div
        style="display: flex; width:100%; height:100%; flex-direction:column; justify-content:flex-start; align-items:center;"
      >
        <div
          class="col-dark-blue"
          style="height:100px; display:flex; justify-content:center; align-items:center; font-size:24px; font-weight:700;"
        >
          Add New FontMapping
        </div>
        
        <div
          style="overflow:auto; display: grid; gap: 15px; vertical-align: center; width: 100%; height: 100%;"
        >
          <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
            <AutoCompleteGroup
              v-model="newFontMappings.group"
              :addAllGroup="true"
              :class="submitted && !newFontMappings.group && !newFontMappingLanguage ? 'bor-reject' : ''"
              class="border-radius-8 col-dark-blue bor-menu-color bg-transparent"
              style="width:250px; height:50px;"
            />
            <AutoCompleteLanguage
              v-model="newFontMappingLanguage"
              :addAllLanguage="true"
              :modelValue="newFontMappingLanguage"
              :class="submitted && !newFontMappingLanguage && !newFontMappings.group ? 'bor-reject' : ''"
              class="border-radius-8 col-dark-blue bor-menu-color bg-transparent"
              style="width:250px; height:50px; margin-left:10px;"
            />
          </div>
          
          <div style="display:grid; gap: 15px; height: 100%; width: 100%;">
            <CustomInputField
              v-model="newFontMapping.oldFullName"
              :modelValue="newFontMapping.oldFullName"
              :rejected="submitted && !newFontMapping.oldFullName"
              label="Old Fullname"
              type="string"
            />
            <CustomInputField
              v-model="newFontMapping.newFullName"
              :modelValue="newFontMapping.newFullName"
              :rejected="submitted && !newFontMapping.newFullName"
              label="New Fullname"
              type="string"
            />
            <CustomInputField
              v-model="newFontMapping.oldFont"
              :modelValue="newFontMapping.oldFont"
              label="Old Font"
              type="string"
            />
            <CustomInputField
              v-model="newFontMapping.newFont"
              :modelValue="newFontMapping.newFont"
              label="New Font"
              type="string"
            />
            <CustomInputField
              v-model="newFontMapping.oldStyle"
              :modelValue="newFontMapping.oldStyle"
              label="Old Style"
              type="string"
            />
            <CustomInputField
              v-model="newFontMapping.newStyle"
              :modelValue="newFontMapping.newStyle"
              label="New Style"
              type="string"
            />
            <CustomInputField
              v-model="newFontMapping.fontFactor"
              :modelValue="newFontMapping.fontFactor"
              label="Font Factor"
              type="number"
            />
            <CustomInputField
              v-model="newFontMapping.resetTracking"
              :modelValue="newFontMapping.resetTracking"
              label="Reset Tracking"
              type="boolean"
            />
            <CustomInputField
              v-model="newFontMapping.resetLineSpacing"
              :modelValue="newFontMapping.resetLineSpacing"
              label="Reset Line Spacing"
              type="boolean"
            />
            <CustomInputField
              v-model="newFontMapping.allCaps"
              :modelValue="newFontMapping.allCaps"
              label="All Caps"
              type="boolean"
            />
            <CustomInputField
              v-model="newFontMapping.exceptions"
              :modelValue="newFontMapping.exceptions"
              label="Exceptions"
              type="array"
            />
          </div>
        </div>
        
        <div
          style="width: 90%; height: 100px; display:flex; align-items:center; justify-content:space-between; overflow: hidden"
        >
          <Button
            @click="toggleNewFontMapping(false)"
            label="Cancel"
            class="border-radius-8 col-dark-blue bor-transparent bg-white"
            style="min-width:120px; height:40px; padding:7px 6px 6px 8px; margin-left: 50px"
          />
          
          <Button
            @click="toggleNewFontMapping(true)"
            label="Save"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="min-width:120px; height:40px; padding:7px 6px 6px 8px; margin-right: 50px"
          />
        </div>
      </div>
    </div>
  </transition>
  
  <div style="display:flex; justify-content: space-between; align-items: center">
    <div style="display:flex; align-items:center;">
      <RoundedLabel
        @click="$store.commit('SHOW_ADD_FILTER_DIALOG', {
          hideStatus: true,
          hideTranslator: true,
          hideLocked: true,
          hideCostCenter: true,
          hideRoleDropdown: true,
          hideDefaultLanguage: true
        })"
        leftIcon="pi pi-filter"
        :text="$t('general.addFilter')"
        class="bor-dark-blue"
        style="cursor:pointer; height:40px; margin-right:24px;"
      />
      <FilterBreadcrumb :items="$store.state.filterBreadcrumbs" maxWidth="calc(100vw - 1030px)"/>
    </div>
    
    <div>
      <RoundedLabel
        @click="toggleNewFontMapping()"
        leftIcon="pi pi-plus"
        :text="$t('general.addFontMapping')"
        style="cursor: pointer; height: 40px; margin-right: 12px; float: left"
        class="bor-dark-blue"
      />
      <RoundedLabel
        @click="fontMappingXmlUpload.click()"
        leftIcon="pi pi-upload"
        :text="$t('general.uploadFontMappingXml')"
        style="cursor:pointer; height:40px; margin-right: 12px; float: left"
        class="bor-dark-blue"
      />
      <input
        type="file"
        style="display: none"
        ref="fontMappingXmlUpload"
        accept=".xml"
        @change="onClickUploadXml"/>
      
      <SearchField
        v-model="searchText"
        style="padding-right: 20px"
        :blurFunction="onBlurSearchField"
      />
    </div>
  </div>
  
  <div style="display:flex; width:98%; height: 40px; margin-top: 20px;">
    <div style="width:2%;height: 40px;"></div>
    
    <div style="width:20%;height: 40px; display:flex; cursor:pointer;">
      <div @click="changeSort('language')" style="display:flex;">
        {{$t('general.language')}}
        <div v-if="sortColumn === 'language'">
          <i v-if="sortAsc" class="pi pi-angle-up" style="margin-left:4px"></i>
          <i v-if="!sortAsc" class="pi pi-angle-down" style="margin-left:4px"></i>
        </div>
      </div>
    </div>
    
    <div @click="changeSort('group')" style="width:20%;height: 40px; display:flex; cursor:pointer;">
      {{$t('general.group')}}
      <div v-if="sortColumn === 'group'">
        <i v-if="sortAsc" class="pi pi-angle-up" style="margin-left:4px"></i>
        <i v-if="!sortAsc" class="pi pi-angle-down" style="margin-left:4px"></i>
      </div>
    </div>
    
    <div style="width:20%;height: 40px; display:flex; cursor:pointer;">
      {{$t('general.oldFullName')}}
    </div>
    
    <div style="width:20%;height: 40px; display:flex; cursor:pointer;">
      {{$t('general.newFullName')}}
    </div>
  </div>
  
  <div style="height: 90%; overflow-y: auto;">
    <div v-for="(mappings, index) in fontMappings" :key="index">
      <ListFontMappingItem
        v-for="(map, ind) in mappings.fontMappings"
        :key="ind"
        :language="mappings.language"
        :group="mappings.group"
        :mapping="map"
        :index="ind"
      />
    </div>
  </div>
</template>

<script src="./AdminControlViewFontMapping.ts"></script>

import {
  Asset,
  Field,
  FieldType,
  SubtitleOptions,
  UserRole
} from "../../../../target/api/de/moovit/titletoolserver/model";
import {defineComponent, getCurrentInstance, nextTick, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {AssetApi, AssetItemApi, FieldApi, SystemApi} from "../../../../target/api";
import {useStore} from "vuex";
import {uuid} from "vue-uuid";

export default defineComponent({
  props: [
    "modelValue",
    "currentPage",
    "transitionDir",
    "assetName",
    "selectedAudioTrack",
    "approver",
    "isApprover",
    "group",
    "translator",
    "translatorEmail",
    "expirationDate",
    "subtitleOption",
    "languages"
  ],
  components: {},

  setup: function (props, context) {
    const assetApi = new AssetApi();
    const systemApi = new SystemApi();
    const assetItemApi = new AssetItemApi();
    const fieldApi = new FieldApi();

    const route = useRoute();
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let valid = true;
    let currentAssetName = ref("");
    let currentApprover = ref("");
    let currentGroup = ref("");
    let currentTranslator = ref("");
    let currentExpirationDate: any = ref(null);
    let selectedAudioTrack = ref(null);
    let currentItemId = ref("");

    let newJobUniqueId = ref<string>();

    let isApprover = ref<boolean>(false);

    let voiceOverTracks = ref<Array<Field>>([]);

    let submitted = ref(false);

    let languages = ref<{ name: string; value: string; autoTranslate: boolean; autoTranslateAvailable: boolean }[]>([]);

    let selectedLanguages = ref<{
      name: string;
      value: string;
      autoTranslate: boolean;
      autoTranslateAvailable: boolean
    }[]>([]);

    let selectedLanguage = ref<null | {
      name: string;
      value: string;
      autoTranslate: boolean;
      autoTranslateAvailable: boolean
    }>();
    let autoTranslatableLanguages = ref<Array<String>>();

    let subtitleOptions = ref<Array<string>>();
    let selectedSubtitleOption = ref<SubtitleOptions>();

    let submit = async () => {
      submitted.value = true;
      if (!!currentAssetName.value
        && !!currentGroup.value
        && !!currentTranslator.value
        && !!currentExpirationDate.value
        && selectedLanguages.value!.length >= 1) {
        if (isApprover.value && !currentApprover.value) {
          return;
        }

        context.emit("update:assetName", currentAssetName.value);

        if (currentApprover.value) {
          context.emit("update:approver", currentApprover.value);
          context.emit("update:isApprover", isApprover.value);
        }
        context.emit("update:group", currentGroup.value);
        context.emit("update:selectedAudioTrack", selectedAudioTrack.value);
        context.emit("update:languages", selectedLanguages.value);
        context.emit("update:translator", currentTranslator.value);
        context.emit("update:expirationDate", currentExpirationDate.value);
        context.emit("update:subtitleOption", selectedSubtitleOption.value);

        context.emit("update:transitionDir", "slide-right");
        context.emit("update:currentPage", props.currentPage + 1);

        // if (voiceOverTracks.value.length > 0) {
        //   let assetItem = (await assetItemApi.getAssetItemById(currentItemId.value)).data;
        //   assetItem.selectedAudioTrack = currentVoiceOverTrack.value.id;
        //   assetItemApi.updateAssetItem(assetItem);
        // }
      }
    };

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return " pi-check-circle col-complete";
      if (page > currentPage) return " pi-circle-on col-menu-color";
      if (page == currentPage) return " pi-circle-on ";
    };

    let getRoles = (rolesString: Array<String>): Array<UserRole> => {
      let roles: Array<UserRole> = [];
      for (const key of rolesString) {
        roles.push(UserRole[key as keyof typeof UserRole]);
      }
      return roles;
    };

    let toggleAutoTranslate = (index: number) => {
      selectedLanguages.value[index].autoTranslate = !selectedLanguages.value[index].autoTranslate;
    }

    watch(currentTranslator, (newVal: any, oldVal) => {
      context.emit("update:translator", newVal);

      if (newVal && newVal.defaultLanguage) {
        let language = languages.value.find((l) => l.value === newVal.defaultLanguage);
        if (language && !selectedLanguages.value.includes(language)) {
          selectedLanguages.value.push(language!);
        }
      }
    });

    watch(selectedLanguage, (newVal: any) => {
      nextTick(() => {
        if (newVal && newVal.name && selectedLanguages.value.length >= 5) {
          store.commit('SHOW_SNACKBAR', {
            snackbarType: 'error',
            snackbarContent: {t: 'snackbar.languageLimit', e: [5]}
          });
        } else if (newVal && newVal.name && !selectedLanguages.value.includes(newVal)) {
          selectedLanguages.value.push(newVal);
        }
      }).then(() => {
        selectedLanguage.value = null;
      });
    });

    let checkApprover = () => {
      setTimeout(() => {
        if (!currentApprover.value) {
          isApprover.value = false;
        }
      }, 200);
    }

    let focusApprover = () => {
      let approverInputElement: any = document.getElementById("approverInput");
      approverInputElement.focus();
    }

    onMounted(async () => {
      console.debug("AddJobViewDetails - onMount");

      let getAssetByIdResponse: any = await assetApi.getAssetById("" + route.query.id);
      let item: Asset = getAssetByIdResponse.data;
      let tracks = (await fieldApi.getAssetItemAudioTracksByAssetItemId(item.masterAssetItem!.id!)).data;

      for (const track of tracks) {
        if (track.fieldType == FieldType.VOICEOVER) {
          let item = {
            name: track.srcName,
            id: track.id,
          };

          voiceOverTracks.value.push(item);
        }
      }

      systemApi.getSystemPreference("autoTranslatableLanguages").then(response => {
        autoTranslatableLanguages.value = (<Array<String>>response.data.value);
      })

      newJobUniqueId.value = uuid.v1();

      subtitleOptions.value = [];
      for (const subtitleOption in SubtitleOptions) {
        subtitleOptions.value.push(subtitleOption);
      }

      if (props.languages) {
        selectedLanguages.value = props.languages;
      } else {
        selectedLanguages.value = [];
      }

      if (props.assetName) {
        currentAssetName.value = props.assetName;
      } else if (item.name) {
        currentAssetName.value = item.name;
      }

      if (props.approver) {
        currentApprover.value = props.approver;
      } else if (item.approverId) {
        currentApprover.value = item.approverId;
      }

      if (props.isApprover) {
        isApprover.value = props.isApprover;
      }

      if (props.selectedAudioTrack) {
        selectedAudioTrack.value = props.selectedAudioTrack;
      }

      if (props.subtitleOption) {
        selectedSubtitleOption.value = props.subtitleOption;
      }

      if (item.id) {
        currentItemId.value = item.id;
      }

      if (props.translator) {
        currentTranslator.value = props.translator;
      }

      if (props.languages) {
        selectedLanguages.value = props.languages;
      }

      if (props.group) {
        currentGroup.value = props.group;
      } else if (item.groupID) {
        currentGroup.value = item.groupID;
      }

      if (props.expirationDate) {
        currentExpirationDate.value = props.expirationDate;
      } else {
        let newExpirationDate = new Date();
        newExpirationDate.setDate(newExpirationDate.getDate() + 14);
        currentExpirationDate.value = newExpirationDate.toISOString().split("T")[0];
      }
    });

    return {
      submit,
      submitted,
      currentAssetName,
      currentExpirationDate,
      isApprover,
      currentApprover,
      currentTranslator,
      currentGroup,
      voiceOverTracks,
      selectedAudioTrack,
      getCircleClass,
      getRoles,
      checkApprover,
      focusApprover,
      toggleAutoTranslate,
      valid,

      selectedLanguages,
      selectedLanguage,
      autoTranslatableLanguages,

      languages,
      subtitleOptions,
      selectedSubtitleOption,

      newJobUniqueId
    };
  },
});

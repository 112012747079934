<template>
  <div style="display: flex; flex-direction: column; width: 100%; height: 100%">
    <div style="display: flex">
      <div style="width: 60%">
        <span>
            <Button class="admin-control-reports-button"
                    :class="activeSheetTab === 0 ? 'admin-control-reports-button-enabled' : ''"
                    :label="windowWidth >= 1414 ? $t('reports.assetsImported') : $t('reports.assetsImported').slice(0, 5) + '...'"
                    v-tooltip="windowWidth < 1414 ? $t('reports.assetsImported') : ''"
                    @click="activeSheetTab = 0"/>
            <Button class="admin-control-reports-button"
                    :class="activeSheetTab === 1 ? 'admin-control-reports-button-enabled' : ''"
                    :label="windowWidth >= 1414 ? $t('reports.jobsCreated') : $t('reports.jobsCreated').slice(0, 5) + '...'"
                    v-tooltip="windowWidth < 1414 ? $t('reports.jobsCreated') : ''"
                    @click="activeSheetTab = 1"/>
            <Button class="admin-control-reports-button"
                    :class="activeSheetTab === 2 ? 'admin-control-reports-button-enabled' : ''"
                    :label="windowWidth >= 1414 ? $t('reports.jobsCompleted') : $t('reports.jobsCompleted').slice(0, 5) + '...'"
                    v-tooltip="windowWidth < 1414 ? $t('reports.jobsCompleted') : ''"
                    @click="activeSheetTab = 2"/>
            <Button class="admin-control-reports-button"
                    :class="activeSheetTab === 3 ? 'admin-control-reports-button-enabled' : ''"
                    :label="windowWidth >= 1414 ? $t('reports.allUsers') : $t('reports.allUsers').slice(0, 5) + '...'"
                    v-tooltip="windowWidth < 1414 ? $t('reports.allUsers') : ''"
                    @click="activeSheetTab = 3"/>
        </span>
      </div>
      <div style="display: flex; gap: 5px">
        <CustomDropdown
          v-model:modelValue="selectedPreset"
          show-clear="true"
          :options="presets"
          :label="'Duration'"
          :placeholder="'Duration'"
          class="grid-item"
          style="display: flex; width: calc(10vw - 1px)">
        
        </CustomDropdown>
        <CustomDatePicker
          v-model="startDate"
          :label="$t('general.startDate')"
          class="grid-item"
          style="display: flex; width: calc(10vw - 1px)"
        />
        
        <CustomDatePicker
          v-model="endDate"
          :label="$t('general.endDate')"
          class="grid-item"
          style="display: flex; width: calc(10vw - 1px)"
        />
        
        <RoundedLabel style="height: 50px; width: calc(10vw - 1px); border-radius: 8px; cursor: pointer"
                      class="bg-dark-blue col-white"
                      @click="downloadExcel()"
                      :text="$t('general.downloadExcel')"
        />
      </div>
      
      <!--   click on download excel -> switches can now be toggled in dialog   -->
      <div
        v-if="false"
        style="position: relative; display: flex; float: right; width: 70%; height: auto; align-items: center; align-content: center; gap: 20px">
        <Switch v-model="assetsImported" rightLabel="Assets Imported"/>
        <Switch v-model="jobsCreated" rightLabel="Jobs Created"/>
        <Switch v-model="jobsCompleted" rightLabel="Jobs Completed"/>
        <Switch v-model="activeUsers" rightLabel="Active Users"/>
        <Switch v-model="allUsers" rightLabel="All Users"/>
      </div>
    </div>
    
    <div v-if="activeSheetTab === 0" style="padding-top: 5px; height: 100%; width: 100%">
      <DataTable :value="assets" stripedRows scrollable scrollHeight="flex">
        <Column field="id" header="Id" style="overflow: hidden"></Column>
        <Column field="name" header="Name" style="overflow: hidden"></Column>
        <Column header="Created" style="overflow: hidden" class="flex-center">
          <template #body="slotProps">
            {{getDateString(slotProps.data.created)}}
          </template>
        </Column>
        <Column field="jobCount" header="Jobs" class="flex-center" style="overflow: hidden"></Column>
        <Column header="Language" class="flex-center" style="overflow: hidden">
          <template #body="slotProps">
            <Flag :countryFlag="slotProps.data.language"
                  v-tooltip="slotProps.data.language"
                  style="float: left; margin-right: 20px"/>
          </template>
        </Column>
        <Column field="group" header="Group" class="flex-center" style="overflow: hidden"></Column>
        <template #footer="slotProps">
          <div style="display: flex; gap: 50px" class="col-dark-blue">
            <div style="width: 300px">
              <div style="display: flex">
                <div style="display: flex; width: 80%" class="handle-font-overflow">{{
                    $t('reports.totalAssets')
                  }}:
                </div>
                {{assets.length}}
              </div>
              <div style="display: flex">
                <div style="width: 80%" class="handle-font-overflow">{{
                    $t('reports.assetsImportedPerWeek')
                  }}:
                </div>
                {{(assets.length / 52).toFixed(3)}}
              </div>
              <div style="display: flex">
                <div style="width: 80%" class="handle-font-overflow">{{
                    $t('reports.assetsImportedPerMonth')
                  }}:
                </div>
                {{(assets.length / 12).toFixed(3)}}
              </div>
            </div>
            
            <div style="width: 200px">
              <div class="handle-font-overflow">{{$t('reports.assetsByGroup')}}:</div>
              <div style="overflow: auto">
                <div v-for="group in groups" style="display: flex">
                  <div class="handle-font-overflow" style="width: 80%">{{group[0]}}
                  </div>
                  <div class="handle-font-overflow" style="width: 20%">{{group[1]}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </DataTable>
    </div>
    
    <div v-if="activeSheetTab === 1" style="padding-top: 5px; height: 100%; width: 100%">
      <DataTable :value="jobs" stripedRows responsiveLayout="scroll" scrollable scrollHeight="flex">
        <Column field="id" header="Id" style="overflow: hidden"></Column>
        <Column field="name" header="Name" style="overflow: hidden"></Column>
        <Column header="Created" class="flex-center" style="overflow: hidden">
          <template #body="slotProps">
            {{getDateString(slotProps.data.created)}}
          </template>
        </Column>
        <Column header="MasterLanguage" class="flex-center" style="overflow: hidden">
          <template #body="slotProps">
            <Flag :countryFlag="slotProps.data.masterLanguage"
                  v-tooltip="slotProps.data.masterLanguage"
                  style="float: left; margin-right: 20px"/>
          </template>
        </Column>
        <Column field="translator" header="Translator" class="flex-center" style="overflow: hidden"></Column>
        <Column field="mamId" header="MAM ID" class="flex-center" style="overflow: hidden">
          <template #body="slotProps">
            {{slotProps.data.mamId}}
          </template>
        </Column>
        <Column field="costCenter" header="CostCenter" class="flex-center" style="overflow: hidden"></Column>
        <Column header="Languages" class="flex-center" style="overflow: hidden">
          <template #body="slotProps">
            <Flag :countryFlag="slotProps.data.languages"
                  v-tooltip="slotProps.data.languages"
                  style="float: left; margin-right: 20px"/>
          </template>
        </Column>
        <Column header="Status" class="flex-center" style="overflow: hidden">
          <template #body="slotProps">
            <Tag :value="slotProps.data.status"
                 :severity="
                         slotProps.data.status === 'MOVIECREATED' ? 'warning' :
                         slotProps.data.status === 'READY_TO_APPROVE' ? 'danger' :
                         slotProps.data.status === 'CLOSED' ? 'success' : 'info'">
            
            </Tag>
          </template>
        </Column>
        
        <template #footer="slotProps">
          <div class="col-dark-blue">
            <div style="display: flex; width: 300px">
              <div style="display: flex; width: 80%" class="handle-font-overflow">{{$t('reports.totalJobs')}}:</div>
              {{jobs.length}}
            </div>
            <div style="display: flex; width: 300px" class="handle-font-overflow">
              <div style="width: 80%">{{$t('reports.jobsCreatedPerWeek')}}:</div>
              {{(jobs.length / 52).toFixed(3)}}
            </div>
            <div style="display: flex; width: 300px" class="handle-font-overflow">
              <div style="width: 80%">{{$t('reports.jobsCreatedPerMonth')}}:</div>
              {{(jobs.length / 12).toFixed(3)}}
            </div>
          </div>
        </template>
      </DataTable>
    </div>
    
    <div v-if="activeSheetTab === 2" style="padding-top: 5px; height: 100%; width: 100%">
      <DataTable :value="jobsComplete" stripedRows responsiveLayout="scroll" scrollable scrollHeight="flex">
        <Column field="id" header="Id" style="min-width: 250px; overflow: hidden"></Column>
        <Column field="name" header="Name" style="overflow: hidden"></Column>
        <Column field="user" header="User" class="flex-center" style="overflow: hidden"></Column>
        <Column field="group" header="Group" class="flex-center" style="overflow: hidden"></Column>
        <Column field="costCenter" header="CostCenter" class="flex-center" style="overflow: hidden"></Column>
        <Column header="Language" class="flex-center" style="overflow: hidden">
          <template #body="slotProps">
            <Flag :countryFlag="slotProps.data.language"
                  v-tooltip="slotProps.data.language"
                  style="float: left; margin-right: 20px"/>
          </template>
        </Column>
        <Column field="date" header="Completed" class="flex-center" style="overflow: hidden"></Column>
        <template #footer="slotProps">
          <div class="col-dark-blue">
            <div style="display: flex; width: 300px">
              <div style="width: 80%" class="handle-font-overflow">{{$t('reports.totalJobs')}}:</div>
              {{jobsComplete.length}}
            </div>
            <div style="display: flex; width: 300px" class="handle-font-overflow">
              <div style="width: 80%">{{$t('reports.jobsCompletedPerWeek')}}:</div>
              {{(jobsComplete.length / 52).toFixed(2)}}
            </div>
            <div style="display: flex; width: 300px" class="handle-font-overflow">
              <div style="width: 80%">{{$t('reports.jobsCompletedPerMonth')}}:</div>
              {{(jobsComplete.length / 12).toFixed(2)}}
            </div>
          </div>
        </template>
      </DataTable>
    </div>
    
    <div v-if="activeSheetTab === 3" style="padding-top: 5px; height: 100%; width: 100%">
      <DataTable :value="users" stripedRows responsiveLayout="scroll" scrollable scrollHeight="flex">
        <Column field="id" header="Id" class="flex-center" style="overflow: hidden"></Column>
        <Column field="firstName" header="First Name" class="flex-center" style="overflow: hidden"></Column>
        <Column field="name" header="Name" class="flex-center" style="overflow: hidden"></Column>
        <Column header="E-Mail" class="flex-center" style="overflow: hidden">
          <template #body="slotProps">
            {{slotProps.data.email}}
          </template>
        </Column>
        <Column header="Country" class="flex-center" style="max-width: 100px; overflow: hidden">
          <template #body="slotProps">
            <Flag :countryFlag="slotProps.data.country"
                  v-tooltip="slotProps.data.country"
                  style="float: left; margin-right: 20px"/>
          </template>
        </Column>
        <Column field="costCenter" header="CostCenter" class="flex-center" style="overflow: hidden"></Column>
        <Column header="Role" class="flex-center" style="max-width: 150px; overflow: hidden">
          <template #body="slotProps">
            <Tag :value="slotProps.data.role"
                 :severity="
                         slotProps.data.role === 'MANAGER' ? 'warning' :
                         slotProps.data.role === 'ADMIN' ? 'danger' :
                         slotProps.data.role === 'TRANSLATOR' ? 'success' : 'info'">
            </Tag>
          </template>
        </Column>
        <Column field="groups" header="Groups" class="flex-center" style="overflow: hidden"></Column>
        <template #footer="slotProps">
          <div class="col-dark-blue">
            <!--		implement stats for users			-->
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script src="./AdminControlViewReports.ts"></script>

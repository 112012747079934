<template>
  <div>
    <div style="display: flex; position: relative; padding-left: 24px">
      <LargeAssetItem v-if="headerItem" :item="headerItem" :index="23" :hideProcess="true" :hideControls="true"
                      style="width: 450px"/>
      
      <div
        class="col-menu-color bg-white border-radius-16"
        style="width: 100%; min-width: 350px; height: calc(100vh - 104px); min-height: 500px; box-shadow: 20px 20px 50px; margin: 0 24px 0 24px"
      >
        <div
          style="display:flex; width:100%; height:100%; flex-direction:column; justify-content:space-between; align-items:center;"
        >
          <!-- HEADLINE -->
          
          <div
            class="col-dark-blue"
            style="height:100px; display:flex; justify-content:center; align-items:center; font-size:24px; font-weight:600;"
          >
            {{$t('editAsset.editAssetDetails')}}
          </div>
          
          <!-- CONTENT -->
          
          <div class="grid-holder" style="height: 70%">
            <CustomInputText
              v-model:model-value="currentAssetName"
              :label="$t('general.assetName')"
              class="grid-item m-5 bor-dark-blue-60"
            />
            <CustomInputText
              v-model:model-value="currentThirdPartyID"
              :label="$t('general.id')"
              class="grid-item m-5 bor-dark-blue-60"
            />
            <AutoCompleteGroup
              v-model:modelValue="currentGroup"
              class="grid-item m-5"
            />
            <AutoCompleteLanguage
              v-model:model-value="currentLanguage"
              class="grid-item m-5"
            />
            <div v-for="metadata in customerMetadata">
              <CustomerMetadataInput :metadata="metadata"
                                     class="grid-item m-5"/>
            </div>
            <div class="grid-item m-5">
              <Switch
                :rightLabel="getUpdateJobText()"
                v-model="updateJobs"
                style="height: 50px; float: left"
              />
            </div>
            
            <div class="grid-item m-5 flex-center transitions rounded-label-hover"
                 style="cursor: pointer"
                 @click="showFieldDialog = !showFieldDialog">
              {{$t('editAsset.toggleFields')}}
            </div>
            
            <pDialog v-model:visible="showFieldDialog" :header="$t('editAsset.toggleFields')" style="width: 70%">
              <div v-for="field in fields" style="width: 100%; display: flex" class="m-5">
                
                <Switch v-model="field.enabled"></Switch>
                
                <div class="bor-menu-color border-radius-8 p-5" style="width: 100%;">
                  <div>
                    {{field.srcValue}}
                  </div>
                </div>
              </div>
            </pDialog>
          </div>
          
          <!-- FOOTER -->
          
          <div
            style="width: 90%; height: 80px; display:flex; align-items:center; justify-content:space-between;"
          >
            <Button
              @click="
              $router.push('/assets');
            "
              :label="$t('general.cancel')"
              class="border-radius-8 col-dark-blue bor-transparent bg-white"
              style="min-width:120px; height:40px; padding:7px 6px 6px 8px;"
            />
            <div style="display:flex; align-items:center; justify-content:flex-end;">
              <!-- <Button
                @click="
                  $router.push('/assets');
                "
                label="Send to MAM"
                class="border-radius-8 col-dark-blue bor-dark-blue bg-white"
                style="height:40px; padding:7px 16px 6px 18px;"
              /> -->
              <Button
                @click="
                updateAsset();
                $router.push('/assets');
              "
                :label="$t('general.save')"
                class="border-radius-8 col-white bor-transparent bg-dark-blue"
                style="min-width:120px; height:40px; padding:7px 16px 6px 18px; margin-left:24px;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./EditAssetView.ts"></script>

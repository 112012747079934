import {AssetItemApi, AssetApi, FieldApi, SystemApi} from "../../../target/api";
import {
  Asset,
  Field,
  AssetItem,
  AssetType,
  CustomerMetadata,
  RenderAssetItemObject,
} from "../../../target/api/de/moovit/titletoolserver/model";
import videojs, {VideoJsPlayer} from "video.js";
import {
  defineComponent,
  getCurrentInstance, nextTick,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

export default defineComponent({
  components: {},
  props: ["currentPage", "transitionDir", "propsValues"],
  setup: function (props: any, context) {
    const router = useRouter();
    const route = useRoute();
    const i18n: any = useI18n();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();
    const systemApi: SystemApi = new SystemApi();
    const store = useStore();

    let currentVideoWidth: any = ref(undefined);
    let asset = ref<Asset>();
    let assetItem = ref<AssetItem>();
    let hasAssetItemFields = ref<boolean>(false);

    let fieldVisualizerHeight = ref<number>(0);

    let player: any = ref<VideoJsPlayer>();
    let videoPlayerRef: any = ref(null);
    let videoOptions: any = ref();

    let clickedFinish = ref<boolean>();

    let getVideoURL = (asset: Asset) => {
      if (asset && asset.masterAssetItem) {
        return (
          process.env.VUE_APP_TT_ENDPOINT +
          "/videostream?access_token=" +
          JSON.parse(localStorage.loginData).access_token +
          "&id=" +
          asset.masterAssetItem.id
        );
      } else {
        return null;
      }
    };

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return " pi-check-circle";
      if (page > currentPage) return " pi-circle-on col-menu-color";
      if (page == currentPage) return " pi-circle-on ";
    };

    let _hasPermission = (permString: string) => {
      if (localStorage.loginData)
        return JSON.parse(localStorage.loginData).user.permissions.permissions[
          permString
          ];
    };

    let onClickCancel = async () => {
      if (asset.value && asset.value.id) {
        assetApi
          .cancelAssetUpload(asset.value.id)
          .then((response) => {
            resetView();
          })
          .catch((err) => {
            console.debug(err);
          });
      } else {
        resetView();
      }
    };
    let resetView = () => {
      context.emit("update:currentFile", null);
      asset.value = undefined;
      context.emit("update:currentPage", 1);
      context.emit("update:currentFile", undefined);
      context.emit("update:currentLanguage", undefined);
      context.emit("update:assets", []);
      context.emit("update:propsValues", {});
      asset.value = undefined;
      hasAssetItemFields.value = false;

      emitter.emit("set-data-loaded", false);
      router.push({
        query: {},
      });
    };

    let onClickFinish = async () => {
      clickedFinish.value = true;

      emitter.emit("set-data-loaded", false);

      if (props.propsValues && props.propsValues.customerMetadata) {
        let customerMetadata = props.propsValues.customerMetadata;

        assetItemApi.getAssetItemById("" + route.query.id).then(assetItem => {
          if (assetItem.data.id) {
            _getCustomerMetadataIdsAndSaveMetadata(assetItem.data.id, customerMetadata).then(result => {
              assetItem.data.customerMetadata = result;
              assetItemApi.updateAssetItemValues(assetItem.data);
            })
          }
        });
      }

      if (asset.value == null) {
        asset.value = {};
      }

      asset.value.assetType = AssetType.MASTER;
      let updateAsset = <Asset>{
        id: asset.value.id,
        assetType: AssetType.MASTER,
      };
      assetApi
        .updateAsset({asset: updateAsset, updateJobs: false})
        .then((assetUpdateResult) => {
          if (assetUpdateResult.data.id) {
            assetApi
              .resetFieldValues(assetUpdateResult.data.id)
              .then((resetFieldResult) => {
                if (assetUpdateResult.data.masterAssetItem) {
                  let renderAssetItemObject = <RenderAssetItemObject>{
                    high: true,
                    assetItemId: assetUpdateResult.data.masterAssetItem.id,
                    skipEmailsAndUploads: true,
                  };
                  assetItemApi.renderAssetItem(true, renderAssetItemObject);
                }

                if (_hasPermission("assets_view/visibility")) {
                  router.push("/assets");
                } else {
                  router.push("/addAsset");
                  setTimeout(() => {
                    history.go(0);
                  }, 100);
                }


                store.commit('SHOW_SNACKBAR', {
                  snackbarContent: {t: 'snackbar.assetAdded'},
                });

                let tAssets = {
                  t: "activityBreadcrumbs.assets",
                  e: undefined,
                };
                store.commit("SET_ACTIVITY_BREADCRUMBS", [tAssets]);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    let _getCustomerMetadataIdsAndSaveMetadata = async (
      assetItemId: string,
      customerMetadata: Array<CustomerMetadata>
    ): Promise<Array<string>> => {
      let metadataIds: string[] = [];

      for (let metadata of customerMetadata) {
        metadataIds.push(metadata.id!);
        metadata.assetItemId = assetItemId;

        await systemApi.addCustomerMetadata(metadata);
      }
      return metadataIds;
    };

    let resize = () => {
      let addAssetPreviewVideoContainer: any = document.getElementById(
        "addAssetPreviewVideoContainer"
      );

      console.debug("resize", addAssetPreviewVideoContainer);

      if (addAssetPreviewVideoContainer) {
        let maxWidth = document.documentElement.clientWidth - 250;
        let maxHeight = addAssetPreviewVideoContainer.clientHeight;
        let quotient = maxWidth / 16 / (maxHeight / 9);

        if (quotient > 1) {
          currentVideoWidth.value = maxHeight * (16 / 9);
        } else {
          currentVideoWidth.value = document.documentElement.clientWidth - 250;
        }
      }
    };

    let setFieldVisualizerHeight = () => {
      let fieldVisualizer = document.getElementById("field-visualizer-container");

      if (fieldVisualizer) {
        fieldVisualizerHeight.value = Number(fieldVisualizer.offsetHeight);
      }
    }

    onMounted(async () => {
      videoOptions.value = {
        controls: true,
        autoplay: true,
        sources: [],
      };

      player.value = videojs(videoPlayerRef.value, videoOptions.value);

      assetApi.getAssetById("" + route.query.id).then(assetById => {
        if (assetById.data) {
          asset.value = assetById.data;
          assetItemApi.getAssetItemById(asset.value.masterAssetItem!.id!).then(result => {
            if (result.data) {
              nextTick(() => {
                assetItem.value = result.data;
                hasAssetItemFields.value = result.data.fields!.length != 0;
              }).then(() => {
                setFieldVisualizerHeight();
              });
            }
          });

          if (asset.value) {
            const url = getVideoURL(asset.value);
            player.value.src({
              type: "video/mp4",
              src: url,
            });
          }

          window.addEventListener("resize", function () {
            resize();
          });
          resize();
        }
      });

    });

    onUnmounted(() => {
      window.removeEventListener("resize", function () {
        resize();
      });
    });

    return {
      getCircleClass,
      setFieldVisualizerHeight,
      currentVideoWidth,
      player,
      asset,
      assetItem,
      fieldVisualizerHeight,
      hasAssetItemFields,
      videoPlayerRef,
      videoOptions,
      onClickFinish,
      clickedFinish,
      onClickCancel,
    };
  },
});

<template>
  <div v-if="visible" style="width: 90%; cursor: pointer">
    <div class="container">
      <div class="user-notification-item">
        <div style="width: 100%">
          <div class="first-line">
            <div style="width: 300px; gap: 20px" class="flex-center">
              <div style="width: 48px; height: 48px" @click="markAsRead(true); jumpToSource()">
                <Avatar class="avatar" size="large" :icon="getIcon()"/>
              </div>
              <div>
                {{notification.message}}
              </div>
            </div>
            <div style="width: 20px; height: 20px; border-radius: 15px; transition: all 0.2s"
                 :class="notification.read ? 'bg-menu-color' : 'bg-complete'"/>
          </div>
          <div class="second-line flex-center" style="justify-content: space-between">
            <div style="width: 100px">
              {{timeSince(Date.parse(notification.dateTime))}} ago
            </div>
            •
            <div class="flex-center" style="width: 150px; text-align: center">
              {{
                (notification.category.replace(new RegExp('_', 'g'), " ").split(' ').map(item =>
                  item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' '))
              }}
            </div>
            •
            <div class="hover-mark-as-read flex-center border-radius-16"
                 style="width: 130px; gap: 5px; padding: 2px; margin-right: 5px" @click="markAsRead()">
              <div class="border-radius-16 bg-complete flex-center col-white"
                   style="width: 18px; height: 18px; opacity: 0.3">
                <i class="pi pi-check" style="font-size: 11px"/>
              </div>
              {{
                'mark as ' + (notification.read ? 'un' : '') + 'read'
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.user-notification-item {
  transition: all 0.2s;
  height: 60px;
  gap: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.container {
  height: 120px;
  display: flex;
  border-bottom: var(--background) solid 1px;
  transition: all 0.2s;
  position: relative;
}

.delete-button {
  margin-right: 5px;
  transition: all 100ms
}

.delete-button:hover {
  box-shadow: 0 0 5px -2px black;
  font-size: 17px;
  border-radius: 42px;
}

.avatar {
  transition: all 0.2s;
}

.avatar:hover {
  transition: all 0.2s;
  background-color: var(--tab-link);
}

.first-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  color: var(--dark-blue);
  transition: all 0.2s;
}

.second-line {
  display: flex;
  position: absolute;
  bottom: 15px;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  font-weight: 200;
  font-size: 14px;
  color: var(--dark-blue);
}

.hover-mark-as-read {
  transition: all var(--transition-speed);
}

.hover-mark-as-read:hover {
  transition: all var(--transition-speed);
  box-shadow: 0 0 2px 1px var(--menu-color);
}
</style>

<script src="./UserNotificationItem.ts"></script>

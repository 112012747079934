<template>
  <MultiSelect v-model="innerModelValue" :placeholder="$t(placeholder ? placeholder : '')" :options="languages"
               optionLabel="name" :showToggleAll="false"
               @change="onSelectLanguage($event)" :filter="true" class="grid-item" style="width: 260px"
               :class="!submitted || (innerModelValue.length > 0) ? '' : 'bor-reject'">
    <template #value="slotProps">
      <div style="display: flex">
        <div v-for="option of slotProps.value" :key="option.code"
             style="display: flex; align-items: center; gap: 5px">
          <RoundedLabel :countryFlag="option.value" :noHover="true"
                        class="bg-white-60" style="width: 35px"/>
          <div v-if="slotProps.value.length === 1" style="max-width: 50px">
            {{option.name}}
          </div>
        </div>
        
        <div v-if="slotProps.value.length === 0">
          {{slotProps.placeholder}}
        </div>
      </div>
    </template>
    <template #option="slotProps">
      <div style="width: 300px; display: flex; align-items: center; position: relative;">
        <div style="width: 70%; display: flex; align-items: center; position: relative;">
          <RoundedLabel :countryFlag="slotProps.option.value" :noHover="true"
                        class="bg-white-60" style="cursor: pointer; border: 1px solid transparent"/>
          <div style="margin-left: 10px; max-width: 150px; overflow: hidden;">{{slotProps.option.name}}</div>
        </div>
        
        <Switch v-if="slotProps.option.autoTranslateAvailable"
                v-model:modelValue="slotProps.option.autoTranslate"
                style="margin-right: 10px; position: absolute; right: 0" :tooltip="'general.autoTranslate'"/>
      </div>
    </template>
  </MultiSelect>
</template>

<script src="./MultiSelectLanguage.ts"></script>

<template>
  <div
    class="col-menu-color bg-white border-radius-16"
    style="
      width: 100%;
      height: calc(100vh - 130px);
      box-shadow: 4px 10px 30px;
      margin: 0 30px;
      border-color: transparent;
    "
  >
    <div
      class="bg-white"
      style="
        margin-left: -15px;
        margin-top: 80px;
        position: absolute;
        height: 30px;
        width: 30px;
        transform: rotate(45deg);
      "
    />
    
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        class="col-dark-blue"
        style="
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 600;
        "
      >
        {{$t("addJob.addJobDetails")}}
      </div>
      
      <div
        style="
          width: 100%;
          height: calc(100vh - 320px);
          overflow: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="grid-holder" style="height: 100%; padding-top: 50px">
          <CustomInputText
            :label="$t('general.newAssetName')"
            v-model="currentAssetName"
            :modelValue="currentAssetName"
            class="grid-item m-5 border-radius-12 bor-dark-blue-60"
          />
          
          <AutoCompleteUser
            v-model="currentTranslator"
            :label="$t('general.translator')"
            :userRoles="[]"
            class="grid-item m-5"
            :class="!submitted || currentTranslator ? '' : 'bor-reject'"
          />
          
          <AutoCompleteGroup
            v-model="currentGroup"
            class="grid-item m-5"
            :disabled="true"
            :class="!submitted || currentGroup ? '' : 'bor-reject'"
          />
          
          <div
            :style="!isApprover ? 'display: flex; align-items: center' : ''"
            class="grid-item m-5"
          >
            <Switch
              v-model="isApprover"
              v-if="!isApprover"
              @click="focusApprover()"
              :rightLabel="$t('addJob.enableApproval')"
            />
            
            <AutoCompleteUser
              v-else
              customId="approverInput m-5"
              v-model="currentApprover"
              :label="$t('general.approver')"
              :userRoles="getRoles(['ADMIN', 'MANAGER'])"
              :invalid="!(!submitted || !isApprover || currentApprover)"
              class="grid-item"
              :customBlurFunction="
                () => {
                  checkApprover();
                }
              "
            />
          </div>
          
          <CustomDatePicker
            v-model="currentExpirationDate"
            label="Expiration Date"
            class="grid-item m-5"
            :class="!submitted || currentExpirationDate ? '' : 'bor-reject'"
          />
          
          <CustomDropdown
            v-if="voiceOverTracks.length !== 0"
            v-model="selectedAudioTrack"
            :options="voiceOverTracks"
            :placeholder="$t('general.voiceOver')"
            optionLabel="name"
            class="grid-item m-5"
          />
          
          <CustomDropdown
            v-if="voiceOverTracks && voiceOverTracks.length !== 0"
            :options="subtitleOptions"
            v-model="selectedSubtitleOption"
            :label="$t('general.subtitleOption')"
            :placeholder="$t('general.subtitleOption')"
            class="grid-item m-5"
          />
          
          <div>
            <AutoCompleteLanguage
              v-model:modelValue="selectedLanguage"
              :selected-languages="selectedLanguages"
              :placeholder="'addJob.chooseLanguages'"
              style="width: 280px"
              class="m-5"
              :class="
                !submitted || selectedLanguages.length !== 0 ? '' : 'bor-reject'
              "
            />
            
            <div
              style="display: flex; flex-direction: column; position: relative; height: 250px; width: 100%"
            >
              <div style="font-size: 12px; padding-left: 10px">
                {{"You can add up to five langauges per job."}}
              </div>
              <div
                v-for="(language, index) in selectedLanguages"
                :key="index"
                class="bor-dark-blue-30 border-radius-24 col-dark-blue"
                style="margin: 5px; padding: 3px; display: flex; align-items: center; justify-content: space-between;"
              >
                <div
                  style="display: flex; align-items: center; justify-content: space-between; margin-left: 10px"
                >
                  <Flag
                    :countryFlag="language.name"
                    style="float: left; font-size: 10px; margin-right: 10px"
                  />
                  {{language.name.split(" ")[0]}}
                  {{language.name.split(" ")[1]}}
                </div>
                
                <div
                  style="display: flex; align-items: center; margin-right: 10px; gap: 5px"
                >
                  <RoundedLabel
                    v-if="autoTranslatableLanguages ? autoTranslatableLanguages.includes(language.name)  : false"
                    :text="$t('addJob.autoTranslation')"
                    left-icon="pi pi-language"
                    v-tooltip="
                      language.autoTranslate
                        ? $t('addJob.disableAutoTranslation')
                        : $t('addJob.enableAutoTranslation')
                    "
                    class="col-white"
                    :class="
                      language.autoTranslate
                        ? 'bg-dark-blue'
                        : 'bg-dark-blue-40'
                    "
                    style="height: 25px; font-size: 8px"
                    @click="toggleAutoTranslate(index)"
                  />
                  <i
                    class="pi pi-times bor-dark-blue border-radius-16"
                    style="font-size: 9px; padding: 4px; margin-right: -5px; cursor: pointer"
                    @click="
                      selectedLanguages.splice(
                        selectedLanguages.indexOf(language, 0),
                        1
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FOOTER -->
      
      <div
        style="
          width: 90%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <Button
          @click="
            $router.push({
              name: 'assets',
              query: {},
            })
          "
          :label="$t('general.cancel')"
          class="border-radius-8 col-dark-blue bor-transparent bg-white"
          style="width: 120px; height: 40px; padding: 7px 6px 6px 8px"
        />
        
        <div>
          <div
            style="
              width: 100px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <i
              :class="'col-dark-blue pi ' + getCircleClass(1, currentPage)"
            ></i>
            <i
              :class="'col-dark-blue pi ' + getCircleClass(2, currentPage)"
            ></i>
          </div>
          <div style="width: 100px; margin-top: 10px; display: flex">
            <div class="col-dark-blue" style="margin: auto">
              {{currentPage}} / 2
            </div>
          </div>
        </div>
        <div
          style="
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          <Button
            v-if="currentPage < 3"
            @click="submit()"
            :label="$t('general.next')"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="min-width: 120px; height: 40px; padding: 7px 6px 6px 8px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./AddJobViewJobDetails.ts"></script>

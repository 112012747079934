import {defineComponent, nextTick, onMounted, onUnmounted, ref, watch} from 'vue';

export default defineComponent({
  props: [
    'modelValue',
    'label',
    'options',
    'optionLabel',
    'placeholder',
    'invalid',
    'disabled',
    'showClear',
    'selectedLanguages',
    'filter',
    'includesFlag',
    'dropdownHeight',
    'textOverFlowWidth'
  ],
  components: {},
  setup: function (props, context) {
    let innerModelValue: any = ref<any>();
    let innerOptions: any = ref<any[]>([]);

    let isDropdownOpen = ref<boolean>(false);
    let isModelValueInOptions = ref<boolean>(false);

    let filterText = ref<string>("");

    let onChangeFilterValue = () => {
      innerOptions.value = props.options;

      innerOptions.value = innerOptions.value.filter((option: any) =>
        (props.optionLabel ? option[props.optionLabel] : option).toLowerCase()
          .includes(filterText.value.toLowerCase()));
    }

    let onClickOption = (option: any) => {
      innerModelValue.value = option;
      filterText.value = "";
      innerOptions.value = props.options;
      context.emit('update:modelValue', option);
    }

    let isIncluded = (option: any): boolean => {
      return props.selectedLanguages.filter((language: any) => language.value === option).length >= 1;
    }

    let onClickOpenDropdown = (event: any) => {
      if (props.disabled) {
        return;
      }

      if (event.target.nodeName !== "I") {
        nextTick(() => {
          isDropdownOpen.value = !isDropdownOpen.value;
        }).then(() => {
          if (props.filter) {
            let filterInputText = document.getElementById("filterInputText");
            if (filterInputText) {
              filterInputText.focus();
            }
          }
        });
      }
    }

    let onClickClear = () => {
      isDropdownOpen.value = false;
      innerModelValue.value = undefined;
      filterText.value = "";
      context.emit('update:modelValue', innerModelValue.value);
    }

    let onMouseClick = (event: MouseEvent) => {
      if (!isDropdownOpen.value) return;

      setTimeout(() => {
        isDropdownOpen.value = false;
      }, 150);
    }

    watch(props, () => {
      innerModelValue.value = props.modelValue;
      innerOptions.value = props.options;

      let tempValue = innerModelValue.value ? props.optionLabel ? innerModelValue.value[props.optionLabel] : innerModelValue.value : "";

      if (props.optionLabel) {
        isModelValueInOptions.value = innerOptions.value.filter((options: any) => options[props.optionLabel] === tempValue).length > 0;
      } else {
        isModelValueInOptions.value = innerOptions.value.includes(tempValue);
      }
    });

    onMounted(() => {
      if (props.options) {
        innerOptions.value = props.options;
      }

      window.addEventListener('mousedown', (event) => onMouseClick(event));
    });


    onUnmounted(() => {
      window.removeEventListener('mousedown', (event) => onMouseClick(event));
    });

    return {
      innerModelValue,
      innerOptions,
      isDropdownOpen,
      isModelValueInOptions,
      filterText,
      onChangeFilterValue,
      onClickOption,
      onClickClear,
      onClickOpenDropdown,
      isIncluded
    };
  },
});

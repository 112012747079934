<template>
  <div style="display:flex; position:relative; padding-left:24px;">
    <LargeAssetItem v-if="headerItem" :item="headerItem" :hideProcess="true" :hideControls="true"/>
    <div
      class="col-menu-color bg-white border-radius-16"
      style="width:100%; min-width:350px; height: calc(100vh - 114px); min-height:500px; box-shadow:20px 20px 50px; margin:0 24px 0 24px; border-color:transparent"
    >
      <div
        style="display:flex; width:100%; height:100%; flex-direction:column; align-items:center;"
      >
        <!-- HEADLINE -->
        
        <div
          class="col-dark-blue"
          style="height:100px; display:flex; justify-content:center; align-items:center; font-size:24px; font-weight:600;"
        >
          {{$t('general.assetInformation')}}
        </div>
        
        <!-- CONTENT -->
        
        <div
          class="bor-dark-blue border-radius-8 bg-white-blue"
          style="
          width: 40%;
          height: calc(100vh - 322px);
          overflow: auto;
          display: flex;
          flex-direction: column;
        "
        >
          <div class="bg-white" style="padding:12px 0px 0px 12px;">
            <div style="font-size: 14px; font-weight: 400; line-height: 24px;">
              {{$t('general.projectType')}}
            </div>
            <div
              class="col-dark-blue"
              style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <img v-if="masterAssetItem.projectType === 'AFX'" src="@/assets/aeft_icon.png"/>
              <img
                v-if="masterAssetItem.projectType === 'PREMIERE'"
                src="@/assets/premiere_icon.png"
              />
              <img v-if="masterAssetItem.projectType === 'FUSION'" src="@/assets/fusion_icon.png"/>
            </div>
          </div>
          <div
            v-for="(item, index) in assetInfos"
            :key="index"
            class="bg-white"
            style="padding:12px 0px 0px 12px;"
          >
            <div style="font-size: 14px; font-weight: 400; line-height: 24px;">
              {{item.key}}
            </div>
            <div
              class="col-dark-blue"
              style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{item.value}}
            </div>
          </div>
          <div class="bg-white" style="min-height:24px;"></div>
          <div
            class="col-dark-blue bg-white-blue"
            style="padding-top:24px; padding:12px 0px 12px 12px; font-size:17px; font-style:italic; border-top:1px dashed lightgray;"
          >
            {{$t('general.customerMetadata')}}
          </div>
          <div
            v-for="(item, index) in assetCustomInfos"
            :key="index"
            class="bg-white-blue"
            style="padding:12px 0 0 12px"
          >
            <div style="font-size: 14px; font-weight: 400; line-height: 18px">
              {{item.name}}
            </div>
            
            <div
              v-if="item.type === 'STRING'"
              class="col-dark-blue"
              style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{item.typeValueString}}
            </div>
            
            <div
              v-if="item.type === 'BOOLEAN'"
              class="col-dark-blue"
              style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{item.typeValueBoolean}}
            </div>
            
            <div
              v-if="item.type === 'INTEGER'"
              class="col-dark-blue"
              style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{item.typeValueInteger}}
            </div>
            
            <div
              v-if="item.type === 'DOUBLE'"
              class="col-dark-blue"
              style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{item.typeValueDouble}}
            </div>
          </div>
        </div>
        
        <!-- FOOTER -->
        
        <div
          style="width: 90%; height: 80px; display:flex; align-items:center; justify-content:space-between;"
        >
          <Button
            @click="
              $router.push('/assets');
            "
            :label="$t('general.back')"
            class="border-radius-8 col-dark-blue bor-transparent bg-white"
            style="min-width:120px; height:40px; padding:7px 6px 6px 8px;"
          />
          <div style="display:flex; align-items:center; justify-content:flex-end;">
            <!-- <Button
              @click="
                $router.push('/assets');
              "
              label="Send to MAM"
              class="border-radius-8 col-dark-blue bor-dark-blue bg-white"
              style="height:40px; padding:7px 16px 6px 18px;"
            /> -->
            <!-- <Button
              @click="
                updateAsset();
                $router.push('/assets');
              "
              :label="$t('general.save')"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="height:40px; padding:7px 16px 6px 18px; margin-left:24px;"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./AssetInfoView.ts"></script>

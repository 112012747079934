import {defineComponent, getCurrentInstance, onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import Draggable from 'vue3-draggable';
import {AssetApi, AssetItemApi, FieldApi, SystemApi} from '../../../target/api';
import {
  Asset,
  AssetItem,
  AssetType, CustomerMetadata,
  Field,
  MediaReplacementTemplate, RenderAssetItemObject
} from '../../../target/api/de/moovit/titletoolserver/model';
import {useStore} from "vuex";

export default defineComponent({
  props: ['currentPage', 'transitionDir', 'propsValues'],
  components: {
    Draggable,
  },
  setup: function (props, context) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const assetApi = new AssetApi();
    const systemApi = new SystemApi();
    const fieldApi: FieldApi = new FieldApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();

    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let selectedLanguage = ref(undefined);

    let isAssetVideo = ref<boolean>(true);

    interface DragItemsList {
      draggable: boolean,
      average: number,
      fields: Array<Field>,
    }

    let draggableLists = ref<Array<DragItemsList>>([]);

    let replaceableMedia = ref<Array<MediaReplacementTemplate>>([]);

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return ' pi-check-circle';
      if (page > currentPage) return ' pi-circle-on col-menu-color';
      if (page == currentPage) return ' pi-circle-on ';
    };

    let items = ref<Array<Field>>([]);

    let duplicateAverage = ref<Array<number>>([]);

    let submitChanges = async () => {
      let newOrder = [];
      for (const item of draggableLists.value) {
        for (const field of item.fields) {
          newOrder.push(field);
        }
      }

      items.value = newOrder;

      for (const field of items.value) {
        if (!field.enabled) {
          fieldApi.deleteField(field.id!);
        } else {
          fieldApi.updateField(field.id!, field);
        }
      }
      console.debug('AddAsset - Checkmark - Submit Changes', JSON.parse(JSON.stringify(items.value)));
    };

    let onClickFinish = () => {
      submitChanges();
      finishUpload();
    }

    let finishUpload = async () => {
      console.debug('finish from checkmarks');

      emitter.emit('set-data-loaded', false);

      if (props.propsValues && props.propsValues.customerMetadata) {
        let customerMetadata = props.propsValues.customerMetadata;

        let assetItem: AssetItem = (await assetItemApi.getAssetItemById("" + route.query.id)).data;

        assetItem.customerMetadata = await _getCustomerMetadataIdsAndSaveMetadata(assetItem.id!, customerMetadata);

        assetItemApi.updateAssetItemValues(assetItem);
      }

      let getAssetResponse = await assetApi.getAssetById('' + route.query.id);
      let asset = getAssetResponse.data;

      if (asset == null) {
        asset = {};
      }

      asset.assetType = AssetType.MASTER;
      let updateAsset = <Asset>{
        id: asset.id,
        assetType: AssetType.MASTER,
      };
      await assetApi.updateAsset({asset: updateAsset, updateJobs: false});
      if (asset.id) {
        await assetApi.resetFieldValues(asset.id);
      }
      if (asset.masterAssetItem) {
        let renderAssetItemObject = <RenderAssetItemObject>{
          high: true,
          assetItemId: asset.masterAssetItem.id,
          skipEmailsAndUploads: true,
        };
        assetItemApi.renderAssetItem(true, renderAssetItemObject);
      }

      if (_hasPermission("assets_view/visibility")) {
        router.push('/assets');
      } else {
        router.push('/addAsset');
        setTimeout(() => {
          history.go(0);
        }, 100);
      }

      let tAssets = {
        t: 'activityBreadcrumbs.assets',
        e: undefined,
      };
      store.commit('SET_ACTIVITY_BREADCRUMBS', [tAssets]);
    };

    let _getCustomerMetadataIdsAndSaveMetadata = async (assetItemId: string, customerMetadata: Array<CustomerMetadata>): Promise<Array<string>> => {
      let metadataIds: string[] = [];

      for (let metadata of customerMetadata) {
        metadataIds.push(metadata.id!);
        metadata.assetItemId = assetItemId;

        await systemApi.addCustomerMetadata(metadata);
      }
      return metadataIds;
    }

    let _hasPermission = (permString: string) => {
      if (localStorage.loginData)
        return JSON.parse(localStorage.loginData).user.permissions.permissions[permString];
    };

    let getAverage = (item: any) => {
      return (item.inPoint + item.outPoint) / 2;
    }

    onMounted(async () => {
      let getAssetResponse: any = await assetApi.getAssetById(
        // 'aea8ab03-d28b-44b6-a07f-5fb31a3bd697'
        '' + route.query.id
      );

      console.debug(
        'AddAssetViewCheckmark - ASSET',
        JSON.parse(JSON.stringify(getAssetResponse.data))
      );

      isAssetVideo.value = getAssetResponse.data.masterAssetItem.needsVerification;

      let assetItemFieldsResponse = await fieldApi.getAssetItemFieldsByAssetItemId(
        getAssetResponse.data.masterAssetItem.id
      );

      replaceableMedia.value = (await systemApi.checkMediaReplaceableNames(getAssetResponse.data.masterAssetItem.id)).data;

      items.value = assetItemFieldsResponse.data;

      let lastAverage = 0;
      let tempDragItemList: DragItemsList = {draggable: false, fields: [], average: 0};

      items.value.forEach((item: any) => {
        if (item.fieldType == 'CONTINUOUS') item.isChecked = true;

        let itemAverage = (item.inPoint + item.outPoint) / 2;

        if (lastAverage == 0) {
          tempDragItemList = {
            draggable: false,
            average: itemAverage,
            fields: [item]
          }
        } else {
          if (lastAverage === itemAverage) {
            tempDragItemList.fields.push(item);
            tempDragItemList.draggable = true;
          } else {
            draggableLists.value.push(Object.assign({}, tempDragItemList));

            tempDragItemList = {
              draggable: false,
              average: itemAverage,
              fields: [item]
            };
          }
        }

        lastAverage = itemAverage;

        /**
         *
         * [
         *  {
         *    true,
         *    [
         *      {field1},
         *      {field2}
         *    ]
         *  },
         *  {
         *    false,
         *    [
         *      {field3},
         *      {field4}
         *    ]
         *  },
         *  {
         *    true,
         *    [
         *      {field5},
         *      {field6},
         *      {field7}
         *    ]
         *  }
         * ]
         *
         */
      });
      if (tempDragItemList && tempDragItemList.average != 0) {
        draggableLists.value.push(Object.assign({}, tempDragItemList));
      }

      console.debug(draggableLists.value);
      console.debug('AddAssetViewCheckmark - MASTERASSETITEM - FIELDS', assetItemFieldsResponse.data);
    });

    return {
      items,
      getCircleClass,
      selectedLanguage,
      submitChanges,
      duplicateAverage,
      getAverage,
      draggableLists,
      replaceableMedia,
      onClickFinish,
      isAssetVideo
    };
  },
});

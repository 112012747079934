<template>
  <div
    class="col-dark-blue-30 bg-white border-radius-16"
    style="
      width: 95%;
      height: calc(100vh - 100px);
      box-shadow: 0 0 10px 0;
      border-color: transparent;
    "
  >
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        class="col-dark-blue"
        style="
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 600;
        "
      >
        {{$t("uploader.addNewAsset")}}
      </div>
      
      <div
        v-if="currentPage === 1"
        style="
          width: 100%;
          height: calc(100vh - 300px);
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <div
          class="col-dark-blue"
          style="
            min-height: 60px;
            font-size: 16px;
            font-weight: 300;
            text-align: center;
          "
        >
          {{$t("addAsset.needToCollectFiles")}}
        </div>
        <div
          v-if="!currentFile"
          class="border-radius-24 bg-light-blue bor-dark-blue col-dark-blue"
          style="
            width: 30%;
            height: 220px;
            border-style: dashed;
            border-width: 3px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div/>
          <div style="text-align: center">
            {{$t("addAsset.dragDropZipFileOrBrowse")}}
          </div>
          <Button
            @click="fileInput.click()"
            :label="$t('general.browseFiles')"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="width: 100px; height: 40px"
          />
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept=".zip,.mp4"
            @change="onFilePicked"
          />
          <div/>
        </div>
        <div
          v-if="currentFile"
          class="border-radius-24 bg-menu-color bor-dark-blue col-dark-blue"
          style="
            width: 30%;
            height: 220px;
            border-style: dashed;
            border-width: 3px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div/>
          <i class="pi pi-file-o col-white" style="font-size: 56px"></i>
          <div
            style="
              text-overflow: ellipsis;
              width: 98%;
              text-align: center;
              overflow: hidden;
            "
          >
            {{currentFile.name}}
          </div>
          <div style="display: flex; align-items: center">
            <Button
              @click="fileInput.click()"
              :label="$t('general.browseFiles')"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="
                width: 100px;
                height: 40px;
                padding: 7px 6px 6px 8px;
                margin-right: 24px;
              "
            />
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              accept=".zip,.mp4"
              @change="onFilePicked"
            />
            <Button
              @click="
                submit()
                //$emit('update:transitionDir', 'slide-right');
                //$emit('update:currentPage', currentPage + 1);
              "
              label="Upload"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="width: 100px; height: 40px; padding: 7px 6px 6px 8px"
            />
          </div>
          
          <div/>
        </div>
      </div>
      
      <div
        v-if="currentPage === 2"
        style="
          width: 100%;
          height: calc(100vh - 300px);
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div style="display: flex; flex-direction: column">
          <div>
            <div
              class="border-radius-24 bg-complete-light col-complete"
              style="
                border: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 12px;
              "
            >
              <div
                v-if="currentFile"
                class="col-dark-blue"
                style="margin-bottom: 12px; overflow: ellipsis"
              >
                {{currentFile.name}}
              </div>
              <div style="display: flex">
                <div style="position: relative; width: 220px; height: 220px">
                  <VueCircle
                    style="
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      width: 220px;
                      height: 220px;
                    "
                    v-bind:class="{ spin: currentStatus3 === 'UNKNOWN' }"
                    v-model:percent="currentProgress3"
                    :show-percent="false"
                    empty-color="transparent"
                    fill-color="#47DF84"
                    :border-width="8"
                  />
                  <VueCircle
                    style="
                      position: absolute;
                      top: 12.5px;
                      left: 12.5px;
                      width: 195px;
                      height: 195px;
                    "
                    v-bind:class="{ spin: currentStatus2 === 'UNKNOWN' }"
                    v-model:percent="currentProgress2"
                    :show-percent="false"
                    empty-color="transparent"
                    fill-color="#00E8333D"
                    :border-width="8"
                  />
                  <VueCircle
                    style="
                      position: absolute;
                      top: 24px;
                      left: 24px;
                      width: 172px;
                      height: 172px;
                      background: transparent;
                    "
                    v-bind:class="{ spin: currentStatus1 === 'UNKNOWN' }"
                    v-model:percent="currentProgress1"
                    :show-percent="false"
                    empty-color="transparent"
                    fill-color="#FFE1DA"
                    :border-width="8"
                  />
                  <VueCircle
                    style="
                      position: absolute;
                      top: 35px;
                      left: 35px;
                      width: 150px;
                      height: 150px;
                      background: transparent;
                    "
                    v-bind:class="{ spin: currentStatus0 === 'UNKNOWN' }"
                    v-model:percent="currentProgress0"
                    :show-percent="false"
                    empty-color="transparent"
                    fill-color="#FFBDAE"
                    :border-width="8"
                  />
                  
                  <div style="position: absolute; top: 80px; width: 220px">
                    <div
                      v-if="!allStagesDone && !error"
                      style="
                        color: #ffbdae;
                        font-size: large;
                        text-align: center;
                      "
                    >
                      {{message}}
                    </div>
                    <div
                      v-if="!allStagesDone && !error"
                      style="
                        color: #ffbdae;
                        font-size: xx-large;
                        text-align: center;
                      "
                    >
                      {{currentProgress}}%
                    </div>
                    <div
                      v-if="allStagesDone"
                      style="
                        color: #47df84;
                        font-size: x-large;
                        text-align: center;
                        margin-top: 15px;
                      "
                    >
                      Completed
                    </div>
                    <div
                      v-if="error"
                      style="
                        color: #e31d1c;
                        font-size: x-large;
                        text-align: center;
                        margin-top: 15px;
                      "
                    >
                      {{message}}
                    </div>
                    <!-- <div style="color:#4C47DF">Render: {{ currentProgress }} %</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-dark-blue"
            style="
              min-height: 60px;
              font-size: 16px;
              font-weight: 300;
              text-align: center;
            "
          >
            {{completedMessage}}
          </div>
        </div>
        
        <div
          v-if="!isMobile"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
          "
        >
          <div
            class="col-dark-blue"
            style="
              min-height: 60px;
              font-size: 16px;
              font-weight: 300;
              text-align: center;
            "
          >
            {{$t("uploader.fillAllNeededInformation")}}
          </div>
          <div>
            <div
              style="
                display: block;
                width: 548px;
                height: 200px;
                margin-top: 16px;
              "
            >
              <CustomInputText
                label="ID"
                v-model="currentId"
                class="
                  border-radius-8
                  col-dark-blue
                  bor-menu-color
                  bg-transparent
                "
                :disabled="true"
                style="width: 228px; height: 50px; float: left; font-size: 16px"
              />
              <CustomInputText
                label="Project Name"
                v-model="currentProjectName"
                class="
                  border-radius-8
                  col-dark-blue
                  bor-menu-color
                  bg-transparent
                "
                style="
                  width: 228px;
                  height: 50px;
                  float: left;
                  font-size: 16px;
                  margin-left: 24px;
                "
              />
              <AutoCompleteLanguage
                v-model="currentLanguage"
                style="
                  width: 228px;
                  height: 50px;
                  float: left;
                  margin-top: 24px;
                "
                class="
                  border-radius-8
                  col-dark-blue
                  bor-menu-color
                  bg-transparent
                "
              />
              <CustomInputText
                label="Group"
                v-model="currentGroup"
                style="
                  width: 228px;
                  height: 50px;
                  float: left;
                  margin-left: 24px;
                  margin-top: 24px;
                "
                :disabled="true"
                class="
                  border-radius-8
                  col-dark-blue
                  bor-menu-color
                  bg-transparent
                "
              />
            </div>
          </div>
        </div>
      </div>
      
      <!-- FOOTER -->
      
      <div
        style="
          width: 90%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between"
      >
        <div>
          <div style="width: 64px"/>
        </div>
        <div
          style="
            width: 300px;
            display: flex;
            align-items: center;
            justify-content: space-between"
        >
          <i :class="'col-dark-blue pi ' + getCircleClass(1, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(2, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(3, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(4, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(5, currentPage)"></i>
        </div>
        <div>
          <Button
            v-if="allStagesDone"
            @click="onClickNext"
            :disabled="!currentId || !currentProjectName || (currentLanguage && currentLanguage.name === '') || !currentGroup"
            label="Next"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="width: 64px; height: 40px; padding: 7px 6px 6px 8px"
          />
          <div v-else style="width: 64px"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./UploaderSetup.ts"></script>

<template>
  <div
    class="col-menu-color bg-white border-radius-16"
    style="
      width: 100%;
      box-shadow: 20px 20px 50px;
      margin-right: 24px;
      border-color: transparent;
    "
  >
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        class="col-dark-blue"
        style="
          height: 100px;
          margin-left: -55px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 600;
        "
      >
        {{$t("addAsset.uploadReady")}}
      </div>
      
      <div
        style="
          width: 100%;
          height: calc(100vh - 220px);
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div
          class="col-dark-blue border-radius-24 bg-complete-light border-transparent"
          style="
            width: 30vw;
            min-height: 60px;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
          margin-left: -55px;
          "
        >
          <div></div>
          <div>
            {{currentFile ? currentFile.name : ""}}
          </div>
          <i
            class="pi pi-check"
            style="font-size: 24px; font-weight: 900; margin-right: 20px"
          ></i>
        </div>
        
        <div
          class="col-dark-blue"
          style="
            min-height: 60px;
            margin-left: -55px;
            font-size: 16px;
            font-weight: 300;
            text-align: center;
          "
        >
          {{$t("addAsset.helpAssetManagerIdentifyAsset")}}
        </div>
        
        <div>
          <div
            class="grid-holder m-5"
            style="height: 450px"
          >
            <CustomInputText
              :label="$t('general.id')"
              v-model="currentId"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent grid-item m-5"
            />
            <CustomInputText
              :label="$t('general.projectName')"
              v-model="currentProjectName"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent grid-item m-5"
              :class="!submitted || currentProjectName ? '' : 'bor-reject'"
            />
            <AutoCompleteLanguage
              v-model="currentLanguage"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent grid-item m-5"
              :class="!submitted || (currentLanguage && currentLanguage.value) ? '' : 'bor-reject'"
            />
            <AutoCompleteGroup
              v-model="currentGroup"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent grid-item m-5"
              :class="!submitted || currentGroup ? '' : 'bor-reject'"
              dropdownHeight="190"
              :fSelected="() => onChangeGroup()"
            />
            
            
            <!--						<AddCustomerMetadataDialog style="margin-top: 24px; width: 228px; height: 50px"-->
            <!--						                           :assetItemId="currentId"-->
            <!--						                           :customerMetadata="customerMetadata"/>-->
            
            <div v-for="metadata in customerMetadata">
              <CustomerMetadataInput :metadata="metadata"
                                     :checkForRequired="submitted"
                                     class="grid-item m-5"/>
            </div>
          
          </div>
        </div>
        <!-- FOOTER -->
        
        <div
          style="
            width: 90%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <Button
              @click="onClickCancel"
              :label="$t('general.cancel')"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="width: 64px; height: 40px; padding: 7px 6px 6px 8px"
            />
            <div>
              <!-- <Button
              @click="
                $emit('update:transitionDir', 'slide-left');
                $emit('update:currentPage', currentPage-1);
              "
              :label="$t('general.back')"
              class="border-radius-8 col-dark-blue bor-transparent bg-white"
              style="min-width:120px; height:40px; padding:7px 6px 6px 8px;"
            /> -->
            </div>
          </div>
          
          <div
            style="
              width: 300px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <i :class="'col-dark-blue pi ' + getCircleClass(1, currentPage)"></i>
            <i :class="'col-dark-blue pi ' + getCircleClass(2, currentPage)"></i>
            <i :class="'col-dark-blue pi ' + getCircleClass(3, currentPage)"></i>
            <i :class="'col-dark-blue pi ' + getCircleClass(4, currentPage)"></i>
            <i :class="'col-dark-blue pi ' + getCircleClass(5, currentPage)"></i>
          </div>
          <div
            style="
              width: 120px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <Button
              @click="submitChanges()"
              :label="$t('general.next')"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="min-width: 120px; height: 40px; padding: 7px 6px 6px 8px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./AddAssetViewDetails.ts"></script>

import {Asset, AssetItem, AssetItemAllOf, BasicAssetItem} from "../../../target/api/de/moovit/titletoolserver/model";
import {FieldApi, AssetItemApi, AssetApi} from "../../../target/api";

import {defineComponent, getCurrentInstance, nextTick, onMounted, onUnmounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import videojs, {VideoJsPlayerOptions} from "video.js";
import Player = videojs.Player;
import {AxiosResponse} from "axios";

export default defineComponent({
  components: {},
  setup: function () {
    const route = useRoute();
    const store = useStore();
    const i18n: any = useI18n();

    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();
    const fieldApi = new FieldApi();

    let show = ref(false);
    let isFullscreen = ref(false);

    let asset = ref<Asset>({});
    let assetItems = ref<Array<AssetItem>>([]);
    let currentAssetItemIndex = ref<number>(0);

    let currentVideoUrl = ref<string>("");

    //Player
    let playerHeader = ref<Player>();
    let playerVideoControlsHeader = ref<any>({});
    let playerVideoOptionsHeader = ref<VideoJsPlayerOptions>();

    let playerDialog = ref<Player>();
    let playerVideoControlsDialog = ref<any>({});
    let playerVideoOptionsDialog = ref<VideoJsPlayerOptions>();

    let isMasterVideoMaximized = ref<boolean>(false);

    let isJobMasterAssetItemVerifyJob = ref<boolean>(false);

    let getVideoURL = (assetItem: AssetItem) => {
      //return "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4"
      if (assetItem) {
        const url = process.env.VUE_APP_TT_ENDPOINT + "/videostream?access_token=" + JSON.parse(localStorage.loginData).access_token + "&id=" + assetItem.id + "&ts=" + assetItem.lastModified;
        currentVideoUrl.value = url;
        return url;
      } else {
        return null;
      }
    };

    let downloadMaster = () => {
      if (asset.value.masterAssetItem) {
        const url = getVideoURL(asset.value.masterAssetItem);
        if (url) {
          _download(url);
        }
      }
    };

    let _download = (url: string) => {
      let downloadElement = document.createElement("a");
      downloadElement.href = url + "&dl=true";
      // downloadElement.download = item.value.name + '.mp4';
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
    };

    let onClickMasterVideo = () => {
      isMasterVideoMaximized.value = !isMasterVideoMaximized.value;

      if (isMasterVideoMaximized.value) {
        setTimeout(() => {
          playerVideoOptionsDialog.value = {controls: true};
          playerVideoOptionsDialog.value.sources = [
            {
              src: getVideoURL(asset.value.masterAssetItem!)!,
              type: "video/mp4",
            },
          ];

          playerDialog.value = videojs(playerVideoControlsDialog.value, playerVideoOptionsDialog.value);
          playerDialog.value.load();
        }, 100);
      }
    };

    let hasPermission = (permstring: string) => {
      if (localStorage.loginData) return JSON.parse(localStorage.loginData).user.permissions.permissions[permstring];
    };

    let _initJob = async () => {
      let getAssetByIdResponse = await assetApi.getAssetById("" + route.query.id);
      asset.value = getAssetByIdResponse.data;

      if (asset.value.id && asset.value.assetItems) {
        for (let assetItem of asset.value.assetItems) {
          if (assetItem.id) {
            assetItems.value.push((await assetItemApi.getAssetItemById(assetItem.id)).data);
          }
        }
      }
    }

    onMounted(async () => {
      await _initJob();

      let tJobs = {
        t: "activityBreadcrumbs.jobs",
        e: undefined,
      };

      let tEditJob = {
        t: "activityBreadcrumbs.editJob",
        e: [asset.value.name],
      };

      store.commit("SET_ACTIVITY_BREADCRUMBS", [tJobs, tEditJob]);
      nextTick(() => {
        show.value = true;
      }).then(() => {
        if (asset.value && asset.value.masterAssetItem) {
          if (asset.value.masterAssetItem.needsVerification) {
            assetItemApi.getAssetItemById(asset.value.id!).then((asset: AxiosResponse<AssetItemAllOf & BasicAssetItem>) => {
              isJobMasterAssetItemVerifyJob.value = true;
              assetItems.value.push(asset.data);
            });


            let tVerifyJob = {
              t: "activityBreadcrumbs.verifyJob",
              e: [asset.value.name],
            };

            store.commit("SET_ACTIVITY_BREADCRUMBS", [tJobs, tVerifyJob]);
          }

          playerVideoOptionsHeader.value = {controls: false};
          playerVideoOptionsHeader.value.sources = [
            {
              src: getVideoURL(asset.value.masterAssetItem!)!,
              type: "video/mp4",
            },
          ];

          playerHeader.value = videojs(playerVideoControlsHeader.value, playerVideoOptionsHeader.value);
          playerHeader.value.load();
        }
      });

      emitter.on('updateCurrentAssetItemIndex', (index: number) => {
        currentAssetItemIndex.value = index;
      });
    });

    onUnmounted(() => {
      emitter.off('updateCurrentAssetItemIndex');
    });

    return {
      asset,
      assetItems,
      currentAssetItemIndex,

      playerVideoControlsHeader,
      playerVideoOptionsHeader,

      playerVideoControlsDialog,
      playerVideoOptionsDialog,

      isMasterVideoMaximized,
      isJobMasterAssetItemVerifyJob,

      hasPermission,
      downloadMaster,
      onClickMasterVideo,

      show,
      isFullscreen
    };
  },
});

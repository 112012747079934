import {AssetApi} from '../../../../target/api';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import main from '../../../main';
import {useStore} from 'vuex';

export default defineComponent({
  components: {},
  setup: function (props, context) {
    const route = useRoute();
    const store = useStore();
    let assetApi = new AssetApi();

    let currentJob: any = ref({});
    let currentJobName = ref({});
    let currentApprover: any = ref(null);
    let currentTranslator: any = ref(undefined);
    let currentExpirationDate = ref(undefined);

    let headerItem: any = ref({});

    let updateJob = async () => {
      if (currentJobName.value) {
        currentJob.value.name = currentJobName.value;
      }
      if (currentApprover.value) {
        currentJob.value.approverId = currentApprover.value.id;
      } else {
        currentJob.value.approverId = "";
      }
      if (currentTranslator.value) {
        currentJob.value.translatorID = currentTranslator.value.id;
      }
      if (currentExpirationDate.value) {
        currentJob.value.tokenObject.expiration = currentExpirationDate.value;
      }
      console.debug('updateJob - ITEM', JSON.parse(JSON.stringify(currentJob.value)));
      await assetApi.updateAsset({asset: currentJob.value, updateJobs: true});
      main.config.globalProperties.$emitter.emit('search-jobs', null);
    };

    onMounted(async () => {
      let getAssetByIdResponse: any = await assetApi.getAssetById('' + route.query.id);
      let item: any = getAssetByIdResponse.data;
      console.debug('EditJob - ITEM', item);

      let tJobs = {
        t: 'activityBreadcrumbs.jobs',
        e: undefined,
      };
      let tEditJob = {
        t: 'activityBreadcrumbs.editJob',
        e: [item.name],
      };
      store.commit('SET_ACTIVITY_BREADCRUMBS', [tJobs, tEditJob]);

      currentJob.value = item;
      headerItem.value = item;

      currentJobName.value = item.name;

      currentApprover.value = item.approverId;
      if (item.approver && item.approver.id) {
        currentApprover.value = item.approver.id;
      }

      currentTranslator.value = item.translatorID;

      if (item.tokenObject && item.tokenObject.expiration) {
        currentExpirationDate.value = item.tokenObject.expiration;
      }
    });

    return {
      headerItem,
      currentExpirationDate,
      currentJobName,
      currentApprover,
      currentTranslator,
      updateJob,
    };
  },
});

<template>
  <div class="border-radius-16 col-dark-blue bor-menu-color" :class="disabled ? 'bg-light-blue' : 'bor-dark-blue'"
       style="display: flex; flex-direction: column; justify-content: space-evenly; padding: 10px; height: 100%; overflow: hidden">
    
    <div style="margin: 0 0 0 75px; cursor: pointer" @click="previousField()">
      <div
        class="bor-transparent col-dark-blue-30 bg-transparent"
        style="
            width: 99%;
            overflow: hidden;
            flex-grow: 1;
            font-size: calc(8px + 6 * ((70vw - 390px) / 680));
          "
      >
        {{firstText}}
      </div>
    </div>
    
    <div style="height: 1px; width: 95%; margin: 5px 20px" :class="disabled ? 'bg-dark-blue-30' : 'bg-light-blue'"/>
    
    <div style="display: flex; align-items: center; justify-content: center; height: 80%">
      <i class="pi pi-angle-right"/>
      <Textarea
        v-model="innerModelValue"
        id="currentTranslation"
        @scroll="onScroll"
        class="border-radius-8 bg-transparent col-dark-blue bor-transparent container"
        :value="innerModelValue"
        :disabled="disabled"
        style="
            width: 100%;
            height: 100%;
            flex-grow: 1;
            font-size: calc(8px + 6 * ((80vw - 390px) / 680));
          "
        @blur="saveCurrentFieldValuesEmit()"
      />
    </div>
    
    <div style="height: 1px; width: 95%; margin: 5px 20px" :class="disabled ? 'bg-dark-blue-30' : 'bg-light-blue'"/>
    
    <div style="margin: 0 0 0 75px; cursor: pointer"
         class="col-dark-blue-30" @click="nextField()">
      <div
        class="bor-transparent col-dark-blue-30 bg-transparent"
        style="
            width: 99%;
            overflow: hidden;
            flex-grow: 1;
            font-size: calc(8px + 6 * ((70vw - 390px) / 680));
          "
      >
        {{lastText}}
      </div>
    </div>
  </div>
</template>

<script src="./AdvancedTextArea.ts"></script>


<!--
<transition-group :name="transitionDir">

<div style="width:100%; height:100%; background-color: red">

<div v-if="innerFieldIndex > 0" >
  {{ innerTextArray[innerFieldIndex - 1] }}
</div>

</div>

<div>
<InputText
  v-model="innerTextArray[innerFieldIndex]"
  style="width:100%; height:100%; border: 0; font-size:16px; background-color: green"

/>
</div>

<div v-if="innerTextArray.length > innerFieldIndex" style="width:100%; height:100%; background-color: blue">

<div>
  {{ innerTextArray[innerFieldIndex + 1] }}
</div>
</div>
</transition-group>

-->
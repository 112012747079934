/// <reference types="dom-mediacapture-record" />
import {defineComponent, getCurrentInstance, onMounted, onUnmounted, ref, watch} from "vue";

import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/src/plugin/microphone";
import videojs, {VideoJsPlayerOptions} from "video.js";
import Player = videojs.Player;

// Idea for Master Wave for Fields
// https://wavesurfer-js.org/plugins/markers.html
// https://codepen.io/Sambego/pen/VmBJJN

export default defineComponent({
  components: {},
  props: ["videoUrl", "visible"],
  setup: function (props, context) {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let videoPlayer = ref<Player>();
    let playerVideoControls = ref<any>({});
    let playerVideoOptions = ref<VideoJsPlayerOptions>({});

    let wavesurferMic = ref<WaveSurfer>();
    let wavesurferWav = ref<WaveSurfer>();

    let mediaRecorder = ref<MediaRecorder>();
    let audioChunks = ref<Array<any>>([]);
    let audioBlob = ref<Blob>();

    let availableInputs = ref<Array<{ value: string; text: string }>>([]);
    let currentInputDevice = ref<{ value: string; text: string }>();

    let isActive = ref<boolean>(false);
    let isRecording = ref<boolean>(false);

    let currentTime = ref<number>(0.0);

    let playButtonIcon = ref<string>("pi-play");

    let toggleDialog = () => {
      console.debug("Dialog toggled");
      if (isActive.value) {
        isActive.value = false;

        if (wavesurferWav.value) {
          wavesurferWav.value.setMute(true);
        }
        if (wavesurferMic.value) {
          wavesurferMic.value.setMute(true);
        }
      } else {
        isActive.value = true;
        setTimeout(() => {
          _loadWaveSurfer();
          _loadVideo();

          if (wavesurferWav.value) {
            wavesurferWav.value.setMute(false);
          }
          if (wavesurferMic.value) {
            wavesurferMic.value.setMute(false);
          }
        }, 20);
      }
    };

    let _loadWaveSurfer = () => {
      console.debug("Loading wavesurfer");
      wavesurferMic.value = WaveSurfer.create({
        container: "#waveformMic",
        waveColor: "black",
        barMinHeight: 1,
        height: 80,
        barGap: 1,
        barWidth: 2,
        interact: false,
        cursorWidth: 0,
        responsive: true,
        plugins: [MicrophonePlugin.create({})],
      });

      wavesurferMic.value.microphone.on("deviceReady", function (stream: any) {
        console.debug("Device ready!", stream);

        mediaRecorder.value = new MediaRecorder(stream);
        mediaRecorder.value.start();
        console.debug("Start recording!");
        if (videoPlayer.value) {
          console.debug("Playing video!");
          videoPlayer.value!.play();
        }

        if (mediaRecorder.value) {
          mediaRecorder.value.addEventListener("dataavailable", (event) => {
            audioChunks.value.push(event.data);
          });

          mediaRecorder.value.addEventListener("stop", () => {
            audioBlob.value = new Blob(audioChunks.value);
            const audioUrl = URL.createObjectURL(audioBlob.value);

            const audio = new Audio(audioUrl);

            wavesurferMic.value!.load(audio);
          });
        }
      });

      wavesurferWav.value = WaveSurfer.create({
        container: "#waveformWav",
        waveColor: "black",
        barMinHeight: 1,
        height: 80,
        barGap: 1,
        barWidth: 2,
        interact: false,
        cursorWidth: 0,
        responsive: true,
      });

      wavesurferWav.value.load(props.videoUrl);
    };

    let _loadVideo = () => {
      playerVideoOptions.value = {};
      playerVideoOptions.value.sources = [{src: props.videoUrl, type: "video/mp4"}];
      playerVideoControls.value.muted = true;
      //errorDisplay

      videoPlayer.value = videojs(playerVideoControls.value, playerVideoOptions.value);
      // videoPlayer.value.load();
    };

    let startRecording = () => {
      playButtonIcon.value = "pi-play";
      if (wavesurferMic.value && !isRecording.value) {
        isRecording.value = true;

        audioChunks.value = [];

        wavesurferMic.value.microphone.start();
      }
    };

    let stopRecording = () => {
      playButtonIcon.value = "pi-play";
      if (videoPlayer.value && !videoPlayer.value.paused()) {
        videoPlayer.value.pause();
        playerVideoControls.value.currentTime = 0;
      }
      if (wavesurferMic.value) {
        wavesurferMic.value.microphone.stop();

        if (isRecording.value) {
          isRecording.value = false;

          if (mediaRecorder.value) {
            mediaRecorder.value.stop();
            console.debug("Stop recording");
          }
        }
      }
    };

    let cancelRecording = () => {
      if (wavesurferMic.value) {
        if (isRecording.value) {
          isRecording.value = false;
          wavesurferMic.value.microphone.stop();
        }

        if (mediaRecorder.value) {
          mediaRecorder.value.stop();
          audioChunks.value = [];
        }

        wavesurferMic.value.empty();

        if (videoPlayer.value) {
          if (!videoPlayer.value.paused()) {
            videoPlayer.value.pause();
          }
          playerVideoControls.value.currentTime = 0;
        }
      }
    };

    let playAudio = () => {
      if (!wavesurferMic.value || !videoPlayer.value) return;

      wavesurferMic.value.play();
      videoPlayer.value.play();
      playButtonIcon.value = "pi-pause";
    };

    let handleTimeupdate = () => {
      currentTime.value = playerVideoControls.value.currentTime;
    };

    let changeAudioDevice = () => {
      //TODO: change audio device
      console.debug(currentInputDevice.value);
      if (isRecording.value) {
        cancelRecording();
      }

      navigator.mediaDevices.getUserMedia({audio: {deviceId: currentInputDevice.value ? {exact: currentInputDevice.value!.value} : undefined}}).then((stream) => {
        console.debug("New AudioStream selected!", stream);
        //_loadWaveSurfer();
      });
    };
    let refreshAudioDevices = () => {
      availableInputs.value = [];
      const constraints = {
        video: false,
        audio: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          stream.getTracks().forEach((t) => {
            let option = {text: "", value: ""};
            option.value = stream.id;
            option.text = t.label || "Microphone " + (availableInputs.value.length + 1);
            availableInputs.value.push(option);
            console.debug("Got MediaStream:", stream);
          });
        })
        .catch((error) => {
          console.error("Error accessing media devices.", error);
        });
    };
    let onClickFinish = () => {
      if (audioChunks.value.length >= 1) {
        let date = new Date();
        let dateString = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + "-" + date.getHours() + "" + date.getMinutes() + "" + date.getSeconds();
        let file = new File([audioBlob.value!], "Recorded-" + dateString + ".webm", {type: "audio/webm"});
        emitter.emit("addAudioTrack", file);
      }
      toggleDialog();
    };

    watch(isActive, () => {
      if (isActive) {
        refreshAudioDevices();
      }
    });
    watch(currentInputDevice, () => {
      changeAudioDevice();
    });

    onMounted(() => {
      emitter.on("toggleCustomVoiceOverTrack", () => {
        toggleDialog();
      });
      //   refreshAudioDevices();

      //   navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      //     for (const deviceInfosKey in deviceInfos) {
      //       let deviceInfo = deviceInfos[deviceInfosKey];

      //       let option = { text: "", value: "" };

      //       if (deviceInfo.kind === "audioinput") {
      //         option.value = deviceInfo.deviceId;
      //         option.text = deviceInfo.label || "Microphone " + (availableInputs.value.length + 1);
      //         availableInputs.value.push(option);
      //       }
      //     }
      //   });
    });

    onUnmounted(() => {
      emitter.off("toggleCustomVoiceOverTrack");
      if (wavesurferMic.value) wavesurferMic.value.destroy();
      if (wavesurferWav.value) wavesurferWav.value.destroy();
    });
    return {
      playerVideoControls,

      toggleDialog,
      isActive,
      currentTime,

      startRecording,
      stopRecording,
      cancelRecording,
      onClickFinish,
      handleTimeupdate,
      changeAudioDevice,
      isRecording,
      availableInputs,
      currentInputDevice,
      playAudio,
      audioChunks,
      playButtonIcon,
    };
  },
});

<template>
  
  <RoundedLabel v-if="visible" :text="'Add Custom VoiceOver'" class="bg-dark-blue col-white"
                @click="toggleDialog()"/>
  
  <pDialog :header="'Audio Recording'" v-model:visible="isActive" :draggable="false" :maximizable="true"
           :modal="true">
    
    <div style="width: 70vw; height: 75vh; overflow: hidden">
      <div class="border-radius-16"
           style="display: flex; height: 74%; width: 100%; margin-bottom: 5px; align-items: flex-end; justify-content: flex-end; background-color: #000000">
        <video
          id="audioTrackVideo"
          ref="playerVideoControls"
          class="video-js vjs-default-skin"
          preload="metadata"
          style="
            pointer-events: none;
            background: transparent;
            height: 100%;
          "
          @timeupdate="handleTimeupdate()"
          @ended="stopRecording()"
        />
        <div class="bg-white border-radius-50" style="position: absolute; height: 40px; width: 100px; margin: 20px">
          <Switch rightLabel="CC" style="margin-top: 8px"/>
        </div>
      </div>
      <div style="display: flex; height: 25%">
        <div style="width: 85%; height: 100%">
          <div style="display: flex; width: 100%; height: 50%; align-items: center; justify-content: center">
            <div class="border-radius-16 bor-dark-blue-30"
                 style="display: flex; width: 99%; height: 95%; background-color: #DDE5E4FF; align-items: center; justify-content: center">
              <div
                style="position: absolute; font-family: 'Devanagari Sangam MN', serif; font-size: 50px; font-weight: bold; color: rgba(0,0,0,0.1)">
                MASTER
              </div>
              <div id="waveformWav" style="width: 100%; height: auto"/>
            </div>
          </div>
          <div style="display: flex; width: 100%; height: 50%; align-items: center; justify-content: center">
            <div class="border-radius-16 bor-dark-blue-30"
                 style="display: flex; width: 99%; height: 95%; background-color: #DDE5E4FF; align-items: center; justify-content: center">
              <div
                style="position: absolute; font-family: 'Devanagari Sangam MN', serif; font-size: 50px; font-weight: bold; color: rgba(0,0,0,0.1)">
                RECORD
              </div>
              <div id="waveformMic" style="width: 100%; height: auto"/>
            </div>
          </div>
        </div>
        <div style="width: 15%; height: 100%">
          <div
            style="display: flex; width: 100%; height: 15%; align-items: center; justify-content: center; font-family: 'Devanagari Sangam MN', serif">
            {{Math.trunc(currentTime / 1200)}}:{{Math.trunc(currentTime / 60)}}:{{
              Math.trunc(currentTime)
            }}.{{
              (((currentTime + "").split(".")[1]) ? ((currentTime + "").split(".")[1]).substring(2)[0] :
                '0')
            }}
          </div>
          <div style="display: flex; width: 100%; height: 20%;">
            <div style="display: flex; height: 85%; width: 25%; align-items: center; justify-content: center">
              <Button class="border-radius-24 bg-white bor-dark-blue"
                      style="height: 2rem; width: 2rem"
                      @click="startRecording()">
                <i class="pi pi-circle-on" style="margin-top: 1px; font-size: 18px; color: red"/>
              </Button>
            </div>
            <div style="display: flex; height: 85%; width: 25%; align-items: center; justify-content: center">
              <Button class="border-radius-24 bg-white bor-dark-blue"
                      style="display: flex; height: 2rem; width: 2rem; align-items: center; justify-content: center"
                      @click="stopRecording()">
                <div style="height: 15px; width: 15px; background-color: black"></div>
              </Button>
            </div>
            <div style="display: flex; height: 85%; width: 25%; align-items: center; justify-content: center">
              <Button class="border-radius-24 bg-white bor-dark-blue"
                      style="height: 2rem; width: 2rem"
                      @click="playAudio()">
                <i :class="'pi ' + playButtonIcon" style="margin-top: 2px; margin-left: 2px; font-size: 18px"/>
              </Button>
            </div>
            <div style="display: flex; height: 85%; width: 25%; align-items: center; justify-content: center">
              <Button class="border-radius-24 bg-white bor-dark-blue"
                      style="height: 2rem; width: 2rem"
                      @click="cancelRecording()">
                <i class="pi pi-trash" style="font-size: 18px"/>
              </Button>
            </div>
          </div>
          <div style="width: 100%; height: 65%">
            <div style="height: 10%"/>
            <div style="width: 100%; height: 35%">
              <CustomDropdown class="bor-dark-blue bg-white border-radius-50"
                              label="Available Devices"
                              placeholder="Available Devices"
                              optionLabel="text"
                              :options="availableInputs"
                              v-model="currentInputDevice"
                              style="height: 95%"
                              @change="changeAudioDevice"/>
            </div>
            <div style="height: 5%"/>
            <div style="width: 100%; height: 35%">
              <div class="bor-dark-blue bg-dark-blue-30 border-radius-50"
                   style="display:flex; height: 95%; width: 98%; cursor: pointer; justify-content: center; align-items: center"
                   @click="onClickFinish()">
                {{(audioChunks.length >= 1 ? 'Save & Exit' : 'Exit')}}
                <i class="pi pi-check"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="downloader" style="display: none"></div>
  </pDialog>
</template>

<script src="./VoiceOverRecorder.ts"></script>

import {defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
import {useStore} from "vuex";

import {
  AssetItemStatus,
  AssetType,
  SearchAssetItemsObject,
  SearchAssetsObject,
  SearchUsersObject
} from '../../../target/api/de/moovit/titletoolserver/model';

import {SystemApi, AssetApi, AssetItemApi, UserApi} from '../../../target/api';
import {useI18n} from "vue-i18n";

export default defineComponent({
  components: {},
  setup: function () {
    const store = useStore();
    const i18n = useI18n();

    const systemApi: SystemApi = new SystemApi();
    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();

    let activeSheetTab = ref<number>(0);
    let windowWidth = ref<number>(0);

    let startDate = ref();
    let endDate = ref();

    let selectedPreset = ref<string>("");
    let presets = ref(
      [i18n.t('general.day'), i18n.t('general.month'), i18n.t('general.year')]
    );

    let assetsImported = ref<boolean>(true);
    let jobsCreated = ref<boolean>(true);
    let jobsCompleted = ref<boolean>(true);
    let activeUsers = ref<boolean>(true);
    let allUsers = ref<boolean>(true);

    //Assets Imported
    let assets = ref<{
      name: string,
      id: string,
      created: string,
      jobCount: string,
      language: string,
      group: string
    }[]>([]);

    let groups = ref<Map<string, number>>(new Map());

    let assetsPerWeek = ref(0);
    let assetsPerMonth = ref(0);

    //Jobs Created
    let jobs = ref<{
      id: string,
      name: string,
      created: string,
      masterAsset: string,
      masterLanguage: string,
      translator: string,
      email: string,
      costCenter: string,
      mamId: string,
      languages: string,
      status: string
    }[]>([]);

    let jobsPerWeek = ref(0);
    let jobsPerMonth = ref(0);

    //Jobs Completed
    let jobsComplete = ref<{
      id: string,
      name: string,
      status: string,
      user: string,
      group: string,
      costCenter: string,
      language: string,
      email: string,
      date: string,
      duration: string,
      masterAsset: string
    }[]>([]);

    let jobsCompletePerWeek = ref(0);
    let jobsCompletePerMonth = ref(0);

    //Active Users
    let totalLogins = ref(0);
    let loginPerWeek = ref(0);
    let loginPerMonth = ref(0);

    //All Users
    let users = ref<{
      id: string,
      firstName: string,
      name: string,
      email: string,
      country: string,
      costCenter: string,
      role: string,
      locked: string,
      groups: string
    }[]>([]);

    let _initAssetsImported = async () => {
      let searchAssetsObject: SearchAssetsObject = {};
      searchAssetsObject.assetType = AssetType.MASTER;
      searchAssetsObject.ascSort = false;
      searchAssetsObject.includeDeleted = true;

      searchAssetsObject.groups = [];
      searchAssetsObject.languages = [];

      if (startDate.value) {
        searchAssetsObject.startDate = "" + new Date(startDate.value).getTime();
      }

      if (endDate.value) {
        searchAssetsObject.endDate = "" + new Date(endDate.value).getTime();
      }

      let searchAssets = (await assetApi.searchAssets(searchAssetsObject)).data;

      assets.value = [];
      for (const searchAsset of searchAssets) {
        if (searchAsset) {
          assets.value.push({
            name: searchAsset.name!,
            id: searchAsset.id!,
            created: "" + searchAsset.created,
            jobCount: "" + (searchAsset.assetItems ? searchAsset.assetItems.length : "0"),
            language: searchAsset.masterAssetItem!.language!,
            group: searchAsset.groupID!
          });
        }
      }

      groups.value = new Map<string, number>();
      for (const asset of assets.value) {
        if (groups.value.has(asset.group)) {
          let curr = groups.value.get(asset.group)!;
          groups.value.set(asset.group, curr + 1);
        } else {
          groups.value.set(asset.group, 1);
        }
      }
    }

    let _initJobsCreated = async () => {
      let searchAssetItemsObject: SearchAssetItemsObject = {};

      searchAssetItemsObject.groups = [];
      searchAssetItemsObject.languages = [];
      searchAssetItemsObject.translators = [];

      if (startDate.value) {
        searchAssetItemsObject.startDate = "" + new Date(startDate.value).getTime();
      }

      if (endDate.value) {
        searchAssetItemsObject.endDate = "" + new Date(endDate.value).getTime();
      }

      let searchReportAssetItems = (await assetItemApi.getReportAssetItems(searchAssetItemsObject)).data;

      jobs.value = [];
      for (const searchReportAssetItem of searchReportAssetItems) {
        if (searchReportAssetItem) {
          jobs.value.push({
            id: searchReportAssetItem.id!,
            name: searchReportAssetItem.assetName!,
            created: searchReportAssetItem.created!,
            masterAsset: searchReportAssetItem.assetID!,
            masterLanguage: searchReportAssetItem.masterLanguage!,
            translator: searchReportAssetItem.translatorID!,
            email: searchReportAssetItem.email!,
            costCenter: searchReportAssetItem.costCenter!,
            mamId: searchReportAssetItem.mamId!,
            languages: searchReportAssetItem.language!,
            status: searchReportAssetItem.status!
          });
        }
      }

      return searchReportAssetItems;
    }

    let _initJobsCompleted = async (items: any) => {
      jobsComplete.value = [];
      for (const job of items) {
        if (job.status == AssetItemStatus.CLOSED
          || (job.status == AssetItemStatus.DELETED && job.completed != null)) {
          jobsComplete.value.push({
            id: job.id!,
            name: job.assetName!,
            status: job.status!,
            user: job.translatorID!,
            group: job.groupID!,
            costCenter: job.costCenter!,
            language: job.language!,
            email: job.email!,
            date: job.completed!,
            duration: job.duration!,
            masterAsset: job.assetID
          });
        }
      }
    }

    let _initAllUsers = async () => {
      let userApi = new UserApi();

      let searchUsersObject: SearchUsersObject = {};
      searchUsersObject.searchText = "";

      let searchUsers = (await userApi.searchUsers(searchUsersObject)).data;

      users.value = [];
      for (const searchUser of searchUsers) {
        if (searchUser) {
          let groups = "";
          if (searchUser.groups) {
            for (const group of searchUser.groups!) {
              groups = groups + group.name + " ";
            }
          }

          users.value.push({
            id: searchUser.id!,
            firstName: searchUser.firstname!,
            name: searchUser.name!,
            email: searchUser.email!,
            country: searchUser.country!,
            costCenter: searchUser.costCenter!,
            role: searchUser.role!,
            locked: (searchUser.locked !== undefined ? searchUser.locked.toString() : ''),
            groups: groups
          });
        }
      }
    }
    watch(startDate, () => {
      console.debug("start date", startDate.value);
      _loadData();
    });

    watch(endDate, () => {
      console.debug("end date", endDate.value);
      _loadData();
    });

    watch(selectedPreset, () => {
      let date = new Date();
      date.setMonth(date.getMonth() + 1);
      endDate.value = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
      switch (selectedPreset.value) {
        case i18n.t('general.day'):
          let oneDayAgo = new Date(date)
          oneDayAgo.setDate(oneDayAgo.getDate() - 1);
          startDate.value = oneDayAgo.getFullYear() + "-" + oneDayAgo.getMonth() + "-" + oneDayAgo.getDate();
          return;
        case i18n.t('general.month'):
          let oneMonthAgo = new Date(date)
          oneMonthAgo.setMonth(date.getMonth() - 1);
          startDate.value = oneMonthAgo.getFullYear() + "-" + oneMonthAgo.getMonth() + "-" + oneMonthAgo.getDate();
          return;
        case i18n.t('general.year'):
          let oneYearAgo = new Date(date)
          oneYearAgo.setFullYear(date.getFullYear() - 1);
          startDate.value = oneYearAgo.getFullYear() + "-" + oneYearAgo.getMonth() + "-" + oneYearAgo.getDate();
          return;
        default:
          startDate.value = "";
          endDate.value = "";
      }
    });

    let _loadData = async () => {
      _initAssetsImported();
      _initJobsCompleted(await _initJobsCreated());
      _initAllUsers();
    }

    let getDateString = (date: string): string => {
      if (date) {
        if (!isNaN(Number(date))) {
          return new Date(+date).toDateString();
        } else {
          return date;
        }
      } else {
        return "";
      }
    }

    let downloadExcel = () => {
      let ifNoStart: Date = new Date();
      ifNoStart.setFullYear(2000);

      let downloadUrl = process.env.VUE_APP_TT_ENDPOINT + "/system/report?access_token=" + JSON.parse(localStorage.loginData).access_token +
        "&start=" + (startDate.value ? _getDateFromString(startDate.value).getTime().toString() : ifNoStart.getTime().toString()) +
        "&end=" + (endDate.value ? _getDateFromString(endDate.value).getTime().toString() : new Date().getTime().toString()) +
        "&createAssetsImportedSheet=" + true +
        "&createJobsCreatedSheet=" + true +
        "&createJobsCompletedSheet=" + true +
        "&createActiveUsersSheet=" + false +
        "&createAllUsersSheet=" + true +
        "&createJobsToApproveSheet=" + true;

      let downloadElement = document.createElement("a");
      downloadElement.href = downloadUrl + "&dl=true";
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
    }

    let _getDateFromString = (dateString: string): Date => {
      let date = new Date();

      let data = dateString.split("-");

      date.setFullYear(+data[0], +data[1] - 1, +data[2]);

      return date;
    }

    let _onResize = () => {
      windowWidth.value = window.innerWidth;
    }

    onMounted(async () => {
      _loadData();
      _onResize();
      window.addEventListener("resize", () => _onResize());
    })

    onUnmounted(() => {
      window.removeEventListener("resize", () => _onResize());
    })

    return {
      activeSheetTab,
      windowWidth,

      getDateString,
      downloadExcel,

      startDate,
      endDate,

      selectedPreset,
      presets,

      assetsImported,
      jobsCreated,
      jobsCompleted,
      activeUsers,
      allUsers,

      assets,
      groups,
      assetsPerWeek,
      assetsPerMonth,

      jobs,
      jobsPerWeek,
      jobsPerMonth,

      jobsComplete,
      jobsCompletePerWeek,
      jobsCompletePerMonth,

      totalLogins,
      loginPerWeek,
      loginPerMonth,

      users
    }
  }
});
<template>
  <div
    style="display: flex; align-items: center; min-width: 10px; max-width: calc(100vw - 550px); overflow: auto; height: 60px; padding-left: 10px"
    :style="maxWidth ? 'max-width: ' + maxWidth : 'max-width: calc(100vw - 550px)'">
    <div
      v-for="(item, index) in items"
      :key="item + index"
      style="margin-right: 10px"
    >
      <RoundedLabel
        @click="
          $store.state.filterBreadcrumbs.splice(index, 1);
          $emitter.emit('search-jobs', null);
          $emitter.emit('search-assets', null);
          $emitter.emit('search-asset-items', null);
          $emitter.emit('search-users', null);
          $emitter.emit('search-font-mapping', null);
        "
        :countryFlag="item.type === 'LANGUAGE' ? item.name === '*' ? '' : getLanguageFlag(item.name) : ''"
        :countryFlagSize=27
        :leftText="item.type + ':'"
        :text="
          item.type === 'LANGUAGE'
            ? item.name === '*' ? '*' : item.name.split(' ')[0].trim() +
              ' ' +
              item.name.split(' ')[1].trim()
            : item.type === 'CUSTOM' ? item.name.name + ': ' + getCustomerMetadataValue(item.name) : item.name
        "
        :lineThrough="blacklistGroups.includes(item.name)"
        rightIcon="pi pi-times-circle"
        class="bor-menu-color bg-white"
        style="cursor: pointer"
      />
    </div>
  </div>
</template>

<script src="./FilterBreadcrumb.ts"></script>

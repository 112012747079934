<template>
  <div
    class="col-menu-color bg-white border-radius-16"
    style="
      width: 100%;
      height: calc(100vh - 100px);
      box-shadow: 20px 20px 50px;
      margin: 0 24px 0 24px;
      border-color: transparent;
    "
  >
    <div class="bg-white"
         style="margin-left: -15px; margin-top: 80px; position: absolute; height: 30px; width: 30px; transform: rotate(45deg)"/>
    
    <div
      style="
        display: flex;
        width: 100%;
        height: calc(100vh - 140px);
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        class="col-dark-blue"
        style="
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 600;
        "
      >
        {{$t('general.summary')}}
      </div>
      
      <div
        class="bor-dark-blue border-radius-8"
        style="
          width: 60%;
          height: calc(100vh - 322px);
          overflow: auto;
          display: flex;
          flex-direction: column;
          padding: 20px 4px 20px 20px;
        "
      >
        <div>
          <div>
            <div style="font-size: 14px; font-weight: 400; line-height: 24px">
              {{$t('general.newAssetName')}}
            </div>
            <div class="col-dark-blue"
                 style="font-size: 18px; font-weight: 400; line-height: 24px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{assetName}}
            </div>
          </div>
          <div style="margin-top: 24px">
            <div style="font-size: 14px; font-weight: 400; line-height: 24px">
              {{$t('general.translator')}}
            </div>
            <div class="col-dark-blue" style="font-size: 18px; font-weight: 400; line-height: 24px">
              {{translator.id ? translator.id : translator}}
            </div>
          </div>
          <div style="margin-top: 24px" v-if="currentApprover">
            <div style="font-size: 14px; font-weight: 400; line-height: 24px">
              {{$t('general.approver')}}
            </div>
            <div class="col-dark-blue" style="font-size: 18px; font-weight: 400; line-height: 24px">
              {{approver.id ? approver.id : approver}}
            </div>
          </div>
          <div style="margin-top: 24px" v-if="group">
            <div style="font-size: 14px; font-weight: 400; line-height: 24px">
              {{$t('general.group')}}
            </div>
            <div class="col-dark-blue" style="font-size: 18px; font-weight: 400; line-height: 24px">
              {{group}}
            </div>
          </div>
          <div style="margin-top: 24px" v-if="selectedAudioTrack">
            <div style="font-size: 14px; font-weight: 400; line-height: 24px">
              {{$t('general.voiceOver')}}
            </div>
            <div class="col-dark-blue" style="font-size: 18px; font-weight: 400; line-height: 24px">
              {{selectedAudioTrack.name}}
            </div>
          </div>
          <div style="margin-top: 24px" v-if="subtitleOption">
            <div style="font-size: 14px; font-weight: 400; line-height: 24px">
              {{$t('general.subtitleOption')}}
            </div>
            <div class="col-dark-blue" style="font-size: 18px; font-weight: 400; line-height: 24px">
              {{subtitleOption}}
            </div>
          </div>
          <div
            style="
              margin-top: 24px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            "
          >
            <div
              style="
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 6px;
              "
            >
              {{$t('general.languageTranslations')}}
            </div>
            <div
              v-for="(item, index) in languages"
              :key="index"
              style="display: flex; align-items: center; justify-content: space-between; width: 80%; max-width: 450px;"
            >
              <div style="display: flex">
                <Flag
                  v-if="item.name && item.name"
                  :countryFlag="item.value"
                  style="margin-right: 12px"
                />
                <div
                  class="col-dark-blue"
                  style="font-size: 18px; font-weight: 400; line-height: 32px"
                >
                  {{item.name}}
                </div>
              </div>
              <div>
                <RoundedLabel :text="$t('addJob.autoTranslation')"
                              left-icon="pi pi-language"
                              class="col-white"
                              noHover="true"
                              :class="item.autoTranslate ? 'bg-dark-blue' : 'bg-dark-blue-30'"
                              style="height: 20px; font-size: 8px; cursor: default;"/>
              </div>
            </div>
            
            <div v-if="customerMetadata && customerMetadata.length > 0" style="margin-top: 15px">
              <div class="col-menu-color"
                   style="margin-bottom: 7px; font-size: 14px; font-weight: 400; line-height: 24px;">
                {{$t('general.customerMetadata')}}
              </div>
              
              <div v-for="item in customerMetadata">
                <div style="font-size: 14px; font-weight: 400; line-height: 18px">
                  {{item.name}}
                </div>
                
                <div
                  v-if="item.type === 'STRING'"
                  class="col-dark-blue"
                  style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  {{item.typeValueString !== undefined ? item.typeValueString : 'No Value'}}
                </div>
                
                <div
                  v-if="item.type === 'BOOLEAN'"
                  class="col-dark-blue"
                  style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  {{item.typeValueBoolean !== undefined ? item.typeValueBoolean : 'No Value'}}
                </div>
                
                <div
                  v-if="item.type === 'INTEGER'"
                  class="col-dark-blue"
                  style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  {{item.typeValueInteger ? item.typeValueInteger : 'No Value'}}
                </div>
                
                <div
                  v-if="item.type === 'DOUBLE'"
                  class="col-dark-blue"
                  style="font-size: 18px; font-weight: 400; line-height: 24px; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  {{item.typeValueDouble !== undefined ? item.typeValueDouble : 'No Value'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FOOTER -->
      
      <div
        style="
          width: 90%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <Button
          @click="
            $emit('update:transitionDir', 'slide-left');
            $emit('update:currentPage', currentPage - 1);
          "
          :label="$t('general.back')"
          class="border-radius-8 col-dark-blue bor-transparent bg-white"
          style="width: 64px; height: 40px; padding: 7px 6px 6px 8px"
        />
        <div>
          <div style="width:100px; display:flex; align-items:center; justify-content:space-between;">
            <i :class="'col-dark-blue pi ' + getCircleClass(1, currentPage)"></i>
            <i :class="'col-dark-blue pi ' + getCircleClass(2, currentPage)"></i>
          </div>
          <div style="width:100px; margin-top: 10px; display: flex;">
            <div class="col-dark-blue" style="margin: auto;">
              {{currentPage}} / 2
            </div>
          </div>
        </div>
        <div
          style="
						position: relative;
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          <i v-if="creatingJob" class="pi pi-spinner spin col-dark-blue"
             style="position: absolute; font-size: 25px; left: 20px"></i>
          
          <Button
            @click="createJob()"
            :disabled="creatingJob"
            :label="$t('general.createJob')"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="height: 40px; padding: 7px 6px 6px 8px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./AddJobViewSummary.ts"></script>

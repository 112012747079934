import {defineComponent, onMounted, ref, watch} from 'vue';
import {UserGroupApi} from '../../../target/api';
import {useStore} from "vuex";
import {CustomerMetadata} from "../../../target/api/de/moovit/titletoolserver/model";

export default defineComponent({
  components: {},
  props: ['items', 'maxWidth'],
  setup: function (props) {
    const store = useStore();

    const userGroupApi = new UserGroupApi()

    let blacklistGroups = ref<string[]>([]);

    watch(props.items, (items) => {
      console.debug("items", items);
      blacklistGroups.value = [];
      for (let key in items) {
        let item = items[key];
        if (item.type == 'GROUP') {
          if (!item.name.includes('Deleted')) {
            isGroupDeleted(key, item);
          }
        }
      }
    })

    let isGroupDeleted = async (index: any, item: any) => {
      console.debug(index, item)
      let result = (await userGroupApi.getUserGroupById(item.name)).data;
      if (result == "") {
        blacklistGroups.value.push(item.name);
      }
    }

    let getLanguageFlag = (name: string): string => {
      let language = store.state.languages
        .filter((language: { name: string, value: string }) => language.name === name);
      if (language.length > 0) {
        return language[0].value;
      } else {
        return ""
      }
    }

    let getCustomerMetadataValue = (metadata: CustomerMetadata): string => {
      let result = "";

      console.log("metadata", metadata)

      switch (metadata.type) {
        case "BOOLEAN":
          result = "" + (metadata.typeValueBoolean ? "true" : "false");
          break;
        case "STRING":
          result = "" + metadata.typeValueString;
          break;
        case "INTEGER":
          result = "" + metadata.typeValueInteger;
          break;
        case "DOUBLE":
          result = "" + metadata.typeValueDouble;
          break;
      }

      console.log("result", result)

      return result !== "undefined" ? result : "No Value";
    }

    onMounted(() => {
    });

    return {
      blacklistGroups,
      getLanguageFlag,
      getCustomerMetadataValue
    };
  },
});
